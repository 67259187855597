import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { useRoles } from 'pages/RolesPage/hooks';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { EPerPages } from 'models/table';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { UserForm } from 'pages/UsersPage/UserForm';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import { useUsers } from 'pages/UsersPage/hooks';
import { useTranslations } from 'hooks/useTranslations';
import { useDictionary } from 'models/dictionaryContext';

enum EEditUserPageTranslationsKeys {
  header = 'editUser.header',
  emailLabel = 'editUser.email.label',
  firstNameLabel = 'editUser.firstName.label',
  lastNameLabel = 'editUser.lastName.label',
  phonePrefixLabel = 'editUser.phonePrefix.label',
  phoneNumberLabel = 'editUser.phoneNumber.label',
  roleLabel = 'editUser.role.label',
  legalEntityPrefix = 'editUser.legalEntityPrefix.label',
}

const EditUserPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser | null>();
  const { handleEdit } = useUsers();
  const { legalEntityPrefix } = useDictionary();
  const { setAlert, clearAlert } = useAlert();
  const { roles, getRolesData } = useRoles();
  const { t } = useTranslations();
  const [initialValues, setInitialValues] = useState<IUserFormValues | null>();

  const compareArrayToObject = (array: string[], obj: Object): boolean => {
    const objEntries = Object.entries(obj).filter((entry) => entry[1] === true);
    return (
      objEntries.length === array.length &&
      objEntries.every((entry) => array.includes(entry[0]))
    );
  };

  const checkForChanges = (data: IUserFormValues): boolean =>
    initialValues?.firstName === data.firstName &&
    initialValues?.lastName === data.lastName &&
    initialValues?.phoneNumberValue === data.phoneNumberValue &&
    initialValues?.phoneNumberCodeDictionaryUniqueId ===
      data.phoneNumberCodeDictionaryUniqueId &&
    compareArrayToObject(user!.prefixGroupIds, data.formPrefixGroupIds) &&
    compareArrayToObject(user!.roleIds, data.formRoleIds);

  const handleSubmit = async (data: IUserFormValues) => {
    try {
      if (id && user) {
        if (!checkForChanges(data)) {
          await handleEdit(id, data);
          navigate(ERouteLinks.Users);
        } else {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('editUser.noChanges.warning.message'),
            variant: EAlertVariants.warning,
          });
        }
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_USER_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  useEffect(
    () => {
      (async function getRolesDataInit() {
        await getRolesData(1, EPerPages.perPage50);
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const formInitialValues = initialValues
      ? { ...initialValues }
      : { ...initialUserFormValues };
    if (legalEntityPrefix) {
      legalEntityPrefix.forEach((prefix) => {
        if (prefix && prefix.uniqueId) {
          formInitialValues.formPrefixGroupIds[prefix.uniqueId] = false;
          if (user?.prefixGroupIds.includes(prefix.uniqueId.toString())) {
            formInitialValues.formPrefixGroupIds[prefix.uniqueId] = true;
          }
        }
      });
    }
    roles.forEach((role) => {
      formInitialValues.formRoleIds[role.uniqueId] = false;
      if (user?.roleIds.includes(role.uniqueId)) {
        formInitialValues.formRoleIds[role.uniqueId] = true;
      }
    });
    setInitialValues(formInitialValues);
  }, [roles]);

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          const formInitialValues = { ...initialUserFormValues };
          formInitialValues.email = response.email;
          formInitialValues.firstName = response.firstName;
          formInitialValues.lastName = response.lastName;
          formInitialValues.version = response.version;
          formInitialValues.phoneNumberCodeDictionaryUniqueId = response.phoneNumber
            ? response.phoneNumber.codeDictionaryUniqueId
            : '';
          formInitialValues.phoneNumberValue = response.phoneNumber
            ? response.phoneNumber.value
            : '';
          response.roleIds.forEach((role) => {
            formInitialValues.formRoleIds[role] = true;
          });
          response.prefixGroupIds.forEach((prefix) => {
            formInitialValues.formPrefixGroupIds[prefix] = true;
          });
          setInitialValues(formInitialValues);
          setUser(response);
        }
      }
    })();
  }, [id]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('user.breadcrumbs.users'),
            route: ERouteLinks.Users,
          },
          t(EEditUserPageTranslationsKeys.header),
        ]}
      />
      <StyledPageTitle variant="h4">{t(EEditUserPageTranslationsKeys.header)}</StyledPageTitle>

      <TableFormWrapper>
        {!!roles.length && user && (
          <UserForm
            onCancel={() => navigate(ERouteLinks.Users)}
            onSubmit={handleSubmit}
            initialFormValues={initialValues}
            isEdit={true}
            roles={roles}
            translationsKeys={EEditUserPageTranslationsKeys}
            legalEntityPrefix={legalEntityPrefix}
          />
        )}
      </TableFormWrapper>
    </>
  );
};

export { EditUserPage };
