import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LegalEntityGroupForm } from 'pages/LegalEntitiesPage/LegalEntityGroupForm';
import { ILegalEntityGroupFormValues } from 'models/form';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Table } from 'components/molecules/Table';
import {
  legalEntityGroupFirearmTableColumns,
  mapLEGroupFirearmListToDataSource,
} from 'pages/LegalEntitiesPage/helpers';
import { CardList } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { LegalEntityGroupFirearmTableCard } from 'pages/LegalEntitiesPage/LegalEntityGroupFirearmTableCard';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { IFirearm } from 'models/firearm';
import { useTranslations } from 'hooks/useTranslations';
import { useFirearms } from 'pages/Firearms/hooks';
import {
  mapLegalityActivityMapColor,
  mapLegalityActivityMapHover,
  renderDataColor,
} from 'components/molecules/Table/helpers';
import { ITableDataSource } from 'models/table';

const LegalEntityAddGroupPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');

  const { getFirearmsData, firearms } = useFirearms();
  const { handleCreateGroup, navigateToDetails } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { legalEntityAlert, nextPageAlert, clearAlert, setAlert } = useAlert();
  const navigate = useNavigate();
  const { state: routerState, pathname }: any = useLocation();
  const [selectedFirearms, setSelectedFirearms] = useState<IFirearm[]>([]);
  const [groupName, setGroupName] = useState<string>(routerState?.groupName || '');
  const { t } = useTranslations();

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    (async function initFirearmsData() {
      const firearmsParam = query.get('firearms');
      if (routerState?.selectedFirearms?.length) {
        setSelectedFirearms(routerState.selectedFirearms);
      } else if (firearmsParam) {
        const firearmIds = firearmsParam.split(',');
        if (firearmIds.length) {
          await getFirearmsData(1, 2000, {
            uniqueIds: firearmIds,
          });
        }
      }
    })();
  }, [routerState, query.get('firearms')]);

  useEffect(() => {
    if (firearms.length) {
      setSelectedFirearms(
        firearms.map((firearm) => ({
          ...firearm,
          id: firearm.uniqueId,
        })),
      );
    }
  }, [firearms]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (data: ILegalEntityGroupFormValues) => {
    try {
      await handleCreateGroup({
        ...data,
        uniqueId: id,
        ...(routerState?.selectedFirearms?.length
          ? {
              firearmIds: routerState?.selectedFirearms.map((item: IFirearm) => item.uniqueId),
            }
          : []),
      });
    } catch (e: any) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  const handleDeleteFirearmParam = (firearmId: string) => {
    const firearmsParam = query.get('firearms')?.split(',');
    if (firearmsParam?.length) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const modifiedIds = firearmsParam.filter((id) => id !== firearmId).join(',');
      navigate(`${pathname}?expand=groups&type=${type}&firearms=${modifiedIds}`);
    }
  };

  const handleDeleteSelectedFirearm = (firearmId: string) => {
    handleDeleteFirearmParam(firearmId);
    // @ts-ignore
    setSelectedFirearms((prevState) => prevState.filter((item) => item.id !== firearmId));
    routerState.selectedFirearms = routerState.selectedFirearms.filter(
      // @ts-ignore
      (item: IFirearm) => item.id !== firearmId,
    );
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=groups`,
          },
          t('addGroup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addGroup.header')}</StyledPageTitle>

      <FormWrapper>
        <LegalEntityGroupForm
          onCancel={navigateToDetails}
          onSubmit={handleSubmit}
          onNameChange={(name: string) => {
            setGroupName(name);
          }}
          initialFormValues={{ name: groupName, uniqueId: '', firearmIds: [] }}
        >
          <>
            <StyledPageTitle variant="h4">
              {t('addGroup.firearmList.section.label')}
            </StyledPageTitle>
            <Typography variant="body1" sx={{ margin: '20px 0' }}>
              {t('addGroup.firearmList.section.info.text')}
            </Typography>
            <Grid item xs={12} xl={6} sx={{ paddingRight: { md: 0, xl: '16px' } }}>
              <Button
                id="search-firearm-button"
                label={t('addGroup.firearmList.section.searchFirearm.button')}
                variant={EButtonVariants.outlined}
                fullWidth
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityGroupFirearmSearch.replace(
                      ':id',
                      id,
                    )}?type=${type}&expand=groups`,
                    {
                      state: {
                        isDetailsContext: false,
                        groupName,
                        fromAddPage: true,
                        prevSelectedFirearms: routerState?.selectedFirearms,
                      },
                    },
                  )
                }
              />
            </Grid>
            {selectedFirearms?.length ? (
              <Box sx={{ marginTop: '20px' }}>
                {isDesktop ? (
                  <Table
                    columns={legalEntityGroupFirearmTableColumns({
                      handleDelete: (firearmId: string) =>
                        handleDeleteSelectedFirearm(firearmId),
                      renderStateFieldColor: (data: ITableDataSource) =>
                        renderDataColor(data).stateField,
                    })}
                    dataSource={mapLEGroupFirearmListToDataSource(selectedFirearms)}
                    specificRowColor={mapLegalityActivityMapColor}
                    specificRowColorHover={mapLegalityActivityMapHover}
                    translationsKeys={{
                      noResults: 'registeredFirearms.accordion.noResults.text',
                    }}
                  />
                ) : (
                  <CardList
                    items={mapLEGroupFirearmListToDataSource(selectedFirearms)}
                    translationsKeys={{
                      noResults: 'legalEntityDetails.firearm.tab.search.noResult.info',
                    }}
                    render={(data, index) => (
                      <Box mb={2} key={data.id}>
                        <LegalEntityGroupFirearmTableCard
                          data={data}
                          key={index}
                          renderDataColor={() => renderDataColor(data)}
                          deleteAction={(firearmId: string) =>
                            handleDeleteSelectedFirearm(firearmId)
                          }
                        />
                      </Box>
                    )}
                  />
                )}
              </Box>
            ) : null}
          </>
        </LegalEntityGroupForm>
      </FormWrapper>
      {legalEntityAlert && (
        <Box mt={2}>
          <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
        </Box>
      )}
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { LegalEntityAddGroupPage };
