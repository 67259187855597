import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { StyledDetailsWrapper } from 'pages/TransactionsPage/ViewTransactionPage/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Chips } from 'components/atoms/Chips';
import {
  ICancelTransactionForGroupParameters,
  useTransactions,
} from 'pages/TransactionsPage/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { parseDictionary } from 'helpers/dictionary';
import { Accordion } from 'components/molecules/Accordion';
import { colors } from 'theme/colors';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions } from 'models/table';
import { Table } from 'components/molecules/Table';
import { CardList, emptyFieldHolder } from 'components/molecules/CardList';

import {
  mapTransactionDetailsAncillaries,
  mapTransactionDetailsFirearms,
  transactionDetailsAncillariesTableColumns,
  transactionDetailsFirearmsTableColumns,
} from 'pages/TransactionsPage/helpers';
import { TransactionDetailsFirearmsCard } from 'pages/TransactionsPage/TransactionDetailsFirearmsCard';
import { Loader } from 'components/atoms/Loader';
import { useLang } from 'models/langContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ETransactionItemType } from 'constants/TransactionItemType';
import { TransactionDetailsAncillariesCard } from 'pages/TransactionsPage/TransactionDetailsAncillariesCard';
import { EArtAncillaryTypeNameUpperCase } from 'constants/ArtAccessoryType';
import { capitalizeFirstLetter } from 'utils/capitalize';
import { defineTransactionStateChipVariant } from 'utils/defineTransactionStateChipVariant';

export enum ETransactionDetailsTranslationsKeys {
  noResults = 'users.search.noResults.info',
}

const CancelTransactionPage = () => {
  const [currentPageFirearms, setCurrentPageFirearms] = useState<number>(1);
  const [currentPageAncillaries, setCurrentPageAncillaries] = useState<number>(1);
  const [perPageFirearms, setPerPageFirearms] = useState<EPerPages>(EPerPages.perPage25);
  const [perPageAncillaries, setPerPageAncillaries] = useState<EPerPages>(EPerPages.perPage25);
  const [sortFirearms, setSortFirearms] = useState<ISortOptions | null>(null);
  const [sortAncillaries, setSortAncillaries] = useState<ISortOptions | null>(null);
  const { t } = useTranslations();
  const { pathname } = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { selectedLanguage } = useLang();
  const { isDesktop } = useThemeBreakpoint();
  const { shortDateFormat, longDateFormat, getDateFormat } = useGlobalProperty();
  const [isFirearmPaginationLoading, setIsFirearmPaginationLoading] = useState(false);
  const [isAncillaryPaginationLoading, setIsAncillaryPaginationLoading] = useState(false);
  const { samePageAlert, setAlert, clearAlert } = useAlert();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const {
    handleGetTransactionDetailsData,
    transactionDetails,
    loadDictionariesForTransactionDetails,
    transactionType,
    handleGetTransactionDetailsFirearmsData,
    handleGetTransactionDetailsAncillariesData,
    transactionDetailsFirearmsContent,
    transactionDetailsAncillariesContent,
    transactionDetailsFirearmsPaginator,
    handleCancelAncillaryTransaction,
    transactionDetailsAncillariesPaginator,
    handleCancelTransactionForAncillaryGroup,
    handleCancelTransaction,
    handleCancelTransactionForGroup,
  } = useTransactions();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  useEffect(() => {
    (async function loadDictionaryInit() {
      await loadDictionariesForTransactionDetails();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async function getTransactionDetailsInit() {
      if (id) {
        await handleGetTransactionDetailsData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async function getTransactionDetailsFirearmsInit() {
      if (id && transactionDetails?.transactionItemType === ETransactionItemType.FIREARM) {
        setIsFirearmPaginationLoading(true);
        await handleGetTransactionDetailsFirearmsData(
          id,
          currentPageFirearms,
          perPageFirearms,
          sortFirearms,
        );
        setIsFirearmPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sortFirearms, perPageFirearms, currentPageFirearms, transactionDetails]);

  useEffect(() => {
    (async function getTransactionDetailsAncillariesInit() {
      if (id && transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY) {
        setIsAncillaryPaginationLoading(true);
        await handleGetTransactionDetailsAncillariesData(
          id,
          currentPageAncillaries,
          perPageAncillaries,
          sortAncillaries,
        );
        setIsAncillaryPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sortAncillaries, perPageAncillaries, currentPageAncillaries, transactionDetails]);

  const isGroupDiscard = !!transactionDetails?.ancillaryGroupUniqueId;
  const groupUniqueId = transactionDetails?.ancillaryGroupUniqueId;

  let frtDetailsTitle = '';
  if (
    transactionDetailsFirearmsContent.length &&
    !transactionDetails?.firearmsWithDifferentMakesOrModels
  ) {
    const { make, model, type, action, caliber } = transactionDetailsFirearmsContent[0];
    frtDetailsTitle = `${make}, ${model}, ${type}, ${action}, ${caliber}`;
  }

  let artDetailsTitle = '';
  if (
    transactionDetailsAncillariesContent.length &&
    !transactionDetails?.ancillariesWithDifferentART
  ) {
    const { ancillarySpecificType, artId, ancillaryType, manufacturer } =
      transactionDetailsAncillariesContent[0];
    artDetailsTitle = `${capitalizeFirstLetter(
      ancillaryType,
    )}, ${ancillarySpecificType}, ${artId}${manufacturer ? `, ${manufacturer}` : ''}`;
  }

  const handleDiscardFirearmTransaction = async () => {
    setIsSubmitLoading(true);

    if (transactionDetails?.firearmGroupUniqueId) {
      handleCancelTransactionForGroup({
        uniqueId: id,
        groupUniqueId: transactionDetails?.firearmGroupUniqueId,
      } as ICancelTransactionForGroupParameters)
        .then(() => {
          if (transactionDetails?.firearmGroupUniqueId) {
            setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
              text: t('discardTransaction.transactionDiscarded.text'),
              variant: EAlertVariants.success,
            });
            navigate(
              `${ERouteLinks.ViewRegisteredFirearm.replace(
                ':id',
                transactionDetailsFirearmsContent[0]?.uniqueId,
              )}?&expand=transactions`,
            );
          }
          setIsSubmitLoading(false);
        })
        .catch(() => {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('discardTransaction.failed.text'),
            variant: EAlertVariants.error,
          });
          setIsSubmitLoading(false);
        });
    } else {
      handleCancelTransaction({
        uniqueId: id,
        firearmUniqueId: transactionDetailsFirearmsContent[0]?.uniqueId,
      })
        .then(() => {
          setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
            text: t('discardTransaction.transactionDiscarded.text'),
            variant: EAlertVariants.success,
          });
          navigate(
            `${ERouteLinks.ViewRegisteredFirearm.replace(
              ':id',
              transactionDetailsFirearmsContent[0]?.uniqueId,
            )}?&expand=transactions`,
          );
          setIsSubmitLoading(false);
        })
        .catch((e: any) => {
          if (e.response.data.exception === 'SingleScopeFirearmDiscardProhibited') {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: e.response.data.message,
              variant: EAlertVariants.error,
            });
          } else {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: t('discardTransaction.failed.text'),
              variant: EAlertVariants.error,
            });
          }
          setIsSubmitLoading(false);
        });
    }
  };

  const navigateToAncillaryDetails = () => {
    // eslint-disable-next-line default-case
    switch (
      transactionDetailsAncillariesContent[0]?.ancillaryType as EArtAncillaryTypeNameUpperCase
    ) {
      case EArtAncillaryTypeNameUpperCase.ACCESSORY: {
        navigate(
          `${ERouteLinks.ViewAccessories.replace(
            ':id',
            transactionDetailsAncillariesContent[0]?.uniqueId,
          )}?&expand=transactions`,
        );
        break;
      }
      case EArtAncillaryTypeNameUpperCase.AMMUNITION: {
        navigate(
          `${ERouteLinks.ViewAmmunition.replace(
            ':id',
            transactionDetailsAncillariesContent[0]?.uniqueId,
          )}?&expand=transactions`,
        );
        break;
      }
      case EArtAncillaryTypeNameUpperCase.COMPONENT: {
        navigate(
          `${ERouteLinks.ViewComponents.replace(
            ':id',
            transactionDetailsAncillariesContent[0]?.uniqueId,
          )}?&expand=transactions`,
        );
        break;
      }
    }
  };
  const handleDiscardAncillaryTransaction = async () => {
    setIsSubmitLoading(true);

    if (isGroupDiscard) {
      handleCancelTransactionForAncillaryGroup({
        uniqueId: id,
        groupUniqueId,
      } as ICancelTransactionForGroupParameters)
        .then(() => {
          setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
            text: t('discardTransaction.transactionDiscarded.text'),
            variant: EAlertVariants.success,
          });
          navigateToAncillaryDetails();
          setIsSubmitLoading(false);
        })
        .catch(() => {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('discardTransaction.failed.text'),
            variant: EAlertVariants.error,
          });
          setIsSubmitLoading(false);
        });
    } else {
      handleCancelAncillaryTransaction({
        uniqueId: id,
        ancillaryUniqueId: transactionDetailsAncillariesContent[0]?.uniqueId,
      })
        .then(() => {
          setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
            text: t('discardTransaction.transactionDiscarded.text'),
            variant: EAlertVariants.success,
          });
          navigateToAncillaryDetails();
          setIsSubmitLoading(false);
        })
        .catch((e: any) => {
          if (e.response.data.exception === 'SingleScopeAncillaryDiscardProhibited') {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: e.response.data.message,
              variant: EAlertVariants.error,
            });
          } else {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: t('discardTransaction.failed.text'),
              variant: EAlertVariants.error,
            });
          }
          setIsSubmitLoading(false);
        });
    }
  };

  const handleCancelFirearmDiscarding = () => {
    if (transactionDetails?.firearmGroupUniqueId) {
      navigate(`${pathname}?expand=transactions`, { replace: true });
    }
    navigate(
      `${ERouteLinks.ViewRegisteredFirearm.replace(
        ':id',
        transactionDetailsFirearmsContent[0]?.uniqueId,
      )}?&expand=transactions`,
    );
  };

  const handleCancelAncillaryDiscarding = () => {
    if (groupUniqueId) {
      navigate(
        `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(':id', id as string).replace(
          ':groupId',
          groupUniqueId,
        )}?&expand=transactions`,
      );
    }
    navigateToAncillaryDetails();
  };

  const getMessage = () => {
    if (
      transactionDetails?.transactionItemType === ETransactionItemType.FIREARM &&
      transactionDetailsFirearmsContent.length > 1
    ) {
      return t('discardTransaction.firearms.info.text');
    }
    if (
      transactionDetails?.transactionItemType === ETransactionItemType.FIREARM &&
      transactionDetailsFirearmsContent.length === 1
    ) {
      return t('discardTransaction.info.text');
    }
    if (
      transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
      transactionDetailsAncillariesContent.length > 1
    ) {
      return t('discardTransaction.ancillaries.info.text');
    }
    if (
      transactionDetails?.transactionItemType === ETransactionItemType.ANCILLARY &&
      transactionDetailsAncillariesContent.length === 1
    ) {
      return t('discardTransaction.ancillary.info.text');
    }

    return '';
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('transaction.header'),
            route: ERouteLinks.Transactions,
          },
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: ERouteLinks.ViewTransaction.replace(':id', id),
          },
          t('discardTransaction.discardTransaction.header'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('discardTransaction.discardTransaction.header')}
      </StyledPageTitle>

      <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
        <Alert text={getMessage()} variant={EAlertVariants.warning} />
      </Grid>

      {transactionDetails !== null ? (
        <Box my={3}>
          <StyledDetailsWrapper container rowGap={3} columns={12} mb={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('discardTransaction.transactionType.label')}
              </Typography>
              <Chips
                text={
                  parseDictionary(transactionType, 'name')?.[transactionDetails?.typeUniqueId]
                }
                variant={defineTransactionStateChipVariant(
                  transactionDetails.legalityUniqueId,
                  transactionDetails.activityUniqueId,
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('discardTransaction.transactionDateAndTime.label')}
              </Typography>
              <Typography variant="body2">
                {formatDate(transactionDetails.date, longDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('discardTransaction.createdOn.label')}
              </Typography>
              <Typography variant="body2">
                {formatDate(transactionDetails.createdDate, longDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('discardTransaction.createdBy.label')}
              </Typography>
              <Typography variant="body2">{transactionDetails.createdBy}</Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid item xs={12}>
            <Accordion
              title={t('discardTransaction.ownerAndKeeper.section.name')}
              alwaysExpanded
            >
              <StyledDetailsWrapper container rowGap={3} mb={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('discardTransaction.ownerAndKeeper.section.owner.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(transactionDetails.owner)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('discardTransaction.ownerAndKeeper.section.keeper.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(transactionDetails.keeper)}
                  </Typography>
                </Grid>
              </StyledDetailsWrapper>
            </Accordion>
          </Grid>
          {transactionDetails.transactionItemType === ETransactionItemType.FIREARM && (
            <Grid item xs={12}>
              <Accordion
                title={t('discardTransaction.firearmDetails.section.name')}
                alwaysExpanded
              >
                {!transactionDetails.firearmsWithDifferentMakesOrModels && (
                  <Grid
                    container
                    sx={{
                      border: `1px solid ${colors.primary25}`,
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                    mb={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: colors.primary25,
                      }}
                      px={2}
                      py={1}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 600 }}>
                          {frtDetailsTitle}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container p={2} rowSpacing={2}>
                      <Grid item xs={12} lg={6} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(
                            'transactionDetails.firearmDetails.accordion.firearmsReferenceNo.label',
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {transactionDetailsFirearmsContent?.[0]?.frtId}
                        </Typography>
                      </Grid>
                      {transactionDetailsFirearmsContent.length === 1 && (
                        <Grid
                          item
                          xs={12}
                          container
                          rowGap={3}
                          sx={{ borderTop: `1px solid ${colors.primary25}` }}
                          mt={3}
                        >
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.firearmDetails.accordion.firecycleID.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {transactionDetailsFirearmsContent[0]?.fireCycleId}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.firearmDetails.accordion.serialNumber.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {transactionDetailsFirearmsContent[0]?.serialNumber}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.firearmDetails.accordion.externalReferenceNo.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {emptyFieldHolder(
                                transactionDetailsFirearmsContent[0]?.externalReferenceNumber,
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.firearmDetails.accordion.manufacturedDate.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {formatDate(
                                transactionDetailsFirearmsContent[0]?.manufacturedAt,
                                shortDateFormat,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} container px={2} mb={2}>
                  {transactionDetails.firearmsWithDifferentMakesOrModels && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('transactionDetails.firearmDetails.accordion.firearmCount.label')}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetailsFirearmsContent.length}
                      </Typography>
                    </Grid>
                  )}
                  {transactionDetails.firearmGroupName && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('transactionDetails.firearmDetails.accordion.groupName.label')}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetails.firearmGroupName}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {transactionDetailsFirearmsContent.length > 1 && (
                  <Grid item xs={12}>
                    {isDesktop ? (
                      <Table
                        columns={transactionDetailsFirearmsTableColumns({
                          isDifferentMakesOrModels:
                            transactionDetails.firearmsWithDifferentMakesOrModels,
                          handleView: (firearmId: string) => {
                            window.open(
                              ERouteLinks.ViewRegisteredFirearm.replace(
                                ':id',
                                firearmId as string,
                              ),
                            );
                          },
                        })}
                        dataSource={mapTransactionDetailsFirearms(
                          transactionDetailsFirearmsContent,
                          shortDateFormat,
                        )}
                        onSort={setSortFirearms}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                      />
                    ) : (
                      <CardList
                        items={mapTransactionDetailsFirearms(
                          transactionDetailsFirearmsContent,
                          shortDateFormat,
                        )}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                        render={(data, index) => (
                          <TransactionDetailsFirearmsCard
                            data={data}
                            key={index}
                            handleView={(firearmId: string) => {
                              window.open(
                                ERouteLinks.ViewRegisteredFirearm.replace(
                                  ':id',
                                  firearmId as string,
                                ),
                              );
                            }}
                            isDifferentMakesOrModels={
                              transactionDetails.firearmsWithDifferentMakesOrModels
                            }
                          />
                        )}
                      />
                    )}

                    {transactionDetailsFirearmsPaginator && (
                      <Pagination
                        count={transactionDetailsFirearmsPaginator.totalElements}
                        onChangePage={(page) => setCurrentPageFirearms(page)}
                        current={currentPageFirearms}
                        perPage={perPageFirearms}
                        onChangePerPage={(value) => setPerPageFirearms(value)}
                        isLoading={isFirearmPaginationLoading}
                      />
                    )}
                  </Grid>
                )}
              </Accordion>
            </Grid>
          )}

          {transactionDetails.transactionItemType === ETransactionItemType.ANCILLARY && (
            <Grid item xs={12}>
              <Accordion
                title={t('addTransaction.ancillaryDetails.section.name')}
                alwaysExpanded
              >
                {!transactionDetails.ancillariesWithDifferentART && (
                  <Grid
                    container
                    sx={{
                      border: `1px solid ${colors.primary25}`,
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                    mb={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: colors.primary25,
                      }}
                      px={2}
                      py={1}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 600 }}>
                          {artDetailsTitle}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container p={2} rowSpacing={2}>
                      <Grid item xs={12} lg={6} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('transactionDetails.ancillaryDetails.accordion.artId.label')}
                        </Typography>
                        <Typography variant="body2">
                          {transactionDetailsAncillariesContent?.[0]?.artId}
                        </Typography>
                      </Grid>
                      {transactionDetailsAncillariesContent.length === 1 && (
                        <Grid
                          item
                          xs={12}
                          container
                          rowGap={3}
                          sx={{ borderTop: `1px solid ${colors.primary25}` }}
                          mt={3}
                        >
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.ancillaryDetails.accordion.firecycleID.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {transactionDetailsAncillariesContent[0]?.fireCycleId}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.ancillaryDetails.accordion.serialNumber.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {emptyFieldHolder(
                                transactionDetailsAncillariesContent[0]?.serialNumber,
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.ancillaryDetails.accordion.externalReferenceNo.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {emptyFieldHolder(
                                transactionDetailsAncillariesContent[0]
                                  ?.externalReferenceNumber,
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t(
                                'transactionDetails.ancillaryDetails.accordion.manufacturedDate.label',
                              )}
                            </Typography>
                            <Typography variant="body2">
                              {formatDate(
                                transactionDetailsAncillariesContent[0]?.productionDate,
                                shortDateFormat,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} container px={2} mb={2}>
                  {transactionDetails.ancillariesWithDifferentART && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(
                          'transactionDetails.ancillaryDetails.accordion.ancillaryCount.label',
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetailsAncillariesContent.length}
                      </Typography>
                    </Grid>
                  )}
                  {transactionDetails.ancillaryGroupName && (
                    <Grid item xs={12} md={6} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('transactionDetails.ancillaryDetails.accordion.groupName.label')}
                      </Typography>
                      <Typography variant="body2">
                        {transactionDetails.ancillaryGroupName}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {transactionDetailsAncillariesContent.length > 1 && (
                  <Grid item xs={12}>
                    {isDesktop ? (
                      <Table
                        columns={transactionDetailsAncillariesTableColumns({
                          isDiscard: true,
                        })}
                        dataSource={mapTransactionDetailsAncillaries(
                          transactionDetailsAncillariesContent,
                          shortDateFormat,
                        )}
                        onSort={setSortAncillaries}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                      />
                    ) : (
                      <CardList
                        items={mapTransactionDetailsAncillaries(
                          transactionDetailsAncillariesContent,
                          shortDateFormat,
                        )}
                        translationsKeys={ETransactionDetailsTranslationsKeys}
                        render={(data, index) => (
                          <TransactionDetailsAncillariesCard data={data} key={index} />
                        )}
                      />
                    )}

                    {transactionDetailsAncillariesPaginator && (
                      <Pagination
                        count={transactionDetailsAncillariesPaginator.totalElements}
                        onChangePage={(page) => setCurrentPageAncillaries(page)}
                        current={currentPageAncillaries}
                        perPage={perPageAncillaries}
                        onChangePerPage={(value) => setPerPageAncillaries(value)}
                        isLoading={isAncillaryPaginationLoading}
                      />
                    )}
                  </Grid>
                )}
              </Accordion>
            </Grid>
          )}

          <Grid item xs={12} lg={6} container spacing={2} mt={5}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label={t('discardTransaction.yes.button')}
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
                sx={{ mr: 1 }}
                onClick={
                  transactionDetails.transactionItemType === ETransactionItemType.FIREARM
                    ? handleDiscardFirearmTransaction
                    : handleDiscardAncillaryTransaction
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label={t('discardTransaction.no.button')}
                variant={EButtonVariants.outlined}
                onClick={
                  transactionDetails.transactionItemType === ETransactionItemType.FIREARM
                    ? handleCancelFirearmDiscarding
                    : handleCancelAncillaryDiscarding
                }
              />
            </Grid>
          </Grid>
          {samePageAlert && (
            <Box mt={2} mb={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </Box>
      ) : (
        <Loader isLoading />
      )}
    </>
  );
};

export default CancelTransactionPage;
