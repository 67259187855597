import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import {
  EArtAmmunitionType,
  EArtAncillaryType,
  EArtAncillaryTypeName,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes, ETransactionDictionaryItemTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { Typography } from '@mui/material';
import {
  getFieldsByAccessoriesType,
  getFieldsByAmmunitionType,
  getFieldsByComponentType,
} from 'pages/AncillariesPage/helpers';
import { colors } from 'theme/colors';
import { useQuery } from 'hooks/useQuery';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { emptyFieldHolder } from 'components/molecules/CardList';

export enum EAccessoryLabels {
  ancillary = 'ancillariesReferenceTable.accordion.ancillary.column',
  accessoryType = 'ancillariesReferenceTable.accordion.accessoryType.column',
  artId = 'ancillariesReferenceTable.accordion.artId.column',
  hostType = 'ancillariesReferenceTable.accordion.hostType.column',
  manufacturer = 'ancillariesReferenceTable.accordion.accessoryManufacturer.column',
  productName = 'ancillariesReferenceTable.accordion.productName.column',
  calibre = 'ancillariesReferenceTable.accordion.calibre.column',
  composition = 'ancillariesReferenceTable.accordion.composition.column',
  proofHouse = 'ancillariesReferenceTable.accordion.proofMark.label',
  lightColour = 'ancillariesReferenceTable.accordion.lightColour.label',
  capacity = 'ancillariesReferenceTable.accordion.capacity.label',
  magnification = 'ancillariesReferenceTable.accordion.magnification.label',
  utility = 'ancillariesReferenceTable.accordion.utility.label',
  rectile = 'ancillariesReferenceTable.accordion.reticle.label',
  notes = 'ancillariesReferenceTable.accordion.notes.label',
}

export enum EAmmunitionLabels {
  ancillary = 'ancillariesReferenceTable.accordion.ancillary.column',
  artId = 'ancillariesReferenceTable.accordion.artId.column',
  ammunitionType = 'ammunitionTable.accordion.ammunitionType.label',
  manufacturer = 'ammunitionTable.accordion.ammunitionManufacturer.label',
  productName = 'ammunitionTable.accordion.productName.label',
  factory = 'ammunitionTable.accordion.factory.label',
  calibre = 'ammunitionTable.accordion.calibre.label',
  cartridgeComposition = 'ammunitionTable.accordion.catridgeComposition.label',
  colourOfPrimerSealant = 'ammunitionTable.accordion.colourOfPrimerSealant.label',
  projectileShape = 'ammunitionTable.accordion.projectileShape.label',
  functionalType = 'ammunitionTable.accordion.functionalType.label',
  shotSize = 'ammunitionTable.accordion.shotSize.label',
  munitionType = 'ammunitionTable.accordion.munitionType.label',
  munitionVelocity = 'ammunitionTable.accordion.munitionVelocity.label',
  headstampYearMarking = 'ammunitionTable.accordion.headstampYearMarking.label',
  alphanumericalMarkings = 'ammunitionTable.accordion.alphanumericalMarkins.label',
  notes = 'ammunitionTable.accordion.notes.label',
}

export enum EComponentLabels {
  ancillary = 'ancillariesReferenceTable.accordion.ancillary.column',
  artId = 'ancillariesReferenceTable.accordion.artId.column',
  componentType = 'componentsTable.accordion.componentType.label',
  manufacturer = 'componentsTable.accordion.manufacturer.label',
  productName = 'componentsTable.accordion.productName.label',
  calibre = 'componentsTable.accordion.calibre.label',
  length = 'componentsTable.accordion.length.label',
  composition = 'componentsTable.accordion.composition.label',
  capacity = 'componentsTable.accordion.capacity.label',
  proofHouse = 'componentsTable.accordion.proofhouse.label',
  frtType = 'componentsTable.accordion.firearmType.label',
  frtManufacturer = 'componentsTable.accordion.firearmManufacturer.label',
  frtMake = 'componentsTable.accordion.make.label',
  frtModel = 'componentsTable.accordion.model.label',
  notes = 'componentsTable.accordion.notes.label',
}

export enum EAncillaryLabels {
  ancillaryDictionaryUniqueId = 'accessoryDetails.ancillary.type.label',
  ancillaryTypeUniqueId = 'myFilters.ancillaries.type.label',
  manufacturerUniqueId = 'myFilters.ancillaries.manufacturer.label',
  calibreUniqueId = 'accessoryDetails.calibre.label',
  hostTypeUniqueId = 'accessoryDetails.hostType.label',
  magnificationUniqueId = 'accessoryDetails.magnification.label',
  utilityUniqueId = 'accessoryDetails.utility.label',
  reticleUniqueId = 'accessoryDetails.reticle.label',
  lightColourUniqueId = 'accessoryDetails.lightcolour.label',
  compositionUniqueId = 'accessoryDetails.composition.label',
  proofHouseUniqueId = 'accessoryDetails.proofMark.label',
  stateUniqueId = 'myFilters.ancillaries.state.label',
  state = 'myFilters.ancillaries.state.label',
  legalityUniqueId = 'myFilters.ancillaries.legality.label',
  legality = 'myFilters.ancillaries.legality.label',
  ownerName = 'accessoryDetails.transactions.owner.column',
  owner = 'accessoryDetails.transactions.owner.column',
  ownerUniqueId = 'accessoryDetails.transactions.owner.column',
  keeperName = 'accessoryDetails.transactions.keeper.column',
  keeper = 'accessoryDetails.transactions.keeper.column',
  keeperUniqueId = 'accessoryDetails.transactions.keeper.column',
  serialNumber = 'accessoryDetails.serialNumber.label',
  exhibitNumber = 'accessoriesTable.accordion.exhibitNumber.label',
  ancillary = 'ancillariesReferenceTable.accordion.ancillary.column',
  accessoryType = 'ancillariesReferenceTable.accordion.accessoryType.column',
  artId = 'ancillariesReferenceTable.accordion.artId.column',
  hostType = 'ancillariesReferenceTable.accordion.hostType.column',
  manufacturer = 'ancillariesReferenceTable.accordion.accessoryManufacturer.column',
  calibre = 'ancillariesReferenceTable.accordion.calibre.column',
  composition = 'ancillariesReferenceTable.accordion.composition.column',
  proofHouse = 'ancillariesReferenceTable.accordion.proofMark.label',
  lightColour = 'ancillariesReferenceTable.accordion.lightColour.label',
  capacity = 'ancillariesReferenceTable.accordion.capacity.label',
  accessoryCapacity = 'ancillariesReferenceTable.accordion.capacity.label',
  magnification = 'ancillariesReferenceTable.accordion.magnification.label',
  utility = 'ancillariesReferenceTable.accordion.utility.label',
  rectile = 'ancillariesReferenceTable.accordion.reticle.label',
  fireCycleId = 'componentsTable.accordion.fireCycleId.label',
  capacityUniqueId = 'componentsTable.accordion.capacity.label',
  ammunitionType = 'Ammunition Type',
  factory = 'Factory',
  cartridgeComposition = 'Cartridge Composition',
  colourOfPrimerSealant = 'Colour of Primer Sealant',
  projectileShape = 'Projectile Shape',
  functionalType = 'Functional Type',
  shotSize = 'Shot Size',
  munitionType = 'Munition Type',
  munitionVelocity = 'Munition Velocity',
  alphanumericalMarkings = 'Alphanumerical Markings',
  lotNumber = 'ammunitionTable.accordion.lotNumber.label',
  batchNumber = 'ammunitionTable.accordion.batchNumber.label',
  ammunitionManufacturerUniqueId = 'ammunitionTable.accordion.ammunitionManufacturer.label',
  factoryUniqueId = 'ammunitionTable.accordion.factory.label',
  manufactureDateFrom = 'ammunitionTable.accordion.manufactureDateFrom.label',
  manufactureDateTo = 'ammunitionTable.accordion.manufactureDateTo.label',
  expiryDateFrom = 'ammunitionTable.accordion.expiryDateFrom.label',
  expiryDateTo = 'ammunitionTable.accordion.expiryDateTo.label',
  functionalTypeUniqueId = 'ammunitionTable.accordion.functionalType.label',
  cartridgeCompositionUniqueId = 'ammunitionTable.accordion.catridgeComposition.label',
  colourOfPrimerSealantUniqueId = 'ammunitionTable.accordion.colourOfPrimerSealant.label',
  projectileShapeUniqueId = 'ammunitionTable.accordion.projectileShape.label',
  shotSizeUniqueId = 'ammunitionTable.accordion.shotSize.label',
  munitionTypeUniqueId = 'ammunitionTable.accordion.munitionType.label',
  munitionVelocityUniqueId = 'ammunitionTable.accordion.munitionVelocity.label',
  headstampYearMarking = 'ammunitionTable.accordion.headstampYearMarking.label',
  alphanumericalMarkins = 'ammunitionTable.accordion.alphanumericalMarkins.label',
  componentType = 'Component Type',
  length = 'componentsTable.accordion.length.label',
  componentCapacityUniqueId = 'componentsTable.accordion.capacity.label',
  frtType = 'componentsTable.accordion.frtType.label',
  frtMake = 'componentsTable.accordion.frtMake.label',
  frtModel = 'componentsTable.accordion.frtModel.label',
  frtManufacturer = 'componentsTable.accordion.frtManufacturer.label',
  productName = 'ammunitionTable.accordion.productName.column',
}

export enum EAncillaryComponentLabels {
  ancillaryTypeUniqueId = 'componentsTable.accordion.componentType.label',
  fireCycleId = 'componentsTable.accordion.fireCycleId.label',
  serialNumber = 'componentsTable.accordion.serialNumber.label',
  exhibitNumber = 'componentsTable.accordion.exhibitNumber.label',
  manufacturerUniqueId = 'componentsTable.accordion.componentManufacturer.label',
  calibreUniqueId = 'componentsTable.accordion.calibre.label',
  length = 'componentsTable.accordion.length.label',
  compositionUniqueId = 'componentsTable.accordion.composition.label',
  proofHouseUniqueId = 'componentsTable.accordion.proofMark.label',
  stateUniqueId = 'componentsTable.accordion.componentState.label',
  legalityUniqueId = 'componentsTable.accordion.componentLegality.label',
  owner = 'componentsTable.accordion.owner.label',
  keeper = 'componentsTable.accordion.keeper.label',
  frtType = 'componentsTable.accordion.frtType.label',
  frtMake = 'componentsTable.accordion.frtMake.label',
  frtModel = 'componentsTable.accordion.frtModel.label',
  frtManufacturer = 'componentsTable.accordion.frtManufacturer.label',
  componentCapacityUniqueId = 'componentsTable.accordion.capacity.label',
  ownerName = 'accessoryDetails.transactions.owner.column',
  ownerUniqueId = 'accessoryDetails.transactions.owner.column',
  keeperName = 'accessoryDetails.transactions.keeper.column',
  keeperUniqueId = 'accessoryDetails.transactions.keeper.column',
  productName = 'ammunitionTable.accordion.productName.column',
}

const DetailAncillariesReference = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const [fields, setFields] = useState<string[] | undefined>(undefined);
  const transactionPagePath = useMatch(ERouteLinks.LegalEntityViewAncillary);
  const isTransactionPage = !!transactionPagePath;
  const queryParams = useQuery();
  const typeLE: string | null = queryParams.get('type');
  const ancillaryDetails = useMatch(ERouteLinks.ViewAncillaryDetails);
  const { hasPermissions } = usePermission();

  const {
    loadDictionaries,
    artAccessoryDetail,
    artAmmunitionDetail,
    artComponentDetail,
    getARTAccessoryDetail,
    getARTAmmunitionDetail,
    getARTComponentDetail,
  } = useAncillaries();

  useEffect(() => {
    if (type && id) {
      if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async () => {
          await getARTAccessoryDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async () => {
          await getARTAmmunitionDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async () => {
          await getARTComponentDetail(id);
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  useEffect(() => {
    if (artAccessoryDetail?.accessoryTypeUniqueId) {
      setFields(getFieldsByAccessoriesType(artAccessoryDetail?.accessoryTypeUniqueId));
    } else if (artAmmunitionDetail?.ammunitionTypeUniqueId) {
      setFields(
        getFieldsByAmmunitionType(
          artAmmunitionDetail?.ammunitionTypeUniqueId as EArtAmmunitionType,
        ),
      );
    } else if (artComponentDetail?.componentTypeUniqueId) {
      setFields(
        getFieldsByComponentType(
          artComponentDetail?.componentTypeUniqueId as EArtComponentType,
        ),
      );
    }
  }, [artAccessoryDetail, artAmmunitionDetail, artComponentDetail]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <div>
      {type === EArtAncillaryTypeName.ACCESSORY.toLowerCase() && (
        <>
          {!ancillaryDetails && (
            <Breadcrumbs
              items={[
                {
                  label: t('module.ancillaries.name'),
                  route: ERouteLinks.Ancillaries,
                },
                {
                  label: t('module.ancillariesreferencetable.name'),
                  route: `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.ACCESSORIES}`,
                },
                t('accessoryDetails.accordion.title.label'),
              ]}
            />
          )}
          {artAccessoryDetail && fields && (
            <Grid container item gap={3}>
              <StyledPageTitle variant="h4">
                {t('accessoryDetails.accordion.title.label')}
              </StyledPageTitle>
              <DetailsTemplate
                title={artAccessoryDetail?.accessoryType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToSelect={() => {
                  navigate(
                    `${ERouteLinks.LegalEntityAddAncillary.replace(
                      ':id',
                      transactionPagePath?.params?.leId ?? '',
                    )}?type=${typeLE}&expand=ancillaries`,
                    {
                      state: {
                        ancillary: artAccessoryDetail,
                        ancillaryType: EArtAncillaryTypeName.ACCESSORY,
                        prevRoute: 'prevRoute',
                      },
                    },
                  );
                }}
                redirectToEditView={
                  !ancillaryDetails && hasPermissions([EPermission.MANAGE_LOCAL_ART])
                    ? () => {
                        navigate(
                          ERouteLinks.EditAncillaries.replace(
                            ':id',
                            artAccessoryDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase()),
                        );
                      }
                    : undefined
                }
              >
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.ACCESSORY.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.accessoryType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAccessoryDetail?.accessoryType}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAccessoryDetail?.artId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAccessoryDetail?.manufacturer)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.productName)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAccessoryDetail?.productName)}
                  </Typography>
                </Grid>

                {fields.includes('hostTypeUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.hostType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.hostType)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.calibre)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('compositionUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.composition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.composition)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('proofHouseUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.proofHouse)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.proofHouse)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('lightColourUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.lightColour)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.lightColour)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('capacity') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.capacity)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.capacity)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('magnificationUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.magnification)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.magnification)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('utilityUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.utility)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.utility)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('rectileUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.rectile)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.rectile)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('notes') && (
                  <Grid item xs={12} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.notes)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAccessoryDetail?.notes)}
                    </Typography>
                  </Grid>
                )}
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}

      {type === EArtAncillaryTypeName.AMMUNITION.toLowerCase() && (
        <>
          {!ancillaryDetails && (
            <Breadcrumbs
              items={[
                {
                  label: t('module.ancillaries.name'),
                  route: ERouteLinks.Ancillaries,
                },
                {
                  label: t('module.ancillariesreferencetable.name'),
                  route: `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.AMMUNITION}`,
                },
                t('module.ammunitionDetails.name'),
              ]}
            />
          )}
          {artAmmunitionDetail && fields && (
            <Grid container item gap={3}>
              <StyledPageTitle variant="h4">
                {t('module.ammunitionDetails.name')}
              </StyledPageTitle>
              <DetailsTemplate
                title={artAmmunitionDetail?.ammunitionType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToSelect={() => {
                  navigate(
                    `${ERouteLinks.LegalEntityAddAncillary.replace(
                      ':id',
                      transactionPagePath?.params?.leId ?? '',
                    )}?type=${typeLE}&expand=ancillaries`,
                    {
                      state: {
                        ancillary: artAmmunitionDetail,
                        ancillaryType: EArtAncillaryTypeName.AMMUNITION,
                        prevRoute: 'prevRoute',
                      },
                    },
                  );
                }}
                redirectToEditView={
                  !ancillaryDetails && hasPermissions([EPermission.MANAGE_LOCAL_ART])
                    ? () => {
                        navigate(
                          ERouteLinks.EditAncillaries.replace(
                            ':id',
                            artAmmunitionDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase()),
                        );
                      }
                    : undefined
                }
              >
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.AMMUNITION.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAmmunitionDetail?.ammunitionType)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAmmunitionDetail?.artId)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAmmunitionDetail?.manufacturer)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productName)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAmmunitionDetail?.productName)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.factory)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artAmmunitionDetail?.factory)}
                  </Typography>
                </Grid>
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.calibre)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('cartridgeCompositionUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.cartridgeComposition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.cartridgeComposition)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('colourOfPrimerSealantUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.colourOfPrimerSealant)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.colourOfPrimerSealant)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('projectileShapeUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.projectileShape)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.projectileShape)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('functionalTypeUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.functionalType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.functionalType)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('shotSizeUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.shotSize)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.shotSize)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('munitionTypeUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.munitionType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.munitionType)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('munitionVelocityUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.munitionVelocity)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.munitionVelocity)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('headstampYearMarking') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.headstampYearMarking)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.headstampYearMarking)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('alphanumericalMarkings') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.alphanumericalMarkings)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artAmmunitionDetail?.alphanumericalMarkings)}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('notes') && (
                  <Grid item xs={12} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.notes)}
                    </Typography>
                    <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                      {emptyFieldHolder(artAmmunitionDetail?.notes)}
                    </Typography>
                  </Grid>
                )}
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}

      {type === EArtAncillaryTypeName.COMPONENTS.toLowerCase() && (
        <>
          {!ancillaryDetails && (
            <Breadcrumbs
              items={[
                {
                  label: t('module.ancillaries.name'),
                  route: ERouteLinks.Ancillaries,
                },
                {
                  label: t('module.ancillariesreferencetable.name'),
                  route: `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.COMPONENTS}`,
                },
                t('module.componentDetails.name'),
              ]}
            />
          )}

          {artComponentDetail && fields && (
            <Grid container item gap={3}>
              <StyledPageTitle variant="h4">
                {t('module.componentDetails.name')}
              </StyledPageTitle>
              <DetailsTemplate
                title={artComponentDetail?.componentType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToSelect={() => {
                  navigate(
                    `${ERouteLinks.LegalEntityAddAncillary.replace(
                      ':id',
                      transactionPagePath?.params?.leId ?? '',
                    )}?type=${typeLE}&expand=ancillaries`,
                    {
                      state: {
                        ancillary: artComponentDetail,
                        ancillaryType: EArtAncillaryTypeName.COMPONENTS,
                        prevRoute: 'prevRoute',
                      },
                    },
                  );
                }}
                redirectToEditView={
                  !ancillaryDetails && hasPermissions([EPermission.MANAGE_LOCAL_ART])
                    ? () => {
                        navigate(
                          ERouteLinks.EditAncillaries.replace(
                            ':id',
                            artComponentDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase()),
                        );
                      }
                    : undefined
                }
              >
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.COMPONENT.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.componentType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.componentType}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.artId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.manufacturer}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.productName)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {emptyFieldHolder(artComponentDetail?.productName)}
                  </Typography>
                </Grid>
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.calibre}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('length') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.length)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.length}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('compositionUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.composition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.composition}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('proofHouseUniqueId') && (
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.proofHouse)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.proofHouse}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('notes') && (
                  <Grid item xs={12} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.notes)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {emptyFieldHolder(artComponentDetail?.notes)}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  container
                  sx={{
                    borderTop: `1px solid ${colors.primary25}`,
                    marginTop: '30px',
                    paddingTop: '25px',
                  }}
                >
                  <Grid item lg={12} container direction="column">
                    <StyledPageTitle variant="h5" sx={{ marginBottom: '20px' }}>
                      {t('ancillaries.accordion.firearmInformation.label')}
                    </StyledPageTitle>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtType)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {emptyFieldHolder(artComponentDetail?.frtType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtManufacturer)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {emptyFieldHolder(artComponentDetail?.frtManufacturer)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtMake)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {emptyFieldHolder(artComponentDetail?.frtMake)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtModel)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {emptyFieldHolder(artComponentDetail?.frtModel)}
                    </Typography>
                  </Grid>
                </Grid>
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default DetailAncillariesReference;
