import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { usePermission } from 'hooks/usePermission';

interface IAmmunitionCard {
  data: ITableDataSource;
  handleEdit?: (args: string) => void;
  handleView?: (args: string) => void;
  renderColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
}

export enum EAmmunitionTableLabels {
  fireCycleId = 'ammunitionTable.accordion.fireCycleId.column',
  lotNumber = 'ammunitionTable.accordion.lotNumber.column',
  batchNumber = 'ammunitionTable.accordion.batchNumber.column',
  asset = 'ammunitionTable.accordion.assetId.column',
  exhibitNumber = 'ammunitionTable.accordion.exhibitNumber.column',
  ammunitionType = 'ammunitionTable.accordion.ammunitionType.column',
  calibre = 'ammunitionTable.accordion.calibre.column',
  ammunitionManufacturer = 'ammunitionTable.accordion.ammunitionManufacturer.column',
  productName = 'ammunitionTable.accordion.productName.column',
  owner = 'ammunitionTable.accordion.owner.column',
  keeper = 'ammunitionTable.accordion.keeper.column',
  state = 'ammunitionTable.accordion.state.column',
}

const AmmunitionCard = ({ data, handleEdit, handleView, renderColor }: IAmmunitionCard) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.fireCycleId)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.lotNumber)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lotNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.batchNumber)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.batchNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.asset)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.assetId)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.exhibitNumber)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.exhibitNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.ammunitionType)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ammunitionType)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.calibre)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.ammunitionManufacturer)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.productName)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ammunitionProductName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.owner)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ownerName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.keeper)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeperName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTableLabels.state)}
        </Typography>
        {data.state ? <Chips text={data.state} variant={renderColor(data).stateField} /> : '-'}
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('general.actions.button')}:{' '}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={9} spacing={1} justifyContent="flex-end">
          {handleEdit && hasPermissions([EPermission.EDIT_ANCILLARY]) && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('ammunitionTable.accordion.edit.button')}
                variant={EButtonVariants.outlined}
                permission={[EPermission.EDIT_ANCILLARY]}
                onClick={() => handleEdit(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {handleView && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('ammunitionTable.accordion.details.button')}
                variant={EButtonVariants.outlined}
                onClick={() => handleView(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AmmunitionCard };
