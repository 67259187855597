import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import AuditLogSearchForm from 'pages/AuditLogPage/AuditLogSearchForm';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { useAuditLog } from 'pages/AuditLogPage/hooks';
import { EPerPages, ISortOptions } from 'models/table';
import { IAuditLogSearchFormValues, initialAuditLogSearchValues } from 'models/auditLog';
import { Pagination } from 'components/molecules/Pagination';
import { Table } from 'components/molecules/Table';
import { auditLogTableColumns, mapAuditLogToDataSource } from 'pages/AuditLogPage/helpers';
import { useRoles } from 'pages/RolesPage/hooks';
import { exportAuditLog } from 'requests/report';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import AuditLogTableCard from 'pages/AuditLogPage/AuditLogTableCard';
import { useLang } from 'models/langContext';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { Loader } from 'components/atoms/Loader';
import { Tooltip } from '@mui/material';
import { isExportAvailable } from 'constants/Export';
import { useLocation, useSearchParams } from 'react-router-dom';
import { objectToQueryString } from 'helpers/searchQuery';

export interface ISearchParams {
  [key: string]: string | Date;
}

const AuditLogPage = () => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { pathname }: any = useLocation();
  const { isDesktop } = useThemeBreakpoint();
  const { getAuditLogData, auditLogs, paginator } = useAuditLog();
  const { hasPermissions } = usePermission();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [auditLogQuery, setAuditLogQuery] = useState<Partial<IAuditLogSearchFormValues>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const { getRolesData, roles } = useRoles();
  const { midDateFormat } = useGlobalProperty();
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState<IAuditLogSearchFormValues | null>(null);

  useEffect(() => {
    (async () => {
      await getRolesData(1, 100);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    const queryValues: ISearchParams = {};
    query.forEach((value, field) => {
      if (field in initialAuditLogSearchValues) {
        queryValues[field] = value;
      }
    });
    setInitialValues({ ...initialAuditLogSearchValues, ...queryValues });
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (!auditLogs.length) {
        setIsLoading(true);
      }
      setIsPaginationLoading(true);
      await getAuditLogData(currentPage, perPage, auditLogQuery, sort);
      setIsPaginationLoading(false);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, auditLogQuery, sort, selectedLanguage]);

  const handleSetAuditLogQuery = (values: Partial<IAuditLogSearchFormValues>) => {
    const query = objectToQueryString(values);
    if (query.length) {
      window.history.replaceState({}, '', `${pathname}?${query}`);
    }
    setAuditLogQuery(values);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          t('auditLogDetails.breadcrumbs.auditLog'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('auditLogDetails.breadcrumbs.auditLog')}
      </StyledPageTitle>

      {initialValues && (
        <AuditLogSearchForm
          onSubmit={handleSetAuditLogQuery}
          initialSearchForm={initialValues}
          isSearchButtonDisabled={isLoading}
        />
      )}

      {hasPermissions(EPermission.EXPORT_AUDIT_LOG) && (
        <TableTopButtons>
          <Grid container justifyContent="flex-end" columns={10}>
            <Grid item lg={2} sm={10} xs={10} sx={{ marginRight: '10px' }}>
              {!isLoading &&
                (isExportAvailable(paginator) ? (
                  <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                    <span>
                      <Button
                        label={t('searchForAuditLog.export.button')}
                        variant={EButtonVariants.contained}
                        onClick={() => {
                          setIsExportLoading(true);
                          exportAuditLog(auditLogQuery).finally(() => {
                            setIsExportLoading(false);
                          });
                        }}
                        fullWidth={true}
                        size={EButtonSizes.small}
                        isLoading={isExportLoading}
                        disabled
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    label={t('searchForAuditLog.export.button')}
                    variant={EButtonVariants.contained}
                    onClick={() => {
                      setIsExportLoading(true);
                      exportAuditLog(auditLogQuery).finally(() => {
                        setIsExportLoading(false);
                      });
                    }}
                    fullWidth={true}
                    size={EButtonSizes.small}
                    isLoading={isExportLoading}
                  />
                ))}
            </Grid>
          </Grid>
        </TableTopButtons>
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isDesktop ? (
            <Table
              columns={auditLogTableColumns({
                roles,
                dateFormat: midDateFormat,
              })}
              dataSource={mapAuditLogToDataSource(auditLogs)}
              onSort={setSort}
            />
          ) : (
            <CardList
              items={mapAuditLogToDataSource(auditLogs)}
              render={(data, index) => (
                <AuditLogTableCard data={data} key={index} roles={roles} />
              )}
            />
          )}
        </>
      )}

      {paginator && (
        <Pagination
          count={paginator.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </>
  );
};

export default AuditLogPage;
