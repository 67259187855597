import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useForm } from 'react-hook-form';
import {
  IAttachmentForm,
  IAttachmentFormValues,
  IFormElementOptionProp,
  initialAttachmentFormValues,
} from 'models/form';
import Button from 'components/atoms/Button';
import { EIconTypes } from 'constants/Icons';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { FormInput } from 'components/molecules/FormInput';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { EMIMETypes } from 'constants/MIMETypes';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import { IShortTransaction } from 'models/transaction';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { handleRestApiException } from 'utils/handleRestApiException';
import { removeFileExtension } from 'utils/removeFileExtension';
import useAttachmentFormSchema from './schema';

const MAX_FILE_SIZE = 3;

const AttachmentForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  usedTransactions,
  transactionType,
}: IAttachmentForm) => {
  const [attachment, setAttachment] = useState<any>(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const { t } = useTranslations();
  const { schema } = useAttachmentFormSchema();
  const { getDateFormat, midDateFormat } = useGlobalProperty();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IAttachmentFormValues>({
    defaultValues: initialFormValues || initialAttachmentFormValues,
    resolver: yupResolver(schema),
  });
  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    if (!attachment) {
      setValue('attachmentName', '');
    } else if (attachment?.name) {
      setValue('attachmentName', removeFileExtension(attachment.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  useEffect(() => {
    reset(initialFormValues || initialAttachmentFormValues);
  }, [reset, initialFormValues]);

  const mapDictionaryOptionsToTransactionOptions = (
    options: IFormElementOptionProp[],
    transactions: IShortTransaction[],
  ): IFormElementOptionProp[] =>
    transactions.map((transaction) => {
      const validOption = options.find((option) => option.value === transaction.typeUniqueId);
      return {
        value: transaction.transactionUniqueId,
        label: `${validOption?.label} ${formatDate(
          transaction.transactionTime,
          midDateFormat,
        )}`,
      };
    });

  const onSubmitForm = async (data: any) => {
    setIsSubmitLoading(true);
    const fileSizeInMB = attachment.size / 1024 / 1024;
    let formErrors = false;
    if (attachment && fileSizeInMB > MAX_FILE_SIZE) {
      formErrors = true;
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('addAttachment.fileSizeValidation.text'),
        variant: EAlertVariants.error,
      });
    }
    if (
      ![
        EMIMETypes.JPG,
        EMIMETypes.JPEG,
        EMIMETypes.PNG,
        EMIMETypes.PDF,
        EMIMETypes.XLSX,
      ].includes(attachment.type)
    ) {
      formErrors = true;
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('addAttachment.fileTypeValidation.text'),
        variant: EAlertVariants.error,
      });
    }
    if (!formErrors) {
      try {
        await onSubmit({ ...data, attachment });
      } catch (e: any) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: handleRestApiException(e) ?? '',
          variant: EAlertVariants.error,
        });
      }
    }
    setIsSubmitLoading(false);
  };

  const prepareTransactionTypeOptions = () => {
    const options = mapDictionaryToOptionProp(transactionType ?? []).filter((item) =>
      usedTransactions
        .map((transaction) => transaction.typeUniqueId)
        .includes(item.value as string),
    );
    return mapDictionaryOptionsToTransactionOptions(options, usedTransactions);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container>
        <Grid item xs={12} container>
          <Grid item lg={9} xs={12}>
            <Box mb={2}>
              <Alert
                text={t('general.field.availableAttachmentFormats.text')}
                variant={EAlertVariants.warning}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormInput
              name="attachmentName"
              label={`${t('componentDetails.attachments.attachmentName.column')}`}
              control={control}
              setValue={setValue}
              errors={errors}
              InputProps={{ disabled: !attachment?.name }}
              required={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{ marginTop: { lg: '28px' }, marginBottom: { xs: 3 }, ml: { lg: 2 } }}
          >
            <Button
              isUploadButton
              onClick={(files) => {
                setAttachment(files[0]);
                clearAlert(ActionTypes.SET_SAME_PAGE_ALERT);
              }}
              fullWidth
              label={t('addAttachment.addFile.label')}
              icon={EIconTypes.plus}
              size={EButtonSizes.small}
              variant={EButtonVariants.outlined}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6}>
            <Box mb={1}>
              <FormSelect
                options={prepareTransactionTypeOptions()}
                setValue={setValue}
                name="transactionUniqueId"
                label={t('attachmentForm.transaction.label')}
                control={control}
                errors={errors}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} lg={3}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="save-button"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </form>
  );
};
export { AttachmentForm };
