import React, { useEffect, useState } from 'react';
import { IARTComponentsFormValues } from 'models/ancillaries';
import { useTranslations } from 'hooks/useTranslations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useComponentsFieldsFormSchema from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm/schemaValidation';
import { useLang } from 'models/langContext';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { EArtComponentType } from 'constants/ArtAccessoryType';
import { getFieldsByComponentType } from 'pages/AncillariesPage/helpers';
import Grid from '@mui/material/Grid';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { Typography } from '@mui/material';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { colors } from 'theme/colors';
import Link from '@mui/material/Link';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EIconTypes } from 'constants/Icons';
import { useScroll } from 'hooks/useScroll';
import {
  componentTypeForFirearmType,
  ESearchFormLabels,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm/helpers';
import { IFormElementOptionProp } from 'models/form';

const initialFormValues: IARTComponentsFormValues = {
  componentTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  calibreUniqueId: '',
  length: '',
  compositionUniqueId: '',
  capacityUniqueId: '',
  proofHouseUniqueId: '',
  notes: '',

  frtId: '',
  frtLocal: false,
  frtMake: '',
  frtModel: '',
  frtManufacturer: '',
  frtType: '',
};

interface IARTComponentsForm {
  onSubmit: (arg: IARTComponentsFormValues) => void;
  onCancel: () => void;
  initialData?: IARTComponentsFormValues;
  isEdit?: boolean;
}

const ARTComponentsForm = ({
  onSubmit,
  onCancel,
  initialData,
  isEdit = false,
}: IARTComponentsForm) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { schema } = useComponentsFieldsFormSchema();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [selectedFirearm, setSelectedFirearm] = useState<any>(null);
  const { isDesktop } = useThemeBreakpoint();
  const { elRef, scrollToError } = useScroll();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [componentTypeProps, setComponentTypeProps] = useState<IFormElementOptionProp[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IARTComponentsFormValues>({
    defaultValues: isEdit ? initialData : initialFormValues,
    resolver: yupResolver(schema),
  });
  const { selectedLanguage } = useLang();
  const firearmType =
    routerState && routerState.firearm && routerState.firearm.type
      ? routerState.firearm.type
      : initialData?.frtType;

  const {
    loadDictionaries,
    artComponentType,
    artComponentManufacturer,
    artComponentCalibre,
    artComponentCapacity,
    artComponentProofHouse,
    artAccessoryComposition,
  } = useAncillaries();

  useEffect(() => {
    (async () => {
      await loadDictionaries([
        EDictionaryTypes.ArtComponentType,
        EDictionaryTypes.ArtComponentManufacturer,
        EDictionaryTypes.ArtComponentCalibre,
        EDictionaryTypes.ArtComponentCapacity,
        EDictionaryTypes.ArtComponentProofHouse,
        EDictionaryTypes.ArtaccessoryComposition,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    setComponentTypeProps(
      mapDictionaryToOptionProp(
        artComponentType?.filter(
          (componentTypeDict) =>
            componentTypeDict.uniqueId &&
            componentTypeForFirearmType(firearmType)
              .map((value) => value.toString())
              .includes(componentTypeDict.uniqueId.toString()),
        ) ?? [],
      ),
    );
  }, [artComponentType, firearmType]);

  useEffect(() => {
    reset(initialFormValues);
    if (isEdit && initialData) {
      const firearm = {
        id: initialData?.frtId,
        make: initialData?.frtMake,
        model: initialData?.frtModel,
        manufacturer: initialData?.frtManufacturer,
        action: '',
        type: initialData?.frtType,
        country: null,
        isLocal: initialData?.frtLocal,
      };
      setSelectedFirearm(firearm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (routerState?.firearm) {
      setSelectedFirearm(routerState.firearm);
    }
  }, [routerState]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const submitForm = async (data: IARTComponentsFormValues) => {
    setSubmitLoading(true);
    if (selectedFirearm) {
      if (selectedFirearm?.id) {
        data.frtId = selectedFirearm.id;
      }
      if (selectedFirearm?.isLocal) {
        data.frtLocal = selectedFirearm.isLocal;
      }
      if (selectedFirearm?.make) {
        data.frtMake = selectedFirearm.make;
      }
      if (selectedFirearm?.model) {
        data.frtModel = selectedFirearm.model;
      }
      if (selectedFirearm?.manufacturer) {
        data.frtManufacturer = selectedFirearm.manufacturer;
      }
      if (selectedFirearm?.type) {
        data.frtType = selectedFirearm.type;
      }
    }
    await onSubmit(data);
    setSubmitLoading(false);
  };

  const handleCancel = () => {
    if (routerState?.prevPage) {
      navigate(routerState.prevPage);
    } else {
      onCancel();
    }
  };

  const componentsTypeChange = (value: EArtComponentType | '') => {
    const notes = getValues('notes');
    if (isEdit) {
      reset({ ...initialData, componentTypeUniqueId: value, notes });
    } else {
      reset({ ...initialFormValues, componentTypeUniqueId: value, notes });
    }
    if (value) {
      const fields = getFieldsByComponentType(value);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (isEdit && initialData?.componentTypeUniqueId) {
      componentsTypeChange(initialData.componentTypeUniqueId as EArtComponentType);
      reset(initialData);
    }
  }, []);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  let frtDetailsTitle = '';
  if (selectedFirearm) {
    const { make, model, action } = selectedFirearm;
    frtDetailsTitle = `${make ? `${make}` : ''}${model ? `, ${model}` : ''}${
      action ? `, ${action}` : ''
    }`;
  }

  const removeFirearmButtonProps = {
    ...(isDesktop ? { icon: EIconTypes.delete } : { label: t('general.delete.text') }),
    ...(isDesktop ? {} : { size: EButtonSizes.small }),
    ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
    ...(isDesktop
      ? { variant: EButtonVariants.outlinedIconTertiary }
      : { variant: EButtonVariants.outlined }),
    fullWidth: !isDesktop,
    disabled: isEdit,
  };

  const renderFirearmDetailsActionButtons = (isTemplateFooter: boolean) => (
    <Grid
      container
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        pt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
        mt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
      }}
    >
      {!isDesktop && !isTemplateFooter && (
        <Grid item xs="auto" sm="auto">
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('general.actions.button')}:{' '}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        xs={8}
        sm={6}
        md={1}
        spacing={2}
        sx={{
          display: {
            xs: isTemplateFooter ? 'none' : 'flex',
            lg: isTemplateFooter ? 'flex' : 'none',
          },
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sm="auto"
          sx={{
            minWidth: { sm: !isTemplateFooter ? 200 : 'auto' },
            maxWidth: { xs: !isTemplateFooter ? 200 : 'auto', sm: 'auto' },
          }}
        >
          <Button
            {...removeFirearmButtonProps}
            onClick={() => {
              setSelectedFirearm(null);
              if (routerState?.firearm) {
                delete routerState.firearm;
              }
              window.history.replaceState({}, document.title);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div ref={elRef}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <SearchWrapper>
              <Typography variant="h5">
                {t('addAncillary.firearmDetails.section.title.label')}
              </Typography>
            </SearchWrapper>
          </Grid>

          {!isEdit && (
            <Grid item xs={12} lg="auto" mb={2}>
              <Button
                fullWidth
                id="ancillary-reference-table_component_select-firearm-reference"
                label={t('ancillaryReferenceTable.component.selectFirearmReference.label')}
                size={EButtonSizes.small}
                variant={EButtonVariants.outlined}
                disabled={isEdit}
                onClick={() =>
                  navigate(`${ERouteLinks.AddAncillariesSearchForFRTFirearm}`, {
                    state: { ...routerState, ancillaries: true },
                  })
                }
              />
            </Grid>
          )}
          {selectedFirearm !== null ? (
            <Grid
              container
              sx={{
                border: `1px solid ${colors.primary25}`,
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: colors.primary25,
                }}
                px={2}
                py={1}
                container
                columns={12}
              >
                <Grid item xs={11}>
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    {frtDetailsTitle}
                  </Typography>
                </Grid>
                {!isEdit && renderFirearmDetailsActionButtons(false)}
              </Grid>
              <Grid item container p={2} rowSpacing={2}>
                <Grid item xs={12} lg={6} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('addFirearm.firearmDetails.section.firearmsReferenceNo.label')}
                  </Typography>
                  <Typography variant="body2">
                    {selectedFirearm?.frtId ?? selectedFirearm?.id}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Link
                    href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${
                      routerState?.firearm?.id || selectedFirearm?.id
                    }`}
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      fullWidth
                      label={t('addFirearm.firearmDetails.section.showFRTInformation.button')}
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                    />
                  </Link>
                </Grid>
                {!isEdit && renderFirearmDetailsActionButtons(true)}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        {selectedFirearm !== null && (
          <Grid container spacing={2} columns={10}>
            <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '20px' }}>
              <SearchWrapper>
                <Typography variant="h5">
                  {t('ancillariesDetails.accordion.title.label')}
                </Typography>
              </SearchWrapper>
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={componentTypeProps}
                name="componentTypeUniqueId"
                label={t(ESearchFormLabels.componentTypeUniqueId)}
                setValue={setValue}
                setValueAdditionalBehavior={componentsTypeChange}
                canBeEmpty
                control={control}
                errors={errors}
                onSelect={componentsTypeChange}
                disabled={isEdit || componentTypeProps.length === 0}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentManufacturer)}
                name="manufacturerUniqueId"
                label={t(ESearchFormLabels.manufacturerUniqueId)}
                setValue={setValue}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('manufacturerUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="productName"
                label={t(ESearchFormLabels.productName)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('productName')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentCalibre)}
                name="calibreUniqueId"
                label={t(ESearchFormLabels.calibreUniqueId)}
                setValue={setValue}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('calibreUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="length"
                label={t(ESearchFormLabels.length)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('length')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryComposition)}
                name="compositionUniqueId"
                label={t(ESearchFormLabels.compositionUniqueId)}
                setValue={setValue}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('compositionUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentCapacity)}
                name="capacityUniqueId"
                label={t(ESearchFormLabels.capacityUniqueId)}
                setValue={setValue}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('capacityUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artComponentProofHouse)}
                name="proofHouseUniqueId"
                label={t(ESearchFormLabels.proofHouseUniqueId)}
                setValue={setValue}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('proofHouseUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormInput
                name="notes"
                type={EFormInputTypes.textarea}
                label={t(ESearchFormLabels.notes)}
                setValue={setValue}
                control={control}
                errors={errors}
                multiline
                minRows={4}
                disabled={checkDisabled('notes')}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              columns={10}
              columnSpacing={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item xs={12} container columnSpacing={3} rowGap={2} mt={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    type="submit"
                    fullWidth
                    label={t('general.save.button')}
                    id="save-button"
                    variant={EButtonVariants.contained}
                    isLoading={isSubmitLoading}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    fullWidth
                    label={t('general.cancel.button')}
                    id="cancel-button"
                    variant={EButtonVariants.outlined}
                    onClick={handleCancel}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </form>
  );
};

export default ARTComponentsForm;
