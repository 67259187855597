import { ITableColumns, ITableDataSource } from 'models/table';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import React from 'react';
import {
  ILegalEntity,
  ILegalEntityAddress,
  ILegalEntityAncillaries,
  ILegalEntityAncillaryGroup,
  ILegalEntityContact,
  ILegalEntityFirearm,
  ILegalEntityGroup,
  ILegalEntityGroupFirearm,
} from 'models/legalEntity';
import { IOrganizationLegalEntity } from 'models/organizationLegalEntity';
import { IParsedDictionary } from 'helpers/dictionary';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { colors } from 'theme/colors';
import { Box, Checkbox, Grid, Tooltip } from '@mui/material';
import { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';
import { IFirearm } from 'models/firearm';
import { EPermission } from 'models/permissions';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { getColumnTitleFromCustomField } from 'utils/table';
import { formatDate } from 'utils/date';
import {
  defaultCheckboxStyle,
  StyledCheckboxBackgroundWrapper,
} from 'components/molecules/FormCheckbox/styles';
import { IAncillary } from 'models/ancillaries';
import { EArtAncillaryType, EArtAncillaryTypeNameLowerCase } from 'constants/ArtAccessoryType';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { getAncillaryType } from 'pages/LegalEntitiesPage/ViewLegalEntityPage';

interface IAncillariesTableColumns {
  handleView: (id: string, type: string) => void;
  callback: any;
  selectAllCallback: () => void;
  areAllAncillariesSelected: boolean;
  selectedAncillaries: IAncillary[];
  renderStateFieldColor: (arg: ITableDataSource) => any;
  selectedAncillaryType: EArtAncillaryType;
}

interface ILegalEntityTableColumns {
  handleView?: (arg: string) => void;
  handleEdit?: (arg: string) => void;
  handleDelete?: (arg: string) => void;
  handleSelect?: (arg: string) => void;
  type: ELEAccordionsPageTypes;
  customFields?: ICustomFieldByObjectType[];
  t?: (key: string) => string;
}

interface ILegalEntityFirearmTableColumns {
  renderStateFieldColor: (arg: ITableDataSource) => any;
  type: string | null;
}

interface ILegalEntityAncillaryTableColumns {
  renderStateFieldColor: (arg: ITableDataSource) => any;
}

interface ILegalEntityAncillaryGroupTableColumns {
  handleAddToGroup: (groupId: string) => void;
  handleEdit: (groupId: string) => void;
  handleDelete: (groupId: string) => void;
  renderStateFieldColor: (arg: ITableDataSource) => any;
  type: string | null;
  id: string | undefined;
}

interface ILegalEntityAncillaryGroupAncillariesTableColumns {
  handleView?: (ancillaryId: string, ancillaryType: string) => void;
  handleDelete: (ancillaryId: string) => void;
  ancillaryOpenDetail?: string;
  setAncillaryOpenDetail?: (ancillaryId: string) => void;
}

interface ILegalEntityAddGroupFirearmTableColumns {
  handleDelete: (arg: string) => void;
  renderStateFieldColor: (arg: ITableDataSource) => any;
}

interface ILegalEntityGroupTableColumns {
  handleAdd: (arg: string) => void;
  handleEdit: (arg: string) => void;
  handleDelete: (arg: string) => void;
  renderStateFieldColor: (arg: ITableDataSource) => any;
  customFields?: ICustomFieldByObjectType[];
  type: string | null;
  id: string | undefined;
}

interface ILegalEntityGroupFirearmTableColumns {
  handleDelete: (arg: string) => void;
  renderStateFieldColor: (arg: ITableDataSource) => any;
}

interface ILegalEntityGroupAncillaryTableColumns {
  handleDelete: (ancillaryId: string) => void;
  ancillaryOpenDetail: string;
  setAncillaryOpenDetail: (arg: string) => void;
}

export const legalEntitiesTableColumns = (
  {
    handleEdit,
    handleDelete,
    handleSelect,
    type,
    customFields = [],
    t,
  }: ILegalEntityTableColumns,
  selectedLanguage: string | undefined,
  dateFormat: string,
  hasPermission: (
    permissions: EPermission | EPermission[],
    isORCondition?: boolean,
  ) => boolean | null,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'individual.firstName',
      title: 'legalEntities.individual.accordion.legalEntityTable.firstName.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: 'individual.lastName',
      title: 'legalEntities.individual.accordion.legalEntityTable.lastName.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'individual.dateOfBirth',
      title: 'legalEntities.individual.accordion.legalEntityTable.dateBirth.column',
      dateFormat,
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
      key: 'city',
      title: 'legalEntities.individual.accordion.legalEntityTable.city.column',
    },
    ...customFieldColumns,
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: '',
      title: 'legalEntities.individual.accordion.legalEntityTable.action.column',
      isSortable: false,
      width: '110px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <div>
          {type === ELEAccordionsPageTypes.LegalEntity ? (
            <>
              <Link
                to={`${ERouteLinks.ViewLegalEntity.replace(':id', props.id)}?type=${
                  ELegalEntityTypes.individual
                }`}
              >
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.view}
                  id={`action_view_${props.id}`}
                />
              </Link>
              {hasPermission([EPermission.MANAGE_LE]) && (
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.edit}
                  onClick={() => handleEdit && handleEdit(props.id)}
                  id={`action_edit_${props.id}`}
                />
              )}
              {hasPermission([EPermission.DELETE_LE]) && (
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.delete}
                  onClick={() => handleDelete && handleDelete(props.id)}
                  permission={[EPermission.DELETE_LE]}
                  id={`action_delete_${props.id}`}
                />
              )}
            </>
          ) : (
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.checkmark}
              id={`ind_action_check_${props.id}`}
              onClick={() =>
                handleSelect &&
                handleSelect(
                  type === ELEAccordionsPageTypes.SelectLegalEntity ? props : props.id,
                )
              }
            />
          )}
        </div>
      ),
    },
  ];
};

export const legalEntityFirearmsTableColumns = ({
  renderStateFieldColor,
  type,
}: ILegalEntityFirearmTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f509',
    key: 'groupName',
    title: '',
    isSortable: false,
    align: 'center',
    width: '50px',
    render: (_data, props) =>
      props.groupName ? (
        <Tooltip title={props.groupName}>
          <div>
            <Chips text="G" variant={EChipsVariants.oneSign} />
          </div>
        </Tooltip>
      ) : (
        ''
      ),
  },
  {
    id: 'f0504ca-6b32-482d-b369-304b6f32f510',
    key: 'fireCycleId',
    title: 'legalEntityDetails.firearm.accordion.firecycleId.column',
  },
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'legalEntityDetails.firearm.accordion.serialNumber.column',
  },
  {
    id: 'h0504ca6-6b32-482d-b369-304a6f32f411',
    key: 'exhibitNumber',
    title: 'legalEntityDetails.firearm.accordion.exhibitNumber.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f511',
    key: 'make',
    title: 'legalEntityDetails.firearm.accordion.make.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f512',
    key: 'model',
    title: 'legalEntityDetails.firearm.accordion.model.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'legalEntityDetails.firearm.accordion.manufacturer.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f514',
    key: 'caliber',
    title: 'legalEntityDetails.firearm.accordion.calibre.column',
  },
  {
    id: 'h6404ca6-6b32-482d-b369-304a6f32f515',
    key: 'owner',
    title: 'legalEntityDetails.firearm.accordion.owner.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f515',
    key: 'keeper',
    title: 'legalEntityDetails.firearm.accordion.keeper.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f516',
    key: 'state',
    title: 'legalEntityDetails.firearm.accordion.state.column',
    align: 'center',
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    align: 'center',
    isCenterTableHead: true,
    width: '60px',
    render: (_data, props) => (
      <Link to={`${ERouteLinks.ViewRegisteredFirearm.replace(':id', props.id)}?type=${type}`}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const legalEntityAncillariesTableColumns = ({
  renderStateFieldColor,
}: ILegalEntityAncillaryTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f509',
    key: 'groupName',
    title: '',
    isSortable: false,
    align: 'center',
    width: '50px',
    render: (_data, props) =>
      props.groupName ? (
        <Tooltip title={props.groupName}>
          <div>
            <Chips text="G" variant={EChipsVariants.oneSign} />
          </div>
        </Tooltip>
      ) : (
        ''
      ),
  },
  {
    id: 'f0504ca-6b32-482d-b369-304b6f32f510',
    key: 'fireCycleId',
    title: 'legalEntityDetails.ancillary.accordion.firecycleId.column',
  },
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'legalEntityDetails.ancillary.accordion.serialNumber.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f511',
    key: 'ancillaryDictionaryValue',
    title: 'legalEntityDetails.ancillary.accordion.ancillaryDictionaryValue.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f512',
    key: 'ancillaryType',
    title: 'legalEntityDetails.ancillary.accordion.ancillaryType.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'legalEntityDetails.ancillary.accordion.manufacturer.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f514',
    key: 'calibre',
    title: 'legalEntityDetails.ancillary.accordion.calibre.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f515',
    key: 'keeperName',
    title: 'legalEntityDetails.ancillary.accordion.keeper.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f516',
    key: 'state',
    title: 'legalEntityDetails.ancillary.accordion.state.column',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: '171f9c58-9f45-11ee-8c90-0242ac120002',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    align: 'center',
    isCenterTableHead: true,
    width: '60px',
    render: (_data, props) => (
      <Link
        to={ERouteLinks.ViewAncillary.replace(':id', props.id as string).replace(
          ':type',
          getAncillaryType(props.ancillaryDictionaryUniqueId),
        )}
      >
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const legalEntityAncillaryGroupsTableColumns = ({
  handleAddToGroup,
  handleEdit,
  handleDelete,
  renderStateFieldColor,
  type,
  id,
}: ILegalEntityAncillaryGroupTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f509',
    key: 'name',
    title: 'legalEntityDetails.ancillaryGroup.accordion.groupName.column',
  },
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f510',
    key: 'ancillaryAmount',
    title: 'legalEntityDetails.ancillaryGroup.accordion.ancillaryCount.column',
  },
  {
    id: '171f9a14-9f45-11ee-8c90-0242ac120002',
    key: 'owner',
    title: 'legalEntityDetails.ancillaryGroup.accordion.owner.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f515',
    key: 'keeper',
    title: 'legalEntityDetails.ancillaryGroup.accordion.keeper.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f516',
    key: 'state',
    title: 'legalEntityDetails.ancillaryGroup.accordion.state.column',
    align: 'center',
    render: (_data, props) =>
      props.state ? <Chips text={props.state} variant={renderStateFieldColor(props)} /> : '-',
  },
  {
    id: '171f9c58-9f45-11ee-8c90-0242ac120002',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    isCenterTableHead: true,
    width: '130px',
    align: 'center',
    render: (_data, props) => (
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={3}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.plus}
            onClick={() => handleAddToGroup(props.id)}
          />
        </Grid>
        <Grid item xs={3}>
          <Link
            to={`${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(
              ':id',
              id as string,
            ).replace(':groupId', props.id)}?type=${type}&expand=ancillaryGroups`}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            onClick={() => handleEdit(props.id)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.delete}
            onClick={() => handleDelete(props.id)}
          />
        </Grid>
      </Grid>
    ),
  },
];

export const legalEntityGroupFirearmsTableColumns = ({
  handleDelete,
  renderStateFieldColor,
}: ILegalEntityGroupFirearmTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca-6b32-4821-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'groupDetails.firearmListSection.serialNumber.column',
  },
  {
    id: 'f0504ca6-6b32-4822-b369-304a6f32f511',
    key: 'make',
    title: 'groupDetails.firearmListSection.make.column',
  },
  {
    id: 'f0504ca6-6b32-4823-b369-304a6f32f512',
    key: 'model',
    title: 'groupDetails.firearmListSection.model.column',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
    key: 'caliber',
    title: 'groupDetails.firearmListSection.calibre.column',
  },
  {
    id: 'f0504ca6-6b32-4827-b369-304a6f32f516',
    key: 'state',
    title: 'groupDetails.firearmListSection.state.column',
    align: 'center',
    isSortable: false,
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: 'f0504ca6-6b32-4828-b369-304a6f32f517',
    key: '',
    title: 'groupDetails.firearmListSection.action.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    width: '80px',
    render: (_data, props) => (
      <>
        <Link to={ERouteLinks.ViewRegisteredFirearm.replace(':id', props.id)}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
        />
      </>
    ),
  },
];
export const legalEntityGroupsTableColumns = ({
  handleAdd,
  handleEdit,
  handleDelete,
  type,
  renderStateFieldColor,
  id,
}: ILegalEntityGroupTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca-6b33-482d-b369-304a6f32f510',
    key: 'name',
    title: 'groups.accordion.groupName.column',
  },
  {
    id: 'f0504ca6-6b33-482d-b369-304a6f32f515',
    key: 'firearmAmount',
    title: 'groups.accordion.firearmCount.column',
  },
  {
    id: 'f0504ca6-6b33-482d-b369-304a6f32f516',
    key: 'owner',
    title: 'groups.accordion.owner.column',
  },
  {
    id: 'f0504ca6-6b33-482d-b369-304a6f32f517',
    key: 'keeper',
    title: 'groups.accordion.keeper.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f518',
    key: 'state',
    title: 'groups.accordion.state.column',
    align: 'center',
    render: (data, props) => {
      if (!data) {
        return '-';
      }
      return <Chips text={props.state} variant={renderStateFieldColor(props)} />;
    },
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f519',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    width: '130px',
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => (
      <>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.plus}
          onClick={() => handleAdd(props.id)}
        />
        <Link
          to={`${ERouteLinks.ViewLegalEntityGroup.replace(':id', id as string).replace(
            ':groupId',
            props.id,
          )}?type=${type}&expand=groups`}
        >
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
        />
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
        />
      </>
    ),
  },
];

export const mapLEToDataSource = (
  items: ILegalEntity[],
  hasViewAllLEPermission?: boolean | null,
  visibility?: IParsedDictionary,
  gender?: IParsedDictionary,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    'individual.firstName': item.firstName,
    'individual.lastName': item.lastName,
    'individual.dateOfBirth': item.dateOfBirth,
    city: item.city ?? '-',
    customFields: item.customFields || [],
    visibility:
      item.visibilityUniqueId && visibility?.[item.visibilityUniqueId]
        ? visibility[item.visibilityUniqueId]
        : '',
    gender:
      item.genderUniqueId && gender?.[item.genderUniqueId] ? gender[item.genderUniqueId] : '',
  }));

export const mapLEFirearmToDataSource = (items: ILegalEntityFirearm[]): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    serialNumber: item.serialNumber,
    make: item.make,
    model: item.model,
    manufacturer: item.manufacturer,
    caliber: item.caliber,
    keeper: item.keeper,
    stateUniqueId: item.stateUniqueId,
    state: item.state,
    groupName: item.groupName,
    fireCycleId: item.fireCycleId,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
    owner: item.owner,
    exhibitNumber: item.exhibitNumber,
  }));

export const mapLEAncillaryToDataSource = (
  items: ILegalEntityAncillaries[],
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
    serialNumber: item.serialNumber,
    stateUniqueId: item.stateUniqueId,
    state: item.state,
    manufacturer: item.manufacturer,
    keeperId: item.keeperId,
    keeperName: item.keeperName,
    ancillaryDictionaryValue: item.ancillaryDictionaryValue,
    ancillaryDictionaryUniqueId: item.ancillaryDictionaryUniqueId,
    calibre: item.calibre,
    ancillaryType: item.ancillaryType,
    fireCycleId: item.fireCycleId,
    groupName: item.groupName,
  }));

export const mapLEAncillaryGropsToDataSource = (
  items: ILegalEntityAncillaryGroup[],
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    name: item.name,
    ancillaryAmount: item.ancillaryAmount,
    state: item.state,
    owner: item.owner,
    keeper: item.keeper,
    legalityUniqueId: item.legalityUniqueId,
    activityUniqueId: item.activityUniqueId,
  }));

export const AncillaryTableColumns = ({
  callback,
  selectAllCallback,
  areAllAncillariesSelected,
  selectedAncillaries,
  selectedAncillaryType,
  renderStateFieldColor,
}: IAncillariesTableColumns): ITableColumns[] => [
  {
    id: '0504ca-6b32-482d-b369-304a6f32f5220',
    key: 'isSelected',
    align: 'center',
    title: () => (
      <StyledCheckboxBackgroundWrapper backgroundColor={colors.white}>
        <Checkbox
          id="select-all-checkbox"
          sx={{
            color: colors.gray400,
            '&.Mui-checked': {
              color: defaultCheckboxStyle.backgroundColorChecked,
            },
            '&.Mui-disabled': {
              color: defaultCheckboxStyle.backgroundColorDisabled,
            },
          }}
          checked={areAllAncillariesSelected}
          onChange={() => selectAllCallback()}
        />
      </StyledCheckboxBackgroundWrapper>
    ),
    isSortable: false,
    width: '20px',
    render: (_data, props) => (
      <Checkbox
        id="select-checkbox"
        sx={{
          color: colors.gray300,
          '&.Mui-checked': {
            color: colors.primary500,
          },
          '&.MuiCheckbox-root': {
            maxWidth: '16px',
            maxHeight: '16px',
          },
        }}
        checked={
          !!selectedAncillaries.filter((item) => item.uniqueId === props.uniqueId).length
        }
        onChange={() => callback(props)}
      />
    ),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'fireCycleId',
    title: 'searchForAncillary.list.fireCycleId.column',
    isSortable: false,
  },
  ...(selectedAncillaryType === EArtAncillaryType.AMMUNITION
    ? [
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
          key: 'lotNumber',
          title: 'searchForAncillary.list.lotNumber.column',
          isSortable: false,
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
          key: 'batchNumber',
          title: 'searchForAncillary.list.batchNumber.column',
          isSortable: false,
        },
      ]
    : [
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
          key: 'serialNumber',
          title: 'searchForAncillary.list.serialNumber.column',
          isSortable: false,
        },
      ]),
  {
    id: '7827a5d0-9807-11ee-b9d1-0242ac120002',
    key: 'ancillaryTypeDictionaryValue',
    title: 'searchForAncillary.list.ancillary.column',
    isSortable: false,
  },
  {
    id: '7827a5d0-9807-11ee-b9d1-0242ac1200022',
    key: 'ancillarySpecificType',
    title: 'searchForAncillary.list.ancillaryType.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'keeper',
    title: 'searchForAncillary.list.keeper.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'owner',
    title: 'searchForAncillary.list.owner.column',
    isSortable: false,
  },
  {
    id: '7827a85a-9807-11ee-b9d1-0242ac120002',
    key: 'calibre',
    title: 'searchForAncillary.list.calibre.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'state',
    title: 'searchForAncillary.list.state.column',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Box textAlign="center" display="flex">
        <Link
          to={ERouteLinks.ViewAncillary.replace(':id', props.id).replace(
            ':type',
            props.ancillaryType.toLowerCase(),
          )}
          target="_blank"
        >
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        {selectedAncillaries.filter((item) => item.uniqueId === props.id).length ? (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.clear}
            onClick={() => callback(props)}
          />
        ) : (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.checkmark}
            onClick={() => callback(props)}
          />
        )}
      </Box>
    ),
  },
];

export const mapAncillaryGroupAncillariesToDataSource = (
  ancillaries: IAncillary[],
): ITableDataSource[] =>
  ancillaries.map((item: any) => ({
    id: item.uniqueId,
    uniqueId: item.uniqueId,
    fireCycleId: item.fireCycleId,
    lotNumber: item.lotNumber,
    batchNumber: item.batchNumber,
    serialNumber: item.serialNumber,
    manufacturer: item.manufacturer,
    ancillaryTypeDictionaryValue: item.ancillaryTypeDictionaryValue,
    ancillarySpecificType: item.ancillarySpecificType,
    ancillaryType: item.ancillaryType,
    calibre: item.calibre,
    state: item.state,
    stateUniqueId: item.stateUniqueId,
    owner: item.owner,
    keeper: item.keeper,
    ownerUniqueId: item.ownerUniqueId,
    keeperUniqueId: item.keeperUniqueId,
    legalityUniqueId: item.legalityUniqueId,
    activityUniqueId: item.activityUniqueId,
  }));

export const mapLEGFirearmToDataSource = (items: ILegalEntityFirearm[]): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    serialNumber: item.serialNumber,
    make: item.make,
    model: item.model,
    manufacturer: item.manufacturer,
    caliber: item.caliber,
    stateUniqueId: item.stateUniqueId,
    state: item.state,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
  }));

export const mapLEGroupToDataSource = (items: ILegalEntityGroup[]): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    name: item.name,
    numberOfFirearm: item.firearmAmount,
    firearmAmount: item.firearmAmount,
    owner: item.owner,
    keeper: item.keeper,
    state: item.state,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
  }));

export const organizationLegalEntityTableColumns = (
  {
    handleEdit,
    handleDelete,
    handleSelect,
    type,
    customFields = [],
    t,
  }: ILegalEntityTableColumns,
  selectedLanguage: string | undefined,
  dateFormat: string,
  hasPermissions: (
    permissions: EPermission | EPermission[],
    isORCondition?: boolean,
  ) => boolean | null,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
      key: 'organization.organizationName',
      title: 'legalEntities.organization.accordion.legalEntityTable.name.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: 'organizationType',
      render: (_data, props) => props.organizationTypeUniqueId,
      title: 'legalEntities.organization.accordion.legalEntityTable.organizationType.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f518',
      key: 'organization.departmentName',
      title: 'legalEntities.organization.accordion.legalEntityTable.departmentName.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f519',
      key: 'city',
      title: 'legalEntities.organization.accordion.legalEntityTable.city.column',
    },
    ...customFieldColumns,
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f521',
      key: '',
      title: 'legalEntities.organization.accordion.legalEntityTable.action.column',
      isSortable: false,
      width: '110px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <div>
          {type === ELEAccordionsPageTypes.LegalEntity ? (
            <>
              <Link
                to={`${ERouteLinks.ViewLegalEntity.replace(':id', props.id)}?type=${
                  ELegalEntityTypes.organisation
                }`}
              >
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.view}
                  id={`action_view_${props.id}`}
                />
              </Link>
              {hasPermissions([EPermission.MANAGE_LE]) && (
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.edit}
                  onClick={() => handleEdit && handleEdit(props.id)}
                  id={`action_edit_${props.id}`}
                />
              )}
              {hasPermissions([EPermission.DELETE_LE]) && (
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  icon={EIconTypes.delete}
                  onClick={() => handleDelete && handleDelete(props.id)}
                  permission={[EPermission.DELETE_LE]}
                  id={`action_delete_${props.id}`}
                />
              )}
            </>
          ) : (
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.checkmark}
              id={`org_action_check_${props.id}`}
              onClick={() =>
                handleSelect &&
                handleSelect(
                  type === ELEAccordionsPageTypes.SelectLegalEntity ? props : props.id,
                )
              }
            />
          )}
        </div>
      ),
    },
  ];
};

export const mapOLEToDataSource = (
  items: IOrganizationLegalEntity[],
  organizationType: IParsedDictionary,
  visibility?: IParsedDictionary,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    'organization.organizationName': item.organizationName,
    organizationTypeUniqueId:
      item.organizationTypeUniqueId && organizationType?.[item.organizationTypeUniqueId]
        ? organizationType[item.organizationTypeUniqueId]
        : '',
    'organization.departmentName': item.departmentName,
    city: item.city || '-',
    customFields: item.customFields || [],
    visibility:
      item.visibilityUniqueId && visibility?.[item.visibilityUniqueId]
        ? visibility[item.visibilityUniqueId]
        : '',
  }));

interface ILegalEntityAddressTableColumns {
  handleEdit: (arg: string) => void;
  handleDelete: (arg: string) => void;
  t: (key: string) => string;
}

export const legalEntityAddressTableColumns = ({
  handleEdit,
  handleDelete,
  t,
}: ILegalEntityAddressTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f500',
    key: 'isDefault',
    title: 'legalEntityDetails.addressTab.storageAddress.column',
    align: 'center',
    render: (_data, props) => (
      <Chips
        text={
          props.isDefault
            ? t('general.confirmation.yes.button')
            : t('general.confirmation.no.button')
        }
        variant={props.isDefault ? EChipsVariants.success100 : EChipsVariants.inactive}
      />
    ),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'typeUniqueId',
    title: 'legalEntityDetails.addressTab.addressType.column',
    align: 'center',
    render: (_data, props) => (
      <Chips text={props.typeUniqueId} variant={EChipsVariants.primary} />
    ),
  },

  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'line1',
    title: 'legalEntityDetails.addressTab.address.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'city',
    title: 'legalEntityDetails.addressTab.city.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'regionUniqueId',
    title: 'legalEntityDetails.addressTab.stateProvince.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'countryUniqueId',
    title: 'legalEntityDetails.addressTab.country.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'gps',
    title: 'legalEntityDetails.addressTab.gps.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: 'postalCode',
    title: 'legalEntityDetails.addressTab.zipPostalCode.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f518',
    key: '',
    title: 'legalEntityDetails.addressTab.action.column',
    isSortable: false,
    align: 'center',
    width: '80px',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        <Button
          variant={EButtonVariants.outlined}
          tableButton
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
          permission={[EPermission.MANAGE_LE]}
        />
        <Button
          variant={EButtonVariants.outlined}
          tableButton
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
          permission={[EPermission.DELETE_LE]}
        />
      </>
    ),
  },
];

export const mapLEAddressToDataSource = (
  items: ILegalEntityAddress[],
  region: IParsedDictionary,
  country: IParsedDictionary,
  type: IParsedDictionary,
  legalEntityId: string,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    typeUniqueId:
      item.typeUniqueId && type?.[item.typeUniqueId] ? type[item.typeUniqueId] : '',
    line1: `${item.line1} ${item.line2}`,
    city: item.city,
    postalCode: item.postalCode,
    regionUniqueId:
      item.regionUniqueId && region?.[item.regionUniqueId] ? region[item.regionUniqueId] : '',
    countryUniqueId:
      item.countryUniqueId && country?.[item.countryUniqueId]
        ? country[item.countryUniqueId]
        : '',
    gps: `${item.latitude}, ${item.longitude}`,
    latitude: item.latitude,
    longitude: item.longitude,
    isDefault: item.isDefault,
    legalEntityId,
  }));

interface ILegalEntityContactTableColumns {
  handleEdit: (arg: string) => void;
  handleDelete: (arg: string) => void;
  type: ELegalEntityTypes;
}

export const legalEntityContactTableColumns = ({
  handleEdit,
  handleDelete,
  type,
}: ILegalEntityContactTableColumns): ITableColumns[] =>
  type === ELegalEntityTypes.organisation
    ? [
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
          key: 'name',
          title: 'legalEntityDetails.contactTab.contactName.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
          key: 'role',
          title: 'legalEntityDetails.contactTab.role.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
          key: 'phoneNumber',
          title: 'legalEntityDetails.contactTab.phoneNumber.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
          key: 'email',
          title: 'legalEntityDetails.contactTab.emailAddress.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
          key: '',
          title: 'legalEntityDetails.contactTab.action.column',
          isSortable: false,
          isCenterTableHead: true,
          width: '80px',
          align: 'center',
          render: (_data, props) => (
            <>
              <Button
                variant={EButtonVariants.outlined}
                tableButton
                icon={EIconTypes.edit}
                onClick={() => handleEdit(props.id)}
                permission={[EPermission.MANAGE_LE]}
              />
              <Button
                variant={EButtonVariants.outlined}
                tableButton
                icon={EIconTypes.delete}
                onClick={() => handleDelete(props.id)}
                permission={[EPermission.DELETE_LE]}
              />
            </>
          ),
        },
      ]
    : [
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
          key: 'phoneNumber',
          title: 'legalEntityDetails.contactTab.phoneNumber.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
          key: 'email',
          title: 'legalEntityDetails.contactTab.emailAddress.column',
        },
        {
          id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
          key: '',
          title: 'legalEntityDetails.contactTab.action.column',
          isSortable: false,
          isCenterTableHead: true,
          width: '80px',
          align: 'center',
          render: (_data, props) => (
            <>
              <Button
                variant={EButtonVariants.outlined}
                tableButton
                icon={EIconTypes.edit}
                onClick={() => handleEdit(props.id)}
                permission={[EPermission.MANAGE_LE]}
              />
              <Button
                variant={EButtonVariants.outlined}
                tableButton
                icon={EIconTypes.delete}
                onClick={() => handleDelete(props.id)}
                permission={[EPermission.DELETE_LE]}
              />
            </>
          ),
        },
      ];

export const mapLEContactToDataSource = (
  items: ILegalEntityContact[],
  phoneCode: IParsedDictionary,
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    name: item.name,
    role: item.role,
    phoneNumber:
      item.phoneNumber.codeDictionaryUniqueId &&
      phoneCode?.[item.phoneNumber.codeDictionaryUniqueId]
        ? `${phoneCode?.[item.phoneNumber.codeDictionaryUniqueId]}${item.phoneNumber.value}`
        : '',
    email: item.email || '-',
  }));

export const legalEntityGroupFirearmTableColumns = ({
  handleDelete,
  renderStateFieldColor,
}: ILegalEntityAddGroupFirearmTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'groupDetails.firearmListSection.serialNumber.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'make',
    title: 'groupDetails.firearmListSection.make.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'model',
    title: 'groupDetails.firearmListSection.model.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'calibre',
    title: 'groupDetails.firearmListSection.calibre.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'state',
    title: 'groupDetails.firearmListSection.state.column',
    isSortable: false,
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: '',
    title: 'groupDetails.firearmListSection.action.column',
    isSortable: false,
    isCenterTableHead: true,
    width: '60px',
    align: 'center',
    render: (_data, props) => (
      <Box textAlign="center">
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
        />
      </Box>
    ),
  },
];

export const mapLEGroupAncillaryListToDataSource = (items: IAncillary[]): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    fireCycleId: item.fireCycleId,
    serialNumber: item.serialNumber,
    lotNumber: item.lotNumber,
    batchNumber: item.batchNumber,
    ancillary: item.ancillaryType,
    ancillaryTypeDictionaryValue: item.ancillaryTypeDictionaryValue,
    ancillaryType: item.ancillarySpecificType,
    manufacturer: item.manufacturer,
    calibre: item.calibre,
  }));

export const legalEntityGroupAncillaryTableColumns = ({
  handleDelete,
}: ILegalEntityAncillaryGroupAncillariesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f502',
    key: 'fireCycleId',
    title: 'addGroup.ancillaryList.fireCycleId.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'addGroup.ancillaryList.serialNumber.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'lotNumber',
    title: 'addGroup.ancillaryList.lotNumber.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'batchNumber',
    title: 'addGroup.ancillaryList.batchNumber.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'ancillaryTypeDictionaryValue',
    title: 'addGroup.ancillaryList.ancillary.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'ancillaryType',
    title: 'addGroup.ancillaryList.ancillaryType.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'manufacturer',
    title: 'addGroup.ancillaryList.manufacturer.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'calibre',
    title: 'addGroup.ancillaryList.calibre.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    width: '80px',
    render: (_data, props) => (
      <Box textAlign="center" display="flex">
        <Link
          to={ERouteLinks.ViewAncillary.replace(':id', props.id).replace(
            ':type',
            props.ancillary,
          )}
          target="_blank"
        >
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
        />
      </Box>
    ),
  },
];

export const mapLEGroupFirearmListToDataSource = (
  items: ILegalEntityGroupFirearm[],
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.id,
    serialNumber: item.serialNumber,
    make: item.make,
    model: item.model,
    calibre: item.caliber,
    state: item.state,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
  }));

export const legalEntityGroupSearchFirearmTableColumns = (
  callback: any,
  selectAllCallback: () => void,
  areAllFirearmsSelected: boolean,
  selectedFirearms: IFirearm[],
  renderStateFieldColor: (arg: ITableDataSource) => any,
): ITableColumns[] => [
  {
    id: '0504ca-6b32-482d-b369-304a6f32f5220',
    key: 'isSelected',
    align: 'center',
    title: () => (
      <StyledCheckboxBackgroundWrapper backgroundColor={colors.white}>
        <Checkbox
          id="select-all-firearms-checkbox"
          sx={{
            color: colors.gray400,
            '&.Mui-checked': {
              color: defaultCheckboxStyle.backgroundColorChecked,
            },
            '&.Mui-disabled': {
              color: defaultCheckboxStyle.backgroundColorDisabled,
            },
          }}
          checked={areAllFirearmsSelected}
          onChange={() => selectAllCallback()}
        />
      </StyledCheckboxBackgroundWrapper>
    ),
    isSortable: false,
    width: '20px',
    render: (_data, props: ILegalEntityGroupFirearm) => (
      <Checkbox
        id="select-firearm-checkbox"
        sx={{
          color: colors.gray300,
          '&.Mui-checked': {
            color: colors.primary500,
          },
          '&.MuiCheckbox-root': {
            maxWidth: '16px',
            maxHeight: '16px',
          },
        }}
        checked={selectedFirearms
          .map((selectedFirearm) => selectedFirearm.uniqueId)
          .includes(props.id)}
        onChange={() => callback(props)}
      />
    ),
  },
  {
    id: 'f0504ca-6b32-482d-b369-304a6f32f510',
    key: 'serialNumber',
    title: 'searchForLegalEntitysFirearm.serialNumber.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f511',
    key: 'make',
    title: 'searchForLegalEntitysFirearm.make.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f512',
    key: 'model',
    title: 'searchForLegalEntitysFirearm.model.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'searchForLegalEntitysFirearm.manufacturer.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f514',
    key: 'caliber',
    title: 'searchForLegalEntitysFirearm.calibre.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f515',
    key: 'keeper',
    title: 'searchForLegalEntitysFirearm.keeper.column',
  },
  {
    id: 'f0504ca6-6b32-482d-b369-304a6f32f516',
    key: 'owner',
    title: 'searchForLegalEntitysFirearm.owner.column',
  },
  {
    id: '171fa2de-9f45-11ee-8c90-0242ac120002',
    key: 'state',
    title: 'searchForLegalEntitysFirearm.state.column',
    align: 'center',
    render: (_data, props) => (
      <Chips text={props.state} variant={renderStateFieldColor(props)} />
    ),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    isCenterTableHead: true,
    width: '80px',
    align: 'center',
    render: (_data, props) => (
      <Box textAlign="center" display="flex">
        <Link to={ERouteLinks.ViewRegisteredFirearm.replace(':id', props.id as string)}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        {selectedFirearms.filter((item) => item.uniqueId === props.id).length ? (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.clear}
            onClick={() => callback(props)}
          />
        ) : (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.checkmark}
            onClick={() => callback(props)}
          />
        )}
      </Box>
    ),
  },
];

export const mapLEGroupSearchFirearmToDataSource = (items: IFirearm[]): ITableDataSource[] =>
  items.map((item) => ({
    isSelected: false,
    id: item.uniqueId,
    uniqueId: item.uniqueId,
    serialNumber: item.serialNumber,
    make: item.make,
    model: item.model,
    manufacturer: item.manufacturer,
    caliber: item.caliber,
    keeper: item.keeper,
    owner: item.owner,
    state: item.state,
    legalityUniqueId: item.legalityUniqueId,
    activityUniqueId: item.activityUniqueId,
  }));

export const mapLegalEntityGroupAncillaryToDataSource = (
  items: IAncillary[],
): ITableDataSource[] =>
  items.map((item) => ({
    id: item.uniqueId,
    uniqueId: item.uniqueId,
    artId: item.artId,
    fireCycleId: item.fireCycleId,
    serialNumber: item.serialNumber,
    lotNumber: item.lotNumber,
    batchNumber: item.batchNumber,
    ancillaryType: item.ancillaryType,
    ancillaryTypeDictionaryValue: item.ancillaryTypeDictionaryValue,
    ancillarySpecificType: item.ancillarySpecificType,
    manufacturer: item.manufacturer,
    calibre: item.calibre,
    hostType: item.hostType,
    composition: item.composition,
    factory: item.factory,
    headstampYearMarking: item.headstampYearMarking,
    cartridgeComposition: item.cartridgeComposition,
    proofMark: item.proofMark,
    alphanumericalMarkings: item.alphanumericalMarkings,
    firearmManufacturer: item.firearmManufacturer,
    firearmMake: item.firearmMake,
    firearmModel: item.firearmModel,
    assetId: item.assetId,
  }));

export const legalEntityGroupAncillariesTableColumns = ({
  handleDelete,
  ancillaryOpenDetail,
  setAncillaryOpenDetail,
}: ILegalEntityGroupAncillaryTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f501',
    key: 'detail',
    title: '',
    isSortable: false,
    render: (_data, props) => (
      <Box textAlign="center">
        {ancillaryOpenDetail && ancillaryOpenDetail === props.id ? (
          <Button
            variant={EButtonVariants.text}
            tableButton={true}
            icon={EIconTypes.dropdownUp}
            onClick={() => setAncillaryOpenDetail('')}
          />
        ) : (
          <Button
            variant={EButtonVariants.text}
            tableButton={true}
            icon={EIconTypes.dropdownDown}
            onClick={() => setAncillaryOpenDetail(props.id)}
          />
        )}
      </Box>
    ),
  },
  {
    id: 'f0504ca-6b32-4821-b369-304a6f32f510',
    key: 'fireCycleId',
    title: 'ancillaries.list.fireCycleId.column',
  },
  {
    id: 'f0504ca-6b32-4821-b369-304a6f32f511',
    key: 'serialNumber',
    title: 'ancillaries.list.serialNumber.column',
  },
  {
    id: 'f0504ca6-6b32-4822-b369-304a6f32f512',
    key: 'lotNumber',
    title: 'ancillaries.list.lotNumber.column',
  },
  {
    id: 'f0504ca6-6b32-4823-b369-304a6f32f513',
    key: 'batchNumber',
    title: 'ancillaries.list.batchNumber.column',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
    key: 'ancillaryTypeDictionaryValue',
    title: 'ancillaries.list.ancillary.column',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f515',
    key: 'ancillarySpecificType',
    title: 'ancillaries.list.ancillaryType.column',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f516',
    key: 'manufacturer',
    title: 'ancillaries.list.manufacturer.column',
  },
  {
    id: 'f0504ca6-6b32-4825-b369-304a6f32f517',
    key: 'calibre',
    title: 'ancillaries.list.calibre.column',
  },
  {
    id: 'f0504ca6-6b32-4828-b369-304a6f32f518',
    key: '',
    title: 'groups.accordion.action.column',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    width: '80px',
    render: (_data, props) => (
      <Box textAlign="center" display="flex">
        <Link
          to={ERouteLinks.ViewAncillary.replace(':id', props.id).replace(
            ':type',
            props.ancillaryType,
          )}
        >
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.delete}
          onClick={() => handleDelete(props.id)}
        />
      </Box>
    ),
  },
];

export const legalEntityGroupAncillariesDetailsTableColumns = (
  type: string | null | undefined,
): ITableColumns[] => {
  if (type?.toLowerCase() === EArtAncillaryTypeNameLowerCase.COMPONENTS) {
    return [
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f510',
        key: 'artId',
        title: 'ancillaries.componentList.artId.label',
      },
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f511',
        key: 'firearmManufacturer',
        title: 'ancillaries.componentList.firearmManufacturer.label',
      },
      {
        id: 'f0504ca6-6b32-4822-b369-304a6f32f512',
        key: 'firearmMake',
        title: 'ancillaries.componentList.firearmMake.label',
      },
      {
        id: 'f0504ca6-6b32-4823-b369-304a6f32f513',
        key: 'firearmModel',
        title: 'ancillaries.componentList.firearmModel.label',
      },
      {
        id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
        key: 'composition',
        title: 'ancillaries.componentList.composition.label',
      },
    ];
  }
  if (type?.toLowerCase() === EArtAncillaryTypeNameLowerCase.AMMUNITION) {
    return [
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f510',
        key: 'artId',
        title: 'ancillaries.ammunitionList.artId.label',
      },
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f511',
        key: 'factory',
        title: 'ancillaries.ammunitionList.factory.label',
      },
      {
        id: 'f0504ca6-6b32-4822-b369-304a6f32f512',
        key: 'composition',
        title: 'ancillaries.ammunitionList.cartridgeComposition.label',
      },
      {
        id: 'f0504ca6-6b32-4823-b369-304a6f32f513',
        key: 'headstampYearMarking',
        title: 'ancillaries.ammunitionList.headstampYearMarking.label',
      },
      {
        id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
        key: 'alphanumericalMarkings',
        title: 'ancillaries.ammunitionList.alphanumericalMarkings.label',
      },
    ];
  }
  if (type?.toLowerCase() === EArtAncillaryTypeNameLowerCase.ACCESSORY) {
    return [
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f510',
        key: 'artId',
        title: 'ancillaries.accessoryList.artId.label',
      },
      {
        id: 'f0504ca-6b32-4821-b369-304a6f32f511',
        key: 'assetId',
        title: 'ancillaries.accessoryList.assetId.label',
      },
      {
        id: 'f0504ca6-6b32-4822-b369-304a6f32f512',
        key: 'hostType',
        title: 'ancillaries.accessoryList.hostType.label',
      },
      {
        id: 'f0504ca6-6b32-4823-b369-304a6f32f513',
        key: 'composition',
        title: 'ancillaries.accessoryList.composition.label',
      },
      {
        id: 'f0504ca6-6b32-4825-b369-304a6f32f514',
        key: 'proofMark',
        title: 'ancillaries.accessoryList.proofMark.label',
      },
    ];
  }

  return [];
};
