import React, { useEffect } from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EPermission } from 'models/permissions';
import { renderDataColor } from 'components/molecules/Table/helpers';
import { Chips } from 'components/atoms/Chips';

interface IFirearmTransactionTableCard {
  discardableFirearmTransactionId?: string | null;
  data: ITableDataSource;
}

const FirearmTransactionTableCard = ({
  data,
  discardableFirearmTransactionId,
}: IFirearmTransactionTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'registeredFirearms.firearmsDetails.transactions.accordion.transactionType.column',
          )}
        </Typography>
        <Chips text={data.transactionType} variant={renderDataColor(data).stateField} />
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateAndTime.column',
          )}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(formatDate(data.date, longDateFormat))}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.firearmsDetails.transactions.accordion.createdDate.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(formatDate(data.createdDate, longDateFormat))}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.firearmsDetails.transactions.accordion.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.firearmsDetails.transactions.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={1}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('actions.general.label')}: `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('registeredFirearms.firearmsDetails.transactions.accordion.details.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewTransaction.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        {discardableFirearmTransactionId === (data.id as string) && (
          <Grid item xs={12} sm={3}>
            <Button
              label={t(
                'registeredFirearms.firearmsDetails.transactions.accordion.remove.link',
              )}
              variant={EButtonVariants.outlined}
              permission={EPermission.DISCARD_TRANSACTION}
              onClick={() =>
                navigate(ERouteLinks.CancelTransaction.replace(':id', data.id as string))
              }
              size="small"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { FirearmTransactionTableCard };
