import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { IRole } from 'models/role';
import { getRole } from 'requests/role';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { IParsedPermissions, useRoles } from 'pages/RolesPage/hooks';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import { IPermission, permissionsGroups } from 'models/permissions';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';

const DeleteRolePage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [role, setRole] = useState<IRole | null>();
  const [parsedPermissions, setParsedPermissions] = useState<IParsedPermissions>({});
  const { clearAlert, setAlert, samePageAlert } = useAlert();
  const { getPermissions, parsePermissions, permissions } = useRoles();
  const { handleDelete } = useRoles();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getRoleDataInit() {
      if (id) {
        const response = await getRole(id);
        if (response) {
          setRole(response);
        }
      }
    })();
  }, [id]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParsedPermissions(parsePermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const onDelete = async () => {
    if (role) {
      await handleDelete(role?.uniqueId)
        .then(() => navigate(ERouteLinks.Roles))
        .catch(() => {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('deleteRole.validation.roleUsed'),
            variant: EAlertVariants.error,
          });
        });
    }
  };

  const hasAnyPermissionOfModule = (permissionsOfModule: IPermission[]): boolean =>
    permissionsOfModule.filter((permission) =>
      role?.permissionIds?.includes(permission.uniqueId),
    ).length > 0;

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('roles.header'),
            route: ERouteLinks.Roles,
          },
          t('deleteRole.header'),
        ]}
      />
      {role && (
        <>
          <Box sx={{ mb: 1 }}>
            <StyledPageTitle variant="h4">{t('deleteRole.header')}</StyledPageTitle>
          </Box>

          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('deleteRole.info.text')}
          </Typography>

          <DetailsTemplate
            pageType={EDetailsPageTypes.delete}
            title={role.name}
            redirectToEditView={() => navigate(`${ERouteLinks.EditRole.replace(':id', id)}`)}
            redirectToView={() => navigate(`${ERouteLinks.DeleteRole.replace(':id', id)}`)}
          >
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('roleDetails.description.label')}
              </Typography>
              <Typography variant="body2">
                {role?.description ? role.description : '---'}
              </Typography>
            </Grid>
            {!!role?.permissionIds?.length && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: colors.primary500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.02em',
                    }}
                  >
                    {t('roleDetails.selectedPermissions.label')}
                  </Typography>
                </Grid>

                {permissionsGroups.map(
                  (group) =>
                    parsedPermissions?.[group.module] && (
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        sm={6}
                        xs={12}
                        key={group.module}
                        sx={{ mb: 2 }}
                      >
                        <Box mb={1}>
                          <Typography variant="subtitle2">{t(group.title)}</Typography>

                          <div>
                            {hasAnyPermissionOfModule(parsedPermissions?.[group.module])
                              ? parsedPermissions?.[group.module].map(
                                  (permission) =>
                                    role?.permissionIds?.includes(permission.uniqueId) && (
                                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                                        {permission.name}
                                      </Typography>
                                    ),
                                )
                              : '-'}
                          </div>
                        </Box>
                      </Grid>
                    ),
                )}
              </>
            )}
          </DetailsTemplate>

          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.yes.button')}
                  id="yes-button"
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={() => onDelete()}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.no.button')}
                  id="no-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.Roles)}
                />
              </Grid>
            </Grid>
          </Grid>
          {samePageAlert && (
            <Box mt={2} sx={{ marginBottom: '10px' }}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export { DeleteRolePage };
