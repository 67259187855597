import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { initialForgotPasswordFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/ResetPasswordPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { usePassword } from 'models/passwordContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { useNavigate } from 'react-router-dom';
import {
  AuthTemplate,
  getBase64Data,
  StyledAuthHeader,
  StyledImg,
} from 'components/templates/AuthTemplate';
import { IChangePasswordBodyRequest } from 'models/password';
import { useQuery } from 'hooks/useQuery';
import { validateResetPasswordLink } from 'requests/password';
import { FC_SYSTEM_DEVICE_ID } from 'pages/LoginPage';
import { StyledPasswordRulesWrapper } from 'pages/ResetPasswordPage/styles';
import { ELoginPartTypes } from 'models/loginScreenSetup';
import { IImageData, useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN, FC_SYSTEM_AUTH_REFRESH_TOKEN } from 'requests/auth';

const ResetPasswordPage = (): ReactElement => {
  const [isResetPasswordSuccessfully, setIsResetPasswordSuccessfully] = useState<
    boolean | null
  >(null);
  const [isLoaded, setLoaded] = useState(false);
  const [alertText, setAlertText] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialForgotPasswordFormValues,
    ...(isResetPasswordSuccessfully === null ? { resolver: yupResolver(schema) } : {}),
  });
  const { changePassword, isChangePasswordLoading, isForgotPasswordLoading } = usePassword();
  const { getLoginScreen, loginScreenDataParsed } = useLogoSetup();

  const query = useQuery();
  const token: any = query.get('token');
  const queryParams: any = new URLSearchParams({
    token: token.toUpperCase(),
  });

  const anyLogoPresent: boolean =
    loginScreenDataParsed !== null &&
    loginScreenDataParsed !== undefined &&
    (loginScreenDataParsed[ELoginPartTypes.LOGO_1] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_2] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_3]) !== undefined;

  const encodedEmail: any = query.get('e');
  let decodedEmail = '';

  try {
    decodedEmail = encodedEmail ? atob(encodedEmail?.toString()) : '';
  } catch (e: any) {
    navigate(ERouteLinks.AuthWithSlash);
  }

  useEffect(() => {
    (async () => {
      localStorage.removeItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
      localStorage.removeItem(FC_SYSTEM_AUTH_REFRESH_TOKEN);
      await getLoginScreen();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function callValidateResetPasswordLink() {
      try {
        await validateResetPasswordLink(token);
      } catch (e: any) {
        setIsResetPasswordSuccessfully(false);
        if (e.response.status === 410) {
          setAlertText(
            'The reset password link has expired. To reset your password, click on the button below.',
          );
        } else if (e.response.status === 400) {
          setAlertText('The reset password link has already been used.');
        }
      } finally {
        setLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePasswordSubmit = handleSubmit(
    ({ newPassword }: IChangePasswordBodyRequest) => {
      changePassword(
        { newPassword },
        queryParams,
        (res) => {
          localStorage.removeItem(FC_SYSTEM_DEVICE_ID);
          if (res.accessToken) {
            setIsResetPasswordSuccessfully(true);
          } else {
            navigate(ERouteLinks.AuthWithSlash, {
              state: {
                isRedirectToTwoFactorAuthPage: true,
                email: decodedEmail,
                password: newPassword,
              },
            });
          }
        },
        () => {
          setIsResetPasswordSuccessfully(false);
        },
      );
    },
  );

  const handleForgotPasswordSubmit = () => {
    navigate(ERouteLinks.ForgotPassword);
  };

  const renderResetPasswordTemplate = () => {
    if (isLoaded) {
      if (isResetPasswordSuccessfully === null) {
        return (
          <>
            <Grid
              item
              xs={12}
              sx={{
                m: {
                  xs: 2,
                  sm: 3,
                },
                marginTop: '80px',
              }}
            >
              <Grid item>
                <StyledAuthHeader variant="h4" textAlign="center" mb={4}>
                  Set New Password
                </StyledAuthHeader>
              </Grid>
              <Grid item>
                <Typography variant="body1" textAlign="center">
                  Your new password must have:
                </Typography>
                <StyledPasswordRulesWrapper>
                  <li>
                    <Typography variant="caption">between 8 and 64 characters</Typography>
                  </li>
                  <li>
                    <Typography variant="caption">
                      at least one uppercase and special character
                    </Typography>
                  </li>
                </StyledPasswordRulesWrapper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={1}>
                <FormInput
                  name="newPassword"
                  type={EFormInputTypes.password}
                  label="New Password"
                  placeholder="Enter new password"
                  control={control}
                  errors={errors}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: { xs: 32, sm: 40 },
                    },
                  }}
                />
              </Box>
              <FormInput
                name="newPasswordRepeat"
                type={EFormInputTypes.password}
                label="Confirm Password"
                placeholder="Password"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
            </Grid>
          </>
        );
      }
      if (isResetPasswordSuccessfully) {
        return (
          <>
            <Grid item xs={12} mt={12} mb={8}>
              <StyledAuthHeader mb={4}>Password Reset</StyledAuthHeader>
            </Grid>
            <Grid item mb={10}>
              <Alert text="The password has been changed successfully." />
            </Grid>
            <Grid item xs={12} sm={7} xl={12} container>
              <Button
                label="Continue"
                variant={EButtonVariants.contained}
                onClick={() => navigate(ERouteLinks.Dashboard)}
                fullWidth
              />
            </Grid>
          </>
        );
      }
      if (!isResetPasswordSuccessfully && alertText) {
        return (
          <>
            <Grid item m={8}>
              <StyledAuthHeader mb={2}>Set New Password</StyledAuthHeader>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Alert text={alertText} variant={EAlertVariants.warning} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={12} container justifyContent="center">
              <Grid item xs={12} my={3}>
                <Button
                  label="Resend Link"
                  variant={EButtonVariants.outlined}
                  onClick={() => handleForgotPasswordSubmit()}
                  isLoading={isForgotPasswordLoading}
                  fullWidth
                />
              </Grid>
              <RouterLink to={ERouteLinks.AuthWithSlash} label="Back to log in" />
            </Grid>
          </>
        );
      }
    }
    return null;
  };

  if (isLoaded) {
    return (
      <AuthTemplate>
        <Grid
          item
          container
          justifyContent="center"
          alignItems={anyLogoPresent ? 'flex-start' : 'center'}
          sm={10}
          md={6}
          lg={5}
          xl={6}
          sx={{ pt: { xs: 3 }, pb: { xs: 3, sm: 6 } }}
          px={2}
        >
          {anyLogoPresent && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                minHeight: '151px',
                width: '100%',
              }}
              mt={3}
            >
              <Box>
                {loginScreenDataParsed?.[ELoginPartTypes.LOGO_1] && (
                  <StyledImg
                    src={getBase64Data(
                      loginScreenDataParsed[ELoginPartTypes.LOGO_1] as IImageData,
                    )}
                    alt="Logo One"
                  />
                )}
              </Box>
              <Box pr={2} pl={2}>
                {loginScreenDataParsed?.[ELoginPartTypes.LOGO_2] && (
                  <StyledImg
                    src={getBase64Data(
                      loginScreenDataParsed[ELoginPartTypes.LOGO_2] as IImageData,
                    )}
                    alt="Logo Two"
                  />
                )}
              </Box>
              <Box>
                {loginScreenDataParsed?.[ELoginPartTypes.LOGO_3] && (
                  <StyledImg
                    src={getBase64Data(
                      loginScreenDataParsed[ELoginPartTypes.LOGO_3] as IImageData,
                    )}
                    alt="Logo Three"
                  />
                )}
              </Box>
            </Box>
          )}
          <Grid item xs={12} xl={6} container justifyContent="center" alignItems="flex-start">
            <Grid item xs={12} container justifyContent="center">
              {renderResetPasswordTemplate()}
            </Grid>
            {isResetPasswordSuccessfully === null && (
              <Grid item xs={12} sm={7} xl={12} container justifyContent="center">
                <Grid item xs={12} my={3}>
                  <Button
                    label="Set new password"
                    variant={EButtonVariants.contained}
                    onClick={() => handleChangePasswordSubmit()}
                    isLoading={isChangePasswordLoading}
                    fullWidth
                  />
                </Grid>
                <RouterLink to={ERouteLinks.AuthWithSlash} label="Back to log in" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </AuthTemplate>
    );
  }
  return <div />;
};
export { ResetPasswordPage };
