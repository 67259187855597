import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/alert';
import { AlertContext } from 'models/alertContext';
import { EAlertTime } from 'utils/date';

const timeouts: { [key: string]: NodeJS.Timeout } = {};
export const AlertProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const clearAlert = (type: string) => {
    if (timeouts?.[type]) {
      clearTimeout(timeouts[type]);
    }
    dispatch({ type, payload: null });
  };
  const clearAlertTimeout = (type: string, time: EAlertTime = EAlertTime.SHORT) => {
    const timeoutId = setTimeout(() => {
      clearAlert(type);
    }, time);
    timeouts[type] = timeoutId;

    return () => clearTimeout(timeoutId);
  };

  const setAlert = (type: string, payload: any) => {
    dispatch({
      type,
      payload,
    });
    clearAlertTimeout(type);
  };

  const value = useMemo(() => ({ ...state, setAlert, clearAlert }), [state]);

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
