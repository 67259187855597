import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDictionaryFormSchema from 'pages/DictionariesPage/schemaValidation';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import {
  IDictionaryLanguage,
  IDictionaryValueForm,
  IDictionaryValueFormValues,
} from 'models/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { StyledDictionaryValueFormHeader } from 'pages/DictionariesPage/ViewDictionaryPage/DictionaryValueForm/styles';
import { FormSelect } from 'components/molecules/FormSelect';
import { ELanguageUniqueId } from 'models/language';
import { capitalizeFirstLetter } from 'utils/capitalize';

const fieldStatus = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];
const DictionaryValueForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  type,
  dictionaryLanguages,
  managedByAdmin,
  translatedValues,
  isEdit = false,
}: IDictionaryValueForm) => {
  const { schema } = useDictionaryFormSchema();
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IDictionaryValueFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  const { roleAlert, clearAlert } = useAlert();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    reset(initialFormValues);
  }, [reset, initialFormValues]);

  const validateRequiredTranslation = (data: IDictionaryValueFormValues) => {
    const requiredTranslationId = ELanguageUniqueId.EN;
    const requiredField = data.translations[requiredTranslationId];
    if (!requiredField || !requiredField.trim()) {
      setError(`translations.${requiredTranslationId}`, {
        message: t('general.field.validation.required'),
      });
      return false;
    }
    return true;
  };

  const onSubmitForm = async (data: IDictionaryValueFormValues) => {
    const isValid = validateRequiredTranslation(data);
    if (isValid) {
      await setIsSubmitLoading(true);
      const { isActive, translations } = data;
      const translationsData: any = {};
      Object.keys(translations).forEach((key) => {
        translationsData[key.toString()] = translations[key];
      });

      const dataToSend: IDictionaryValueFormValues = {
        isActive,
        translations: { ...translationsData },
      };

      if (initialFormValues) {
        onSubmit({ ...initialFormValues, ...dataToSend });
      } else {
        onSubmit(dataToSend);
      }
      setIsSubmitLoading(false);
    }
  };

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_DICTIONARY_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container>
        <Grid container rowGap={3}>
          <Grid item gap={3} container>
            <Grid item xs={12}>
              <StyledDictionaryValueFormHeader>
                {isEdit
                  ? `${t('dictionary.header')}: `
                  : `${t('addDictionaryValue.header')}: `}
                <b>{capitalizeFirstLetter(type || '')}</b>
              </StyledDictionaryValueFormHeader>
            </Grid>
            <Grid item xs={12} md={5.8} lg={4} xl={3}>
              <Box mb={1}>
                <FormSelect
                  name="isActive"
                  label={`${t('addDictionaryValue.status.label')}`}
                  setValue={setValue}
                  options={fieldStatus}
                  canBeEmpty={false}
                  control={control}
                  errors={errors}
                  disabled={!managedByAdmin}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item gap={3} container>
            <Grid item xs={12}>
              <StyledDictionaryValueFormHeader>
                {t('addDictionaryValue.valueTranslations')}
              </StyledDictionaryValueFormHeader>
            </Grid>
            {initialFormValues.translations &&
              dictionaryLanguages &&
              dictionaryLanguages.map((lang: IDictionaryLanguage) => (
                <Grid item xs={12} md={5.8} lg={4} xl={3} key={lang.code}>
                  <Box mb={1}>
                    <FormInput
                      name={`translations.${lang.uniqueId}`}
                      label={`${lang.code.toUpperCase()}
                            ${t('addDictionaryValue.languageName.label')}${
                        lang.defaultLanguage ? '*' : ''
                      }`}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      disabled={!translatedValues || (lang.defaultLanguage && !managedByAdmin)}
                      charsLimit={40}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} container gap={3} mt={2}>
          <Grid item xs={12} md={5.8} lg={4} xl={3}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="save-button"
              disabled={!managedByAdmin && !translatedValues}
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} md={5.8} lg={4} xl={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>

        <Grid item mt={2} xs={12}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
        <Grid item mt={3} xs={12}>
          {roleAlert && <Alert text={roleAlert.text} variant={roleAlert.variant} />}
        </Grid>
      </Grid>
    </form>
  );
};

export { DictionaryValueForm };
