import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { IconButton, SxProps } from '@mui/material';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { useTranslations } from 'hooks/useTranslations';
import { isStringEmpty } from 'utils/string';
import { StyledTextField } from './styles';
import { getNestedErrorMessage } from './helpers';

export enum EFormInputTypes {
  text = 'text',
  textarea = 'textarea',
  password = 'password',
  number = 'number',
}

interface IFormInput extends IFormElementProps {
  placeholder?: string;
  multiline?: boolean;
  minRows?: number;
  rows?: number;
  type?: EFormInputTypes;
  sx?: SxProps;
  className?: string;
  InputProps?: object;
  disabled?: boolean;
  required?: boolean;
  arrayName?: string;
  arrayIndex?: number;
  setValue?: any;
  fieldName?: string;
  helperText?: string;
  charsLimit?: number;
  defaultValue?: any;
  withValidation?: boolean;
  withLabel?: boolean;
  showCounter?: boolean;
}

export const FormInput = ({
  name,
  type = EFormInputTypes.text,
  label,
  placeholder,
  multiline,
  minRows,
  rows,
  control,
  errors,
  sx,
  setValue,
  className,
  InputProps,
  disabled,
  required,
  arrayName,
  arrayIndex,
  fieldName,
  helperText,
  charsLimit,
  defaultValue,
  withValidation = true,
  withLabel = true,
  showCounter = false,
}: IFormInput) => {
  const { t } = useTranslations();
  const [error, setError] = useState<string>('');
  const [counterText, setCounterText] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    let isError = false;
    if (name.includes('.') && !name.includes('[') && Object.keys(errors).length) {
      const keys = name.split('.');
      const errorMessage = getNestedErrorMessage(errors, keys);
      if (keys && errorMessage) {
        isError = true;
        setError(errorMessage);
      }
    } else {
      const errorMessage =
        arrayName && arrayIndex !== undefined && fieldName
          ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
          : errors[name]?.message;

      isError = true;
      setError(errorMessage);
    }
    if (!isError) {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, name]);

  if (charsLimit && showCounter && !counterText) {
    setCounterText(`0/${charsLimit}`);
  }

  const renderEndAdornment = (field: ControllerRenderProps) => {
    if (type === EFormInputTypes.password) {
      return (
        <IconButton
          sx={{
            maxHeight: '32px',
            maxWidth: '32px',
          }}
          onClick={() => setIsPasswordVisible((data) => !data)}
        >
          <Icon type={isPasswordVisible ? EIconTypes.view : EIconTypes.hidden} />
        </IconButton>
      );
    }
    if (
      field.value !== '' &&
      !disabled &&
      setValue &&
      field.value !== undefined &&
      field.value !== null
    ) {
      return (
        <IconButton
          sx={{
            maxHeight: '32px',
            maxWidth: '32px',
          }}
          onClick={() => {
            setValue(name, '');
            setError('');
          }}
        >
          <Icon type={EIconTypes.clear} />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <StyledFormElementWrapper>
      {withLabel && (
        <FormLabel name={name} label={label} disabled={disabled} required={required} />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            defaultValue={defaultValue}
            className={className}
            disabled={disabled}
            isEmpty={isStringEmpty(field.value)}
            InputProps={{
              inputProps: {
                ...(showCounter && charsLimit ? { maxLength: charsLimit } : {}),
              },
              ...InputProps,
              endAdornment: renderEndAdornment(field),
            }}
            type={isPasswordVisible ? 'text' : type}
            id={name}
            variant="outlined"
            placeholder={placeholder}
            multiline={multiline}
            minRows={minRows}
            rows={rows}
            error={!!error}
            sx={sx}
            helperText={showCounter ? counterText : helperText}
            onChange={(e: BaseSyntheticEvent) => {
              setError('');
              field.onChange(e.target.value || '');
              if (charsLimit && showCounter) {
                setCounterText(`${(e.target.value || '').length}/${charsLimit}`);
              }

              if (charsLimit && !showCounter && charsLimit < e.target.value.length) {
                setError(
                  t('general.field.validation.maxCharacters').replace(
                    '{0}',
                    charsLimit.toString(),
                  ),
                );
              }
            }}
          />
        )}
      />
      {withValidation && <FormErrorLabel label={error} />}
    </StyledFormElementWrapper>
  );
};
