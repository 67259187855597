import React, { useEffect, useState } from 'react';
import { Table } from 'components/molecules/Table';
import { useRoles } from 'pages/RolesPage/hooks';
import { StyledPageTitle } from 'theme/styles';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions } from 'models/table';
import { RoleSearchForm } from 'pages/RolesPage/RoleSearchForm';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import { RoleTableCard } from 'pages/RolesPage/RoleTableCard';
import { useTranslations } from 'hooks/useTranslations';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { mapRolesToDataSource, rolesTableColumns } from './helpers';
import { ISearchFormValues } from './RoleSearchForm';

const RolesPage = () => {
  const navigate = useNavigate();
  const { roleAlert, nextPageAlert, clearAlert } = useAlert();
  const { roles, getRolesData, paginator } = useRoles();
  const { t } = useTranslations();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [query, setQuery] = useState<Partial<ISearchFormValues>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(true);

  const { isDesktop } = useThemeBreakpoint();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getRolesDataInit() {
      setIsPaginationLoading(true);
      await getRolesData(currentPage, perPage, query, sort);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, query, sort]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_ROLE_ALERT);
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPaginationLoading],
  );

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          t('roles.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('roles.header')}</StyledPageTitle>

      {roleAlert && <Alert text={roleAlert.text} variant={roleAlert.variant} />}
      {nextPageAlert && <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />}

      <RoleSearchForm onSubmit={setQuery} />

      {isDesktop ? (
        <Table
          columns={rolesTableColumns({
            handleEdit: (id: string) => navigate(ERouteLinks.EditRole.replace(':id', id)),
            handleDelete: (id: string) => navigate(ERouteLinks.DeleteRole.replace(':id', id)),
            longDateFormat,
          })}
          dataSource={mapRolesToDataSource(roles)}
          onSort={setSort}
        />
      ) : (
        <CardList
          items={mapRolesToDataSource(roles)}
          render={(data, index) => <RoleTableCard data={data} key={index} />}
        />
      )}

      {paginator && (
        <Pagination
          count={paginator.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </>
  );
};

export default RolesPage;
