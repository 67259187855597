import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { Chips } from 'components/atoms/Chips';
import { getFieldsByAmmunitionType } from 'pages/AncillariesPage/helpers';
import { EArtAmmunitionType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { EAmmunitionLabels } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition';
import {
  AmmunitionInformationContainer,
  AmmunitionInformationTitle,
} from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/styles';
import Link from '@mui/material/Link';
import Button from 'components/atoms/Button';
import { mapAncillaryTypeToTranslationKey } from 'utils/mapAncillaryTypeToTranslation';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface IAmmunitionDetails {
  id: string;
}

const AmmunitionDetails = ({ id }: IAmmunitionDetails) => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const { loadDictionaries, ammunitionDetail, getAmmunitionDetail } = useAncillaries();
  const { shortDateFormat } = useGlobalProperty();
  const [accessFields, setAccessFields] = useState<string[]>([]);

  useEffect(() => {
    if (ammunitionDetail) {
      if (ammunitionDetail.typeUniqueId) {
        const fields = getFieldsByAmmunitionType(
          ammunitionDetail.typeUniqueId as EArtAmmunitionType,
        );
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [ammunitionDetail]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getAmmunitionDetail(id);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  return (
    <div>
      {ammunitionDetail && accessFields.length && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={ammunitionDetail?.type}
            pageType={EDetailsPageTypes.view}
            redirectToView={() => navigate(`${ERouteLinks.ViewAmmunition.replace(':id', id)}`)}
            actionButtonProps={{
              ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
              ...{ size: 'small' },
              ...{
                sx: {
                  minWidth: '207px !important',
                  width: { xs: '100%', sm: '207px !important' },
                },
              },
              ...{ sizePX: EButtonSizesPX.px24 },
              ...{ variant: EButtonVariants.outlined },
            }}
          >
            <AmmunitionInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionGeneralInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAmmunitionLabels.ancillaryType)}
                </Typography>
                <Typography variant="body2">
                  {t(mapAncillaryTypeToTranslationKey(ammunitionDetail?.ancillaryType))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAmmunitionLabels.ammunitionType)}
                </Typography>
                <Typography variant="body2">{ammunitionDetail?.type ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EAmmunitionLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {ammunitionDetail?.artId ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Link
                  href={`${ERouteLinks.ViewAncillaries.replace(
                    ':id',
                    ammunitionDetail.artUniqueId,
                  ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase())}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('ammunitionDetails.showArtInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>
              {showField('manufacturerUniqueId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionManufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.productName ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('factory') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.factory)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.factory ?? '-'}</Typography>
                </Grid>
              )}
              {showField('calibreGauge') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.calibreGauge)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.calibre ?? '-'}</Typography>
                </Grid>
              )}
              {showField('functionalTypeUniqueId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.functionalType)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.functionalType ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('cartridgeComposition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.cartridgeComposition)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.cartridgeComposition ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('colourOfPrimerSealant') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.colourOfPrimerSealant)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.colourOfPrimerSealant ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('shotSize') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.shotSize)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.shotSize ?? '-'}</Typography>
                </Grid>
              )}
              {showField('munitionType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.munitionType)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.munitionType ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('munitionVelocity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.munitionVelocity)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.munitionVelocity ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('projectileShape') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.projectileShape)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.projectileShape ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('alphanumericalMarkins') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.alphanumericalMarkins)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.alphanumericalMarkins ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('headstampYearMarking') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.headStampYearMarking)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.headstampYearMarking ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('expirationDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.expirationDate)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.expirationDate ?? '-'}
                  </Typography>
                </Grid>
              )}
            </AmmunitionInformationContainer>
            <AmmunitionInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionDetailedInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              {showField('fireCycleId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.firecycleID)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.fireCycleId ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('lotNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.lotNumber)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.lotNumber ?? '-'}</Typography>
                </Grid>
              )}
              {showField('batchNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.batchNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.batchNumber ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('state') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionState)}
                  </Typography>
                  <Typography variant="body2">
                    <Chips
                      text={emptyFieldHolder(ammunitionDetail?.state)}
                      variant={
                        renderDataColorByValues(
                          ammunitionDetail.activityUniqueId,
                          ammunitionDetail.legalityUniqueId,
                        ).stateField
                      }
                    />
                  </Typography>
                </Grid>
              )}
              {showField('ownerName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentOwner)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.ownerName ?? '-'}</Typography>
                </Grid>
              )}
              {showField('keeperName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentKeeper)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.keeperName ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('productionDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productionDate)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.productionDate
                      ? formatDate(ammunitionDetail?.productionDate, shortDateFormat)
                      : '-'}
                  </Typography>
                </Grid>
              )}
              {showField('ammunitionExpiryDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.expiryDate)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.ammunitionExpiryDate
                      ? formatDate(ammunitionDetail?.ammunitionExpiryDate, shortDateFormat)
                      : '-'}
                  </Typography>
                </Grid>
              )}
              {showField('exhibitNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.exhibitNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.exhibitNumber ?? '-'}
                  </Typography>
                </Grid>
              )}
            </AmmunitionInformationContainer>
          </DetailsTemplate>
        </Grid>
      )}
    </div>
  );
};

export default AmmunitionDetails;
