import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { INotification } from 'models/notification';
import { formatDate } from 'utils/date';
import { colors } from 'theme/colors';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useForm } from 'react-hook-form';
import { mapFilterTypeToLabel } from 'pages/NotificationsPage/helpers';
import { useTranslations } from 'hooks/useTranslations';
import {
  notificationListItemCheckboxStyle,
  StyledNotificationListItem,
} from 'pages/NotificationsPage/NotificationListItem/styles';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';

interface INotificationListItem {
  item: INotification;
  onChange: (arg: string) => void;
  handleDelete: () => void;
  isChecked: boolean;
}

const defaultValues = {
  isChecked: false,
};

const NotificationListItem = ({
  item,
  onChange,
  isChecked,
  handleDelete,
}: INotificationListItem) => {
  const { t } = useTranslations();
  const { isMobile } = useThemeBreakpoint();
  const { longDateFormat } = useGlobalProperty();
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setValue('isChecked', isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <StyledNotificationListItem
      item
      xs={12}
      container
      alignItems="center"
      sx={{
        margin: '6px 0',
        padding: { xs: '20px', sm: '0px 12px' },
        borderRadius: '4px',
        backgroundColor: item.read ? colors.primary15 : colors.primary25,
        border: isChecked ? `1px solid ${colors.primary200}` : '1px solid transparent',
      }}
    >
      <Grid item xs={1} sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FormCheckbox
          name="isChecked"
          control={control}
          errors={errors}
          onChange={() => onChange(item.uniqueId)}
          style={notificationListItemCheckboxStyle}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        sx={{ gap: { xs: 0.5, sm: 0 }, marginBottom: { xs: 2.5, sm: 0 } }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: colors.gray900, display: { xs: 'block', sm: 'none' } }}
        >
          {t('notificationList.header.date')}
        </Typography>
        <Typography
          variant={`${item.read ? 'body2' : 'subtitle2'}`}
          sx={{ color: colors.gray900 }}
        >
          {formatDate(item.createdDate, longDateFormat)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ gap: { xs: 0.5, sm: 0 } }}>
        <Typography
          variant="subtitle2"
          sx={{ color: colors.gray900, display: { xs: 'block', sm: 'none' } }}
        >
          {t('notificationList.header.subject')}
        </Typography>
        <Typography variant={`${item.read ? 'body2' : 'subtitle2'}`}>
          {t('notification.subject')
            .replace('{0}', mapFilterTypeToLabel(item.filterType, t))
            .replace('{1}', item.filterName)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: 'block', sm: 'none' },
          borderBottom: `1px solid ${colors.primary100}`,
        }}
        mt={2}
        mb={2}
      />
      <Grid
        item
        xs={12}
        sm={2}
        container
        justifyContent={isMobile ? 'space-between' : 'flex-end'}
        spacing={1}
      >
        <Grid item xs={6} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Typography
            variant="subtitle2"
            sx={{ color: colors.gray900 }}
            textTransform="uppercase"
          >
            {t('notificationList.header.actions')}:
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Button
            onClick={() => onChange(item.uniqueId)}
            variant={EButtonVariants.contained}
            sx={{ backgroundColor: isChecked ? `${colors.primary900} !important` : '' }}
            label={isChecked ? t('notificationList.unselect.text') : t('general.select.text')}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm="auto">
          <Link to={ERouteLinks.ViewNotification.replace(':id', item.uniqueId)}>
            <Button
              variant={EButtonVariants.outlined}
              {...(!isMobile ? { icon: EIconTypes.view } : {})}
              {...(isMobile ? { label: t('general.details.text') } : {})}
              size="small"
              fullWidth
            />
          </Link>
        </Grid>
        <Grid item xs={6} sm="auto">
          <Button
            variant={EButtonVariants.outlined}
            {...(!isMobile ? { icon: EIconTypes.delete } : {})}
            {...(isMobile ? { label: t('general.delete.text') } : {})}
            onClick={handleDelete}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </StyledNotificationListItem>
  );
};

export { NotificationListItem };
