import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Loader } from 'components/atoms/Loader';
import { useFRT } from 'pages/FRT/hooks';
import { FRTForm } from 'pages/FRT/FRTForm';
import { IFRTFormValues, initialFRTFormValues, initialFRTNotesFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';

const FRTEditPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { frtId = '' } = useParams<{ id: string; frtId: string }>();
  const [isFRTSpecificationsLoading, setIsFRTSpecificationsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IFRTFormValues>({
    ...initialFRTFormValues,
  });
  const { setAlert, clearAlert } = useAlert();
  const {
    handleEditFRT,
    getFRTDetailsData,
    FRTDetails,
    getFRTSpecificationsData,
    FRTSpecifications,
    FRTNotes,
    getFRTNotesData,
    FRTCheckIsUsed,
    getFRTCheckIsUsedData,
  } = useFRT();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function getFRTSpecificationsDataInit() {
      try {
        setIsFRTSpecificationsLoading(true);
        await Promise.all([
          getFRTDetailsData(frtId),
          getFRTSpecificationsData(frtId),
          getFRTNotesData(frtId),
          getFRTCheckIsUsedData(frtId),
        ]);
      } catch (e) {
        console.error(e);
      }
      setIsFRTSpecificationsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frtId]);

  let frtDetailsTitle = '';
  if (FRTDetails !== null) {
    const { make, model, action, type } = FRTDetails;
    frtDetailsTitle = `${make}, ${model}, ${action}, ${type}`;
  }

  useEffect(() => {
    if (FRTDetails && FRTNotes) {
      const updatedValues = {
        ...initialFRTFormValues,
        make: FRTDetails.make || '',
        model: FRTDetails.model || '',
        manufacturer: FRTDetails.manufacturer || '',
        action: FRTDetails.action || '',
        type: FRTDetails.type || '',
        level: FRTDetails.level || '',
        legalClassification: FRTDetails.legalClassification || '',
        productCodes:
          FRTDetails.productCodes.length > 0
            ? FRTDetails.productCodes.map((el) => ({ productCode: el }))
            : [{ productCode: '' }],
        country: FRTDetails.country?.toUpperCase() || '',
      };
      FRTNotes.forEach((note) => {
        updatedValues[note.type as keyof typeof initialFRTNotesFormValues] = note.description;
      });
      setInitialValues(updatedValues);
    }
  }, [FRTDetails, FRTNotes]);

  const calibres = FRTSpecifications.map((spec) => spec.caliber).join(', ');

  const handleSubmit = async (data: IFRTFormValues) => {
    try {
      if (frtId) {
        await handleEditFRT(frtId, data, FRTCheckIsUsed);
      }
      navigate(ERouteLinks.FRTDetails.replace(':frtId', frtId));
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.somethingWentWrong.message'),
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.firearm.name'), route: ERouteLinks.Firearms },
          {
            label: t('firearms.firearmsReferenceTable.accordion.name'),
            route: `${ERouteLinks.Firearms}?expand=frt`,
          },
          t('firearmsReferenceTable.editFirearm.editFirearm.header'),
        ]}
      />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('firearmsReferenceTable.editFirearm.editFirearm.header')}
        </StyledPageTitle>
      </Box>

      {isFRTSpecificationsLoading ? (
        <Grid container>
          <Grid item xs={12} mb={2}>
            <Loader isLoading={isFRTSpecificationsLoading} />
          </Grid>
        </Grid>
      ) : (
        <FRTForm
          frtDetails={FRTDetails}
          frtDetailsTitle={frtDetailsTitle}
          calibres={calibres}
          frtCheckIsUsed={FRTCheckIsUsed}
          onSubmit={handleSubmit}
          onCancel={() => navigate(`${ERouteLinks.Firearms}?expand=frt`)}
          initialFormValues={initialValues}
        />
      )}
    </>
  );
};

export { FRTEditPage };
