import { ActionTypes } from 'state/actions/alert';
import { IAlert } from 'components/atoms/Alert';
import { IReducerAction } from 'models/context';

interface IState {
  roleAlert: IAlert | null;
  dictionaryAlert: IAlert | null;
  legalEntityAlert: IAlert | null;
  legalEntityOrgAlert: IAlert | null;
  authAlert: IAlert | null;
  userAlert: IAlert | null;
  samePageAlert: IAlert | null;
  nextPageAlert: IAlert | null;
  transactionAlert: IAlert | null;
  ancillaryAlert: IAlert | null;
  trustedDeviceAlert: IAlert | null;
  firearmAlert: IAlert | null;
}

export const initialState: IState = {
  roleAlert: null,
  dictionaryAlert: null,
  legalEntityAlert: null,
  legalEntityOrgAlert: null,
  authAlert: null,
  userAlert: null,
  samePageAlert: null,
  nextPageAlert: null,
  transactionAlert: null,
  ancillaryAlert: null,
  trustedDeviceAlert: null,
  firearmAlert: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case ActionTypes.SET_ROLE_ALERT: {
      return { ...state, roleAlert: payload };
    }
    case ActionTypes.CLEAR_ROLE_ALERT: {
      return { ...state, roleAlert: null };
    }
    case ActionTypes.SET_DICTIONARY_ALERT: {
      return { ...state, dictionaryAlert: payload };
    }
    case ActionTypes.CLEAR_DICTIONARY_ALERT: {
      return { ...state, dictionaryAlert: null };
    }
    case ActionTypes.SET_USER_ALERT: {
      return { ...state, userAlert: payload };
    }
    case ActionTypes.CLEAR_USER_ALERT: {
      return { ...state, userAlert: null };
    }
    case ActionTypes.SET_LEGAL_ENTITY_ALERT: {
      return { ...state, legalEntityAlert: payload };
    }
    case ActionTypes.CLEAR_LEGAL_ENTITY_ALERT: {
      return { ...state, legalEntityAlert: null };
    }
    case ActionTypes.SET_LEGAL_ENTITY_ORG_ALERT: {
      return { ...state, legalEntityOrgAlert: payload };
    }
    case ActionTypes.CLEAR_LEGAL_ENTITY_ORG_ALERT: {
      return { ...state, legalEntityOrgAlert: null };
    }
    case ActionTypes.SET_AUTH_ALERT: {
      return { ...state, authAlert: payload };
    }
    case ActionTypes.CLEAR_AUTH_ALERT: {
      return { ...state, authAlert: null };
    }
    case ActionTypes.SET_SAME_PAGE_ALERT: {
      return { ...state, samePageAlert: payload };
    }
    case ActionTypes.CLEAR_SAME_PAGE_ALERT: {
      return { ...state, samePageAlert: null };
    }
    case ActionTypes.SET_NEXT_PAGE_ALERT: {
      return { ...state, nextPageAlert: payload };
    }
    case ActionTypes.CLEAR_NEXT_PAGE_ALERT: {
      return { ...state, nextPageAlert: null };
    }
    case ActionTypes.SET_TRANSACTION_ALERT: {
      return { ...state, transactionAlert: payload };
    }
    case ActionTypes.CLEAR_TRANSACTION_ALERT: {
      return { ...state, transactionAlert: null };
    }
    case ActionTypes.SET_ANCILLARY_ALERT: {
      return { ...state, ancillaryAlert: payload };
    }
    case ActionTypes.CLEAR_ANCILLARY_ALERT: {
      return { ...state, ancillaryAlert: null };
    }
    case ActionTypes.SET_TRUSTED_DEVICE_ALERT: {
      return { ...state, trustedDeviceAlert: payload };
    }
    case ActionTypes.CLEAR_TRUSTED_DEVICE_ALERT: {
      return { ...state, trustedDeviceAlert: null };
    }
    case ActionTypes.SET_FIREARM_ALERT: {
      return { ...state, firearmAlert: payload };
    }
    case ActionTypes.CLEAR_FIREARM_ALERT: {
      return { ...state, firearmAlert: null };
    }
    default:
      return state;
  }
};
