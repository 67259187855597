import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import {
  IAccessory,
  IAmmunition,
  IAncillariesReference,
  IARTAmmunition,
  IARTComponent,
  IComponent,
} from 'models/ancillaries';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import {
  EArtAccessoryType,
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import Button from 'components/atoms/Button';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { getColumnTitleFromCustomField } from 'utils/table';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { formatDate } from 'utils/date';
import { EPermission } from 'models/permissions';
import { Chips } from 'components/atoms/Chips';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { IDictionary } from 'models/dictionary';
import { EFirearmTypeUniqueId } from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm/helpers';

interface IAncillariesTableColumns {
  isTransactionPage?: boolean;
  handleViewTransaction?: (arg: string) => void;
  handleEdit: (arg: string) => void;
  handleSelect?: (arg: any) => void;
  customFields?: ICustomFieldByObjectType[];
  t?: (key: string) => string;
  LEId?: string | undefined;
  type?: string | null | undefined;
}

export const AccessoriesTableColumns = (
  { handleEdit, customFields = [], t }: IAncillariesTableColumns,
  renderStateFieldColor: (arg: ITableDataSource) => any,
  selectedLanguage: string | undefined,
  dateFormat: string,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: 'fireCycleId',
      title: 'accessoriesTable.accordion.firecycleId.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'serialNumber',
      title: 'accessoriesTable.accordion.serialNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'exhibitNumber',
      title: 'accessoriesTable.accordion.exhibit.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'ancillarySpecificType',
      title: 'accessoriesTable.accordion.accessoryType.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'accessoryHostType',
      title: 'accessoriesTable.accordion.hostType.column',
    },
    {
      id: 'f0504ca5-6b12-482d-b369-304a6f32f512',
      key: 'manufacturer',
      title: 'accessoriesTable.accordion.manufacturer.column',
    },
    {
      id: 'f0504ca5-6b12-482d-b369-304a6f32f512',
      key: 'accessoryProductName',
      title: 'accessoriesTable.accordion.productName.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
      key: 'ownerName',
      title: 'accessoriesTable.accordion.owner.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'keeperName',
      title: 'accessoriesTable.accordion.keeper.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'state',
      isCenterTableHead: true,
      title: 'accessoriesTable.accordion.state.column',
      align: 'center',
      render: (_data, props) => (
        <Chips text={props.state} variant={renderStateFieldColor(props)} />
      ),
    },
    ...customFieldColumns,
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: '',
      title: 'accessoriesTable.accordion.action.column',
      isSortable: false,
      width: '80px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <div>
          <Link to={ERouteLinks.ViewAccessories.replace(':id', props.id)}>
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            permission={[EPermission.EDIT_ANCILLARY]}
            icon={EIconTypes.edit}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        </div>
      ),
    },
  ];
};

export const mapAccessoriesToDataSource = (accessories: IAccessory[]): ITableDataSource[] =>
  accessories.map((accessory) => ({
    id: accessory.uniqueId,
    serialNumber: accessory.serialNumber,
    fireCycleId: accessory.fireCycleId,
    manufacturer: accessory.manufacturer,
    accessoryProductName: accessory.productName,
    exhibitNumber: accessory.exhibitNumber,
    ancillarySpecificType: accessory.type,
    accessoryHostType: accessory.hostType,
    ownerName: accessory.ownerName,
    keeperName: accessory.keeperName,
    stateUniqueId: accessory.stateUniqueId,
    state: accessory.state,
    activityUniqueId: accessory.activityUniqueId,
    legalityUniqueId: accessory.legalityUniqueId,
    customFields: accessory.customFields || [],
  }));

export const AmmunitionTableColumns = (
  { handleEdit, customFields = [], t }: IAncillariesTableColumns,
  renderStateFieldColor: (arg: ITableDataSource) => any,
  selectedLanguage: string | undefined,
  dateFormat: string,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'fireCycleId',
      title: 'ammunitionTable.accordion.fireCycleId.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: 'lotNumber',
      title: 'ammunitionTable.accordion.lotNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'batchNumber',
      title: 'ammunitionTable.accordion.batchNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'assetId',
      title: 'ammunitionTable.accordion.assetId.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'exhibitNumber',
      title: 'ammunitionTable.accordion.exhibit.column',
    },
    {
      id: '7827a5d0-9807-11ee-b9d1-0242ac120002',
      key: 'ammunitionType',
      title: 'ammunitionTable.accordion.ammunitionType.column',
    },
    {
      id: '7827a85a-9807-11ee-b9d1-0242ac120002',
      key: 'calibre',
      title: 'ammunitionTable.accordion.calibre.column',
    },
    {
      id: '7827a9fe-9807-11ee-b9d1-0242ac120002',
      key: 'manufacturer',
      title: 'ammunitionTable.accordion.ammunitionManufacturer.column',
    },
    {
      id: 'f0504ca5-6b12-482d-b369-304a6f32f512',
      key: 'ammunitionProductName',
      title: 'ammunitionTable.accordion.productName.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
      key: 'ownerName',
      title: 'ammunitionTable.accordion.owner.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'keeperName',
      title: 'ammunitionTable.accordion.keeper.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'state',
      title: 'ammunitionTable.accordion.state.column',
      isCenterTableHead: true,
      align: 'center',
      render: (_data, props) => (
        <Chips text={props.state} variant={renderStateFieldColor(props)} />
      ),
    },
    ...customFieldColumns,
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: '',
      title: 'accessoriesTable.accordion.action.column',
      isSortable: false,
      width: '80px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <div>
          <Link
            to={ERouteLinks.ViewAncillary.replace(':id', props.id).replace(
              ':type',
              'ammunition',
            )}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            permission={[EPermission.EDIT_ANCILLARY]}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        </div>
      ),
    },
  ];
};

export const mapAmmunitionToDataSource = (ammunition: IAmmunition[]): ITableDataSource[] =>
  ammunition.map((item) => ({
    id: item.uniqueId,
    fireCycleId: item.fireCycleId,
    lotNumber: item.lotNumber,
    batchNumber: item.batchNumber,
    assetId: item.assetId,
    exhibitNumber: item.exhibitNumber,
    ammunitionType: item.type,
    calibre: item.calibre,
    manufacturer: item.manufacturer,
    ammunitionProductName: item.productName,
    ownerName: item.ownerName,
    keeperName: item.keeperName,
    activityUniqueId: item.activityUniqueId,
    legalityUniqueId: item.legalityUniqueId,
    state: item.state,
    customFields: item.customFields || [],
  }));

export const ComponentsTableColumns = (
  { handleEdit, customFields = [], t }: IAncillariesTableColumns,
  renderStateFieldColor: (arg: ITableDataSource) => any,
  selectedLanguage: string | undefined,
  dateFormat: string,
): ITableColumns[] => {
  const customFieldColumns: ITableColumns[] = customFields
    .filter((customField) => customField.visibleInSearchResults)
    .map((customField) => ({
      id: customField.uniqueId,
      key: `customField.${customField.name}`,
      title: getColumnTitleFromCustomField(customField, selectedLanguage),
      isSortable: false,
      render: (data, props) => {
        if (props?.customFields?.length) {
          const cf = props.customFields.find(
            (cField: ILegalEntityCustomField) => cField.customFieldName === customField.name,
          );
          if (cf) {
            if (cf.customFieldType === ECustomFieldType.BOOL && t) {
              if (cf.value === null) {
                return '-';
              }
              return cf.value
                ? t('customFields.value.yes.label')
                : t('customFields.value.no.label');
            }
            if (cf.customFieldType === ECustomFieldType.DATE) {
              return formatDate(cf.value, dateFormat);
            }
            return cf.value || '-';
          }
        }
        return '-';
      },
    }));

  return [
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
      key: 'fireCycleId',
      title: 'componentsTable.accordion.fireCycleId.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'serialNumber',
      title: 'componentsTable.accordion.serialNumber.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
      key: 'exhibitNumber',
      title: 'componentsTable.accordion.exhibit.column',
    },
    {
      id: 'f0504ca5-6b66-482d-b369-304a6f32f521',
      key: 'ancillarySpecificType',
      title: 'componentsTable.accordion.componentType.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
      key: 'manufacturer',
      title: 'componentsTable.accordion.componentManufacturer.column',
    },
    {
      id: 'f0504ca5-6b12-482d-b369-304a6f32f512',
      key: 'componentProductName',
      title: 'componentsTable.accordion.productName.column',
    },
    {
      id: '7827a1d4-9807-11ee-b9d1-0242ac120012',
      key: 'componentFrtType',
      title: 'componentsTable.accordion.firearmType.column',
    },
    {
      id: '7827a1d4-9807-11ee-b9d1-0242ac120022',
      key: 'componentFrtMake',
      title: 'componentsTable.accordion.make.column',
    },
    {
      id: '7827a1d4-9807-11ee-b9d1-0242ac120032',
      key: 'componentFrtModel',
      title: 'componentsTable.accordion.model.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
      key: 'ownerName',
      title: 'componentsTable.accordion.owner.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'keeperName',
      title: 'componentsTable.accordion.keeper.column',
    },
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
      key: 'state',
      title: 'componentsTable.accordion.state.column',
      isCenterTableHead: true,
      align: 'center',
      render: (_data, props) => (
        <Chips text={props.state} variant={renderStateFieldColor(props)} />
      ),
    },
    ...customFieldColumns,
    {
      id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
      key: '',
      title: 'componentsTable.accordion.action.column',
      isSortable: false,
      width: '80px',
      align: 'center',
      isCenterTableHead: true,
      render: (_data, props) => (
        <div>
          <Link
            to={ERouteLinks.ViewAncillary.replace(':id', props.id).replace(
              ':type',
              'component',
            )}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            permission={[EPermission.EDIT_ANCILLARY]}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        </div>
      ),
    },
  ];
};

export const mapComponentsToDataSource = (components: IComponent[]): ITableDataSource[] =>
  components.map((component) => ({
    id: component.uniqueId,
    serialNumber: component.serialNumber,
    manufacturer: component.manufacturer,
    componentProductName: component.productName,
    fireCycleId: component.fireCycleId,
    exhibitNumber: component.exhibitNumber,
    ancillarySpecificType: component.type,
    componentFrtType: component.frtType,
    componentFrtMake: component.frtMake,
    componentFrtModel: component.frtModel,
    ownerName: component.ownerName,
    keeperName: component.keeperName,
    state: component.state,
    activityUniqueId: component.activityUniqueId,
    legalityUniqueId: component.legalityUniqueId,
    stateUniqueId: component.stateUniqueId,
    customFields: component.customFields || [],
  }));

export const AncillariesReferenceTableColumns = ({
  isTransactionPage = false,
  handleEdit,
  handleSelect,
  LEId,
  type,
}: IAncillariesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'ancillary',
    title: 'ancillariesReferenceTable.accordion.ancillary.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'artId',
    title: 'ancillariesReferenceTable.accordion.artId.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'accessoryType',
    title: 'ancillariesReferenceTable.accordion.accessoryType.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'hostType',
    title: 'ancillariesReferenceTable.accordion.hostType.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'manufacturer',
    title: 'ancillariesReferenceTable.accordion.accessoryManufacturer.column',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f5132',
    key: 'productName',
    title: 'ancillariesReferenceTable.accordion.productName.label',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f515',
    key: 'calibre',
    title: 'ancillariesReferenceTable.accordion.calibre.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f516',
    key: 'composition',
    title: 'ancillariesReferenceTable.accordion.composition.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'ancillariesReferenceTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        {!isTransactionPage ? (
          <Link
            to={ERouteLinks.ViewAncillaries.replace(':id', props.id).replace(
              ':type',
              EArtAncillaryTypeName.ACCESSORY.toLowerCase(),
            )}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        ) : (
          <Link
            to={`${ERouteLinks.LegalEntityViewAncillary.replace(':leId', LEId ?? '')
              .replace(':id', props.id)
              .replace(
                ':type',
                EArtAncillaryTypeName.ACCESSORY.toLowerCase(),
              )}?type=${type}&expand=ancillaries`}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        )}
        {!isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            permission={[EPermission.MANAGE_LOCAL_ART]}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        )}
        {isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.smallCheckmark}
            onClick={() => handleSelect && handleSelect(props)}
          />
        )}
      </div>
    ),
  },
];

export const mapAncillariesReferenceToDataSource = (
  ancillariesReference: IAncillariesReference[],
  ancillary: string,
): ITableDataSource[] =>
  ancillariesReference.map((item) => ({
    id: item.uniqueId,
    ancillary,
    artId: item.artId,
    type: item.accessoryType,
    accessoryType: item.accessoryType,
    hostType: item.hostType,
    manufacturer: item.manufacturer,
    productName: item.productName,
    calibre: item.calibre,
    composition: item.composition,
    capacity: item.capacity,
    lightColour: item.lightColour,
    magnification: item.magnification,
    proofHouse: item.proofHouse,
    rectile: item.rectile,
    utility: item.utility,
    accessoryTypeUniqueId: item.accessoryTypeUniqueId,
  }));

export const AncillariesReferenceTableAmmunitionColumns = ({
  isTransactionPage = false,
  handleEdit,
  handleSelect,
  LEId,
  type,
}: IAncillariesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f510',
    key: 'ancillary',
    title: 'ancillariesReferenceTable.accordion.ancillary.column',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f511',
    key: 'artId',
    title: 'ancillariesReferenceTable.accordion.artId.column',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f512',
    key: 'ammunitionType',
    title: 'ammunitionTable.accordion.ammunitionType.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'ammunitionTable.accordion.ammunitionManufacturer.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f5132',
    key: 'productName',
    title: 'ammunitionTable.accordion.productName.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f514',
    key: 'factory',
    title: 'ammunitionTable.accordion.factory.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f515',
    key: 'calibre',
    title: 'ammunitionTable.accordion.calibre.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f516',
    key: 'functionalType',
    title: 'ammunitionTable.accordion.functionalType.label',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f517',
    key: '',
    title: 'ancillariesReferenceTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        {!isTransactionPage ? (
          <Link
            to={ERouteLinks.ViewAncillaries.replace(':id', props.id).replace(
              ':type',
              EArtAncillaryTypeName.AMMUNITION.toLowerCase(),
            )}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        ) : (
          <Link
            to={`${ERouteLinks.LegalEntityViewAncillary.replace(':leId', LEId ?? '')
              .replace(':id', props.id)
              .replace(
                ':type',
                EArtAncillaryTypeName.AMMUNITION.toLowerCase(),
              )}?type=${type}&expand=ancillaries`}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        )}
        {!isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            permission={[EPermission.MANAGE_LOCAL_ART]}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        )}
        {isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.smallCheckmark}
            onClick={() => handleSelect && handleSelect(props)}
          />
        )}
      </div>
    ),
  },
];

export const mapARTAmmunitionToDataSource = (
  ancillariesReference: IARTAmmunition[],
  ancillary: string,
): ITableDataSource[] =>
  ancillariesReference.map((item) => ({
    id: item.uniqueId,
    ancillary,
    artId: item.artId,
    ammunitionType: item.ammunitionType,
    type: item.ammunitionType,
    manufacturer: item.manufacturer,
    productName: item.productName,
    factory: item.factory,
    calibre: item.calibre,
    functionalType: item.functionalType,
    ammunitionTypeUniqueId: item.ammunitionTypeUniqueId,
  }));

export const AncillariesReferenceTableComponentsColumns = ({
  isTransactionPage = false,
  handleEdit,
  handleSelect,
  LEId,
  type,
}: IAncillariesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f510',
    key: 'ancillary',
    title: 'ancillariesReferenceTable.accordion.ancillary.column',
    isSortable: false,
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f511',
    key: 'artId',
    title: 'ancillariesReferenceTable.accordion.artId.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f512',
    key: 'componentType',
    title: 'ancillariesReferenceTable.accordion.component.componentType.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f513',
    key: 'manufacturer',
    title: 'ancillariesReferenceTable.accordion.component.manufacturer.column',
  },
  {
    id: 'f0504ca5-6b34-482d-b369-304a6f32f5132',
    key: 'productName',
    title: 'ancillariesReferenceTable.accordion.productName.label',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f514',
    key: 'frtType',
    title: 'ancillariesReferenceTable.accordion.component.firearmType.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f515',
    key: 'frtManufacturer',
    title: 'ancillariesReferenceTable.accordion.component.firearmManufacturer.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f516',
    key: 'frtMake',
    title: 'ancillariesReferenceTable.accordion.component.make.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f517',
    key: 'frtModel',
    title: 'ancillariesReferenceTable.accordion.component.model.column',
  },
  {
    id: 'f0504ca0-6b34-482d-b369-304a6f32f518',
    key: '',
    title: 'ancillariesReferenceTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        {!isTransactionPage ? (
          <Link
            to={ERouteLinks.ViewAncillaries.replace(':id', props.id).replace(
              ':type',
              EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
            )}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        ) : (
          <Link
            to={`${ERouteLinks.LegalEntityViewAncillary.replace(':leId', LEId ?? '')
              .replace(':id', props.id)
              .replace(
                ':type',
                EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
              )}?type=${type}&expand=ancillaries`}
          >
            <Button
              variant={EButtonVariants.outlined}
              tableButton={true}
              icon={EIconTypes.view}
            />
          </Link>
        )}
        {!isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.edit}
            permission={[EPermission.MANAGE_LOCAL_ART]}
            onClick={() => handleEdit && handleEdit(props.id)}
          />
        )}
        {isTransactionPage && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.smallCheckmark}
            onClick={() => handleSelect && handleSelect(props)}
          />
        )}
      </div>
    ),
  },
];

export const mapARTComponentsToDataSource = (
  ancillariesReference: IARTComponent[],
  ancillary: string,
): ITableDataSource[] =>
  ancillariesReference.map((item) => ({
    id: item.uniqueId,
    ancillary,
    artId: item.artId,
    componentType: item.componentType,
    type: item.componentType,
    manufacturer: item.manufacturer,
    productName: item.productName,
    frtType: item.frtType,
    frtManufacturer: item.frtManufacturer,
    frtMake: item.frtMake,
    frtModel: item.frtModel,
    composition: item.composition,
    calibre: item.calibre,
    proofHouse: item.proofHouse,
    componentTypeUniqueId: item.componentTypeUniqueId,
  }));

export const filterHostTypeByAccessoryType = (
  accessoryType: EArtAccessoryType,
  artAccessoryHostType: IDictionary[] | null,
): IDictionary[] | null => {
  let validHostTypes: EFirearmTypeUniqueId[] = [];

  // eslint-disable-next-line default-case
  switch (accessoryType) {
    case EArtAccessoryType.LASER_LIGHT_MODULES: {
      validHostTypes = [
        EFirearmTypeUniqueId.AIRSOFT,
        EFirearmTypeUniqueId.COMBINATION_GUN,
        EFirearmTypeUniqueId.GRENADE_LAUNCHER,
        EFirearmTypeUniqueId.MACHINE_GUN,
        EFirearmTypeUniqueId.OTHER,
        EFirearmTypeUniqueId.RIFLE,
        EFirearmTypeUniqueId.SHOTGUN,
        EFirearmTypeUniqueId.SUBMACHINE_GUN,
        EFirearmTypeUniqueId.HANDGUN,
      ];
      break;
    }
    case EArtAccessoryType.OPTICAL_SIGHTS: {
      validHostTypes = [
        EFirearmTypeUniqueId.AIRSOFT,
        EFirearmTypeUniqueId.COMBINATION_GUN,
        EFirearmTypeUniqueId.GRENADE_LAUNCHER,
        EFirearmTypeUniqueId.MACHINE_GUN,
        EFirearmTypeUniqueId.OTHER,
        EFirearmTypeUniqueId.RIFLE,
        EFirearmTypeUniqueId.SHOTGUN,
        EFirearmTypeUniqueId.SUBMACHINE_GUN,
        EFirearmTypeUniqueId.HANDGUN,
        EFirearmTypeUniqueId.RECOILLESS_RIFLE,
        EFirearmTypeUniqueId.ROCKET_LAUNCHER,
        EFirearmTypeUniqueId.MANPAD,
        EFirearmTypeUniqueId.ANTI_TANK_MISSILE,
      ];
      break;
    }
    case EArtAccessoryType.SUPPRESSORS: {
      validHostTypes = [
        EFirearmTypeUniqueId.AIRSOFT,
        EFirearmTypeUniqueId.COMBINATION_GUN,
        EFirearmTypeUniqueId.MACHINE_GUN,
        EFirearmTypeUniqueId.OTHER,
        EFirearmTypeUniqueId.RIFLE,
        EFirearmTypeUniqueId.SHOTGUN,
        EFirearmTypeUniqueId.SUBMACHINE_GUN,
        EFirearmTypeUniqueId.HANDGUN,
      ];
      break;
    }
    case EArtAccessoryType.MAGAZINES: {
      validHostTypes = [
        EFirearmTypeUniqueId.AIRSOFT,
        EFirearmTypeUniqueId.COMBINATION_GUN,
        EFirearmTypeUniqueId.GRENADE_LAUNCHER,
        EFirearmTypeUniqueId.MACHINE_GUN,
        EFirearmTypeUniqueId.OTHER,
        EFirearmTypeUniqueId.RIFLE,
        EFirearmTypeUniqueId.SHOTGUN,
        EFirearmTypeUniqueId.SUBMACHINE_GUN,
        EFirearmTypeUniqueId.HANDGUN,
      ];
      break;
    }
    case EArtAccessoryType.FLASH_ELIMINATORS: {
      validHostTypes = [
        EFirearmTypeUniqueId.AIRSOFT,
        EFirearmTypeUniqueId.COMBINATION_GUN,
        EFirearmTypeUniqueId.MACHINE_GUN,
        EFirearmTypeUniqueId.OTHER,
        EFirearmTypeUniqueId.RIFLE,
        EFirearmTypeUniqueId.SHOTGUN,
        EFirearmTypeUniqueId.SUBMACHINE_GUN,
        EFirearmTypeUniqueId.HANDGUN,
      ];
      break;
    }
  }

  const result = artAccessoryHostType?.filter((dictionary) =>
    validHostTypes.includes(dictionary.uniqueId as EFirearmTypeUniqueId),
  );

  return result === undefined ? null : result;
};

export const getFieldsByAccessoriesType = (accessoriesTypeId: EArtAccessoryType | string) => {
  switch (accessoriesTypeId) {
    case EArtAccessoryType.MAGAZINES:
      return [
        'manufacturerUniqueId',
        'productName',
        'calibreUniqueId',
        'compositionUniqueId',
        'accessoryCapacity',
        'composition',
        'calibre',
        'manufacturer',
        'exhibitNumber',
        'stateUniqueId',
        'legalityUniqueId',
        'capacity',
        'notes',
        'hostTypeUniqueId',
        'hostType',
      ];
    case EArtAccessoryType.LASER_LIGHT_MODULES:
      return [
        'manufacturerUniqueId',
        'productName',
        'lightColourUniqueId',
        'manufacturer',
        'lightColour',
        'stateUniqueId',
        'legalityUniqueId',
        'notes',
        'hostTypeUniqueId',
        'hostType',
      ];
    case EArtAccessoryType.OPTICAL_SIGHTS:
      return [
        'manufacturerUniqueId',
        'productName',
        'magnificationUniqueId',
        'utilityUniqueId',
        'reticleUniqueId',
        'manufacturer',
        'magnification',
        'utility',
        'rectileUniqueId',
        'stateUniqueId',
        'legalityUniqueId',
        'notes',
        'hostTypeUniqueId',
        'hostType',
      ];
    case EArtAccessoryType.FLASH_ELIMINATORS:
      return [
        'manufacturerUniqueId',
        'productName',
        'hostTypeUniqueId',
        'calibreUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'manufacturer',
        'hostType',
        'calibre',
        'composition',
        'proofHouse',
        'serialNumber',
        'stateUniqueId',
        'legalityUniqueId',
        'notes',
      ];
    case EArtAccessoryType.SUPPRESSORS:
      return [
        'manufacturerUniqueId',
        'productName',
        'hostTypeUniqueId',
        'calibreUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'manufacturer',
        'hostType',
        'calibre',
        'composition',
        'proofHouse',
        'serialNumber',
        'stateUniqueId',
        'legalityUniqueId',
        'notes',
      ];
    default:
      return [];
  }
};

export const getFieldsByAmmunitionType = (type: EArtAmmunitionType | string) => {
  const fieldsForAllTypes = [
    'productName',
    'fireCycleId',
    'stateUniqueId',
    'legalityUniqueId',
    'lotNumber',
    'batchNumber',
    'exhibitNumber',
    'manufacturerUniqueId',
    'manufacturer',
    'manufacturedAt',
    'factory',
    'cartridgeCompositionUniqueId',
    'cartridgeComposition',
    'manufactureDateFrom',
    'manufactureDateTo',
    'productionDate',
    'state',
    'ownerName',
    'keeperName',
    'ammunitionExpiryDate',
    'notes',
    'assetId',
  ];

  switch (type) {
    case EArtAmmunitionType.HANDGUN_RIFLE:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'colourOfPrimerSealantUniqueId',
        'projectileShapeUniqueId',
        'functionalTypeUniqueId',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'calibre',
        'colourOfPrimerSealant',
        'projectileShape',
        'alphanumericalMarkins',
        'cartridgeCompositionUniqueId',
        'expirationDate',
        'calibreGauge',
        'functionalType',
        'calibreUniqueId',
      ];
    case EArtAmmunitionType.SHOTGUN:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'cartridgeCompositionUniqueId',
        'colourOfPrimerSealantUniqueId',
        'shotSizeUniqueId',
        'shotSize',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'calibre',
        'colourOfPrimerSealant',
        'alphanumericalMarkins',
        'calibreUniqueId',
        'calibreGauge',
        'shotSize',
        'expirationDate',
      ];
    case EArtAmmunitionType.AIR_RIFLE:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'cartridgeCompositionUniqueId',
        'calibre',
        'calibreGauge',
        'calibreUniqueId',
      ];
    case EArtAmmunitionType.GRENADE_LAUNCHER:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'cartridgeCompositionUniqueId',
        'munitionTypeUniqueId',
        'munitionVelocityUniqueId',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'calibre',
        'alphanumericalMarkins',
        'munitionVelocity',
        'munitionType',
        'expirationDate',
        'calibreGauge',
        'calibreUniqueId',
        'headstampYearMarking',
      ];
    case EArtAmmunitionType.MORTAR_SHELL:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'cartridgeCompositionUniqueId',
        'munitionTypeUniqueId',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'calibre',
        'alphanumericalMarkins',
        'munitionType',
        'expirationDate',
        'calibreGauge',
        'calibreUniqueId',
      ];
    case EArtAmmunitionType.RECOILLESS_ARTILLERY_SHELL:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'cartridgeCompositionUniqueId',
        'munitionTypeUniqueId',
        'munitionVelocityUniqueId',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'calibre',
        'alphanumericalMarkins',
        'munitionVelocity',
        'munitionType',
        'expirationDate',
        'calibreGauge',
        'calibreUniqueId',
      ];
    case EArtAmmunitionType.ROCKET_PROPELLED:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'calibreUniqueId',
        'cartridgeCompositionUniqueId',
        'munitionTypeUniqueId',
        'munitionVelocityUniqueId',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'alphanumericalMarkins',
        'munitionVelocity',
        'munitionType',
        'expirationDate',
      ];
    case EArtAmmunitionType.MISSILES:
      return [
        ...fieldsForAllTypes,
        'ammunitionTypeUniqueId',
        'manufacturerUniqueId',
        'factoryUniqueId',
        'cartridgeCompositionUniqueId',
        'headstampYearMarking',
        'alphanumericalMarkings',
        'expiryDateFrom',
        'expiryDateTo',
        'alphanumericalMarkins',
        'expirationDate',
        'alphanumericalMarkins',
        'munitionVelocity',
        'munitionType',
        'munitionTypeUniqueId',
      ];
    default:
      return [];
  }
};

export const getFieldsByComponentType = (type: EArtComponentType) => {
  const fieldsForAllTypes = [
    'productName',
    'stateUniqueId',
    'legalityUniqueId',
    'frtType',
    'frtMake',
    'frtModel',
    'frtManufacturer',
    'fireCycleId',
    'serialNumber',
    'exhibitNumber',
    'notes',
  ];
  switch (type) {
    case EArtComponentType.UPPER_RECEIVER:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'calibreUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'calibre',
        'composition',
        'proofHouse',
      ];
    case EArtComponentType.LOWER_RECEIVER:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'composition',
        'proofHouse',
      ];
    case EArtComponentType.FRAME:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'composition',
        'proofHouse',
      ];
    case EArtComponentType.BARREL:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'calibreUniqueId',
        'length',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'calibre',
        'composition',
        'proofHouse',
        'length',
      ];
    case EArtComponentType.BOLT:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'calibreUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'calibre',
        'proofHouse',
      ];
    case EArtComponentType.SLIDE:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'calibreUniqueId',
        'compositionUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'calibre',
        'composition',
        'proofHouse',
      ];
    case EArtComponentType.CYLINDER:
      return [
        ...fieldsForAllTypes,
        'componentTypeUniqueId',
        'manufacturerUniqueId',
        'calibreUniqueId',
        'compositionUniqueId',
        'componentCapacityUniqueId',
        'proofHouseUniqueId',
        'componentType',
        'manufacturer',
        'calibre',
        'composition',
        'proofHouse',
        'capacityUniqueId',
      ];

    default:
      return [];
  }
};

export const isEmptyObject = (object?: { [key: string]: any }) => {
  if (object) {
    const objectRef = object;
    const reducedObject = Object.keys(objectRef)
      .filter((k) => objectRef[k] != null && objectRef[k].length)
      .reduce((a, k) => ({ ...a, [k]: objectRef[k] }), {});
    return !Object.keys(reducedObject).length;
  }
  return true;
};
