import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import useUserFormSchema from 'pages/UsersPage/schemaValidation';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useDictionary } from 'models/dictionaryContext';
import { useUsers } from 'pages/UsersPage/hooks';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import FormLabel from 'components/atoms/FormLabel';
import { IRole } from 'models/role';
import { useTranslations } from 'hooks/useTranslations';
import { ITranslationKey } from 'components/molecules/Table';
import { useLang } from 'models/langContext';
import { IDictionary } from 'models/dictionary';
import { useScroll } from 'hooks/useScroll';
import { StyledForm } from 'theme/styles';

export interface IUserForm {
  initialFormValues?: IUserFormValues | null;
  onCancel?: () => void;
  onSubmit: (arg: IUserFormValues) => void;
  roles: IRole[];
  legalEntityPrefix: IDictionary[] | null;
  translationsKeys?: ITranslationKey | any;
  isEdit?: boolean;
  isDisabled?: boolean;
}

const UserForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  roles,
  legalEntityPrefix,
  translationsKeys,
  isEdit = false,
  isDisabled = false,
}: IUserForm) => {
  const { loadDictionaries } = useUsers();
  const { phoneCode } = useDictionary();
  const { schema } = useUserFormSchema();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IUserFormValues>({
    defaultValues: initialFormValues || initialUserFormValues,
    resolver: yupResolver(schema),
  });

  const { samePageAlert, clearAlert } = useAlert();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { elRef, scrollToError } = useScroll();

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    reset(initialFormValues || initialUserFormValues);
  }, [reset, initialFormValues]);

  const onSubmitForm = async (data: IUserFormValues) => {
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({ ...initialFormValues, ...data });
    } else {
      await onSubmit(data);
    }
    setIsSubmitLoading(false);
  };

  useEffect(
    () => {
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function init() {
      await loadDictionaries();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <StyledForm container spacing={2} ref={elRef}>
        {isDisabled && (
          <Grid item xs={12} style={{ margin: '10px 0 20px' }}>
            <Alert text={t('users.addUser.limit.warning')} variant={EAlertVariants.warning} />
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={3}>
          <Box mb={1}>
            <FormInput
              name="email"
              label={`${t(translationsKeys.emailLabel)}*`}
              control={control}
              disabled={isEdit || isDisabled}
              setValue={setValue}
              errors={errors}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box mb={1}>
            <FormInput
              name="firstName"
              label={`${t(translationsKeys.firstNameLabel)}*`}
              control={control}
              setValue={setValue}
              errors={errors}
              charsLimit={20}
              disabled={isDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box mb={1}>
            <FormInput
              name="lastName"
              label={`${t(translationsKeys.lastNameLabel)}*`}
              control={control}
              errors={errors}
              setValue={setValue}
              charsLimit={20}
              disabled={isDisabled}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={6} container columnSpacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            {phoneCode && (
              <FormSelect
                name="phoneNumberCodeDictionaryUniqueId"
                options={mapDictionaryToOptionProp(phoneCode)}
                label={`${t(translationsKeys.phonePrefixLabel)}*`}
                setValue={setValue}
                control={control}
                errors={errors}
                disabled={isDisabled}
              />
            )}
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <FormInput
              name="phoneNumberValue"
              label={`${t(translationsKeys.phoneNumberLabel)}*`}
              control={control}
              setValue={setValue}
              errors={errors}
              charsLimit={20}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box mb={1}>
              <FormLabel name="formRoleIds" label={`${t(translationsKeys.roleLabel)}*`} />
              {roles.map((role) => (
                <FormCheckbox
                  key={role.uniqueId}
                  name={`formRoleIds[${role.uniqueId}]`}
                  label={role.name}
                  control={control}
                  errors={errors}
                  disabled={isDisabled}
                />
              ))}
              <FormErrorLabel label={errors?.formRoleIds?.message?.toString() as string} />
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box mb={1}>
              <FormLabel
                name="formPrefixGroupIds"
                label={`${t(translationsKeys.legalEntityPrefix)}*`}
              />
              {legalEntityPrefix &&
                legalEntityPrefix.map((prefix) => (
                  <FormCheckbox
                    key={prefix.uniqueId}
                    name={`formPrefixGroupIds[${prefix.uniqueId}]`}
                    label={prefix.name}
                    control={control}
                    errors={errors}
                    disabled={isDisabled}
                  />
                ))}
              <FormErrorLabel
                label={errors?.formPrefixGroupIds?.message?.toString() as string}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
                disabled={isDisabled}
              />
            </Grid>

            {onCancel && (
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item mt={2} xs={12}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
        <Grid item mt={3} xs={12}>
          {samePageAlert && (
            <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
          )}
        </Grid>
      </StyledForm>
    </form>
  );
};

export { UserForm };
