import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledTextField = styled(TextField)((props: any) => ({
  fieldset: {
    borderRadius: 4,
  },
  input: {
    fontSize: 14,
    backgroundColor: `${colors.white}`,
    boxSizing: 'border-box',
    ...(props.disabled ? { backgroundColor: colors.gray100 } : {}),
  },
  textarea: {
    fontSize: 14,
    padding: '12px',
  },
  'input::placeholder, textarea::placeholder': {
    fontSize: 16,
    fontWeight: 400,
    color: `${colors.gray300}`,
    fontFamily: 'NotoSansRegular, sans-serif',
    opacity: 1,
  },
  '& .MuiInputBase-adornedEnd': {
    padding: '0',
    ...(props.multiline ? { display: 'flex', alignItems: 'stretch' } : {}),
  },
  '& .MuiOutlinedInput-root': {
    marginBottom: 3,
    backgroundColor: `${colors.white}`,
    '& fieldset': {
      borderColor: colors.gray300,
    },
    '& ~ p': {
      marginLeft: '0px',
    },
  },
  '& .MuiOutlinedInput-root:has(textarea)': {
    padding: 0,
  },
}));
