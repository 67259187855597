import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Grid, Typography } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { getFieldsByComponentType } from 'pages/AncillariesPage/helpers';
import {
  EAncillaryState,
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { IAncillaryDetailsCustomfield } from 'models/ancillaries';
import { ECustomFieldType } from 'models/customField';
import { clearAlertTimeout, EAlertTime, formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ILLEGAL_ID, INACTIVE_ID } from 'pages/Firearms';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { getLegalEntityType } from 'components/organisms/RegisteredFirearmDetails';
import { StyledRouterLink } from 'components/atoms/RouterLink/styles';
import { ActionTypes } from 'state/actions/alert';
import { EFirearmStateId } from 'models/firearm';
import { ComponentInformationContainer, ComponentInformationTitle } from './styles';
import { ComponentsTransactions } from './ComponentsTransactions';
import { ComponentsComments } from './ComponentsComments';
import { ComponentsAttachments } from './ComponentsAttachments';

export enum EComponentLabels {
  componentGeneralInformation = 'componentDetails.componentGeneralInformation.header',
  componentDetailedInformation = 'componentDetails.componentDetailedInformation.header',
  firearmInformation = 'componentDetails.firearmInformation.header',
  ancillaryType = 'ancillaryDetails.ancillaryType.label',
  componentType = 'componentDetails.componentType.label',
  artId = 'componentDetails.artId.label',
  hostType = 'componentDetails.hostType.label',
  manufacturer = 'componentDetails.componentManufacturer.label',
  productName = 'componentDetails.productName.label',
  calibre = 'componentDetails.calibre.label',
  composition = 'componentDetails.composition.label',
  proofHouse = 'componentDetails.proofhouse.label',
  capacity = 'componentDetails.capacity.label',
  length = 'componentDetails.length.label',
  firecycleID = 'componentDetails.firecycleID.label',
  serialNumber = 'componentDetails.serialNumber.label',
  componentState = 'componentDetails.componentState.label',
  exhibitNumber = 'componentDetails.exhibitNumber.label',
  currentOwner = 'componentDetails.currentOwner.label',
  currentKeeper = 'componentDetails.currentKeeper.label',
  externalReferenceNumber = 'componentDetails.externalReferenceNumber.label',
  manufacturedDate = 'componentDetails.manufacturedDate.label',

  firearmManufacturer = 'componentDetails.firearmManufacturer.label',
  firearmReferenceNo = 'componentDetails.firearmReferenceNo.label',
  firearmFirecycleType = 'componentDetails.firecycleType.label',
  firearmMake = 'componentDetails.make.label',
  firearmModel = 'componentDetails.model.label',
}

const DetailComponents = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert, transactionAlert, ancillaryAlert } = useAlert();
  const { loadDictionaries, componentDetail, getComponentDetail } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const { shortDateFormat } = useGlobalProperty();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    if (componentDetail) {
      if (componentDetail.typeUniqueId) {
        const fields = getFieldsByComponentType(
          componentDetail.typeUniqueId as EArtComponentType,
        );
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [componentDetail]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getComponentDetail(id);
      }
    })();
  }, [id]);

  useEffect(
    () => {
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_NEXT_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_TRANSACTION_ALERT, EAlertTime.SHORT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_ANCILLARY_ALERT);
    },
    [],
  );

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  let renderDataColor = EChipsVariants.primary;
  if (componentDetail?.activityUniqueId === INACTIVE_ID) {
    renderDataColor = EChipsVariants.inactive;
  } else if (componentDetail?.legalityUniqueId === ILLEGAL_ID) {
    renderDataColor = EChipsVariants.error;
  }

  const renderCustomFieldData = (customField: IAncillaryDetailsCustomfield) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value || '-';
  };

  const navigateToMap = () => {
    navigate(ERouteLinks.RegisteredAncillariesMap, {
      state: {
        ancillaryUniqueId: componentDetail?.uniqueId,
        ancillaryType: EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
        legalityUniqueId: componentDetail?.legalityUniqueId,
        navigateBack: `${ERouteLinks.ViewComponents.replace(':id', id as string)}`,
        showAdvanced: true,
        showLegalityBar: false,
        breadcrumbs: [
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.components.name'),
            route: `${ERouteLinks.Ancillaries}?expand=components`,
          },
          {
            label: t('componentDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewComponents.replace(':id', id as string)}`,
          },
          t('ancillaries.ancillariesLocation.showOnMap.label'),
        ],
      },
    });
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.components.name'),
            route: `${ERouteLinks.Ancillaries}?expand=components`,
          },
          t('componentDetails.accordion.title.label'),
        ]}
      />
      {componentDetail && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={componentDetail?.type}
            pageType={EDetailsPageTypes.view}
            redirectToShowOnMap={componentDetail?.latitude ? navigateToMap : undefined}
            showOnMapButtonId="component-show-on-map"
            redirectToEditView={
              hasPermissions([EPermission.EDIT_ANCILLARY])
                ? () => {
                    navigate(
                      ERouteLinks.EditComponents.replace(':id', componentDetail.uniqueId),
                    );
                  }
                : undefined
            }
            redirectToDisableView={
              componentDetail.stateUniqueId === EAncillaryState.DISABLED ||
              !hasPermissions([EPermission.DISABLE_ANCILLARY])
                ? undefined
                : () =>
                    navigate(
                      `${ERouteLinks.DisableAncillary.replace(
                        ':id',
                        componentDetail.uniqueId,
                      ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase())}`,
                    )
            }
          >
            <ComponentInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.componentGeneralInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.componentType)}
                </Typography>
                <Typography variant="body2">{componentDetail?.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EComponentLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {componentDetail?.artId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} container direction="column">
                {componentDetail.artUniqueId && (
                  <Link
                    href={ERouteLinks.ViewAncillaryDetails.replace(
                      ':id',
                      componentDetail.artUniqueId,
                    ).replace(':type', EArtAncillaryTypeNameLowerCase.COMPONENTS)}
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      label={t('componentDetails.showArtInformation.button')}
                      fullWidth
                    />
                  </Link>
                )}
              </Grid>

              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.manufacturer)}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.productName)}
                  </Typography>
                </Grid>
              )}
              {showField('calibre') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.calibre)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.calibre)}
                  </Typography>
                </Grid>
              )}
              {showField('composition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.composition)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.composition)}
                  </Typography>
                </Grid>
              )}
              {showField('proofHouse') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.proofHouse)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.proofHouse)}
                  </Typography>
                </Grid>
              )}
              {showField('capacity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.capacity)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.capacity)}
                  </Typography>
                </Grid>
              )}
              {showField('length') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.length)}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(componentDetail?.length)}
                  </Typography>
                </Grid>
              )}
            </ComponentInformationContainer>
            <ComponentInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.componentDetailedInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firecycleID)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.fireCycleId)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.serialNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.serialNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.componentState)}
                </Typography>
                <Typography variant="body2">
                  <Chips
                    text={emptyFieldHolder(componentDetail?.state)}
                    variant={renderDataColor}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.exhibitNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.exhibitNumber)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.currentOwner)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.ownerName) &&
                  componentDetail.ownerType ? (
                    <StyledRouterLink
                      to={`${ERouteLinks.ViewLegalEntity.replace(
                        ':id',
                        componentDetail.ownerId,
                      )}?type=${getLegalEntityType(componentDetail.ownerType)}`}
                    >
                      {componentDetail.ownerName}
                    </StyledRouterLink>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.currentKeeper)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.keeperName) &&
                  componentDetail.keeperType ? (
                    <StyledRouterLink
                      to={`${ERouteLinks.ViewLegalEntity.replace(
                        ':id',
                        componentDetail.keeperId,
                      )}?type=${getLegalEntityType(componentDetail.keeperType)}`}
                    >
                      {componentDetail.keeperName}
                    </StyledRouterLink>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.externalReferenceNumber)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.externalReferenceNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.manufacturedDate)}
                </Typography>
                <Typography variant="body2">
                  {formatDate(componentDetail?.productionDate, shortDateFormat)}
                </Typography>
              </Grid>
              {componentDetail?.customFields?.map((customField) => (
                <Grid item xs={6} lg={3} key={customField.customFieldUniqueId}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {
                      customField?.translations?.filter(
                        (translation) =>
                          selectedLanguage?.uniqueId === translation.languageUniqueId,
                      )?.[0]?.name
                    }
                  </Typography>
                  <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
                </Grid>
              ))}
            </ComponentInformationContainer>
            <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.firearmInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmManufacturer)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.frtManufacturer)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmReferenceNo)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.frtReferenceNo)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} container direction="column">
                <Link
                  href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${componentDetail.frtId}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                    label={t('componentDetails.showFrtInformation.button')}
                    fullWidth
                  />
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmFirecycleType)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.frtType)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmMake)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.frtMake)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmModel)}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(componentDetail?.frtModel)}
                </Typography>
              </Grid>
            </ComponentInformationContainer>
          </DetailsTemplate>
          <Grid item xs={12} pb={2} container direction="column">
            {nextPageAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
              </Box>
            )}
            {ancillaryAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={ancillaryAlert.text} variant={ancillaryAlert.variant} />
              </Box>
            )}
            {transactionAlert && (
              <Box sx={{ marginBottom: '16px' }}>
                <Alert text={transactionAlert.text} variant={transactionAlert.variant} />
              </Box>
            )}
            <Button
              label={
                isAllExpanded
                  ? t('componentDetails.collapseAll.button')
                  : t('componentDetails.expandAll.button')
              }
              onClick={() => setIsAllExpanded((prev) => !prev)}
              variant={EButtonVariants.outlined}
              sx={{ width: '150px', alignSelf: 'flex-end' }}
            />
          </Grid>
        </Grid>
      )}
      {hasPermissions(
        [EPermission.VIEW_TRANSACTIONS, EPermission.VIEW_ALL_TRANSACTIONS],
        true,
      ) && (
        <Accordion
          title={t('componentDetails.transactions.header')}
          name="transactions"
          key="transactions"
          expanded={isAllExpanded}
        >
          <ComponentsTransactions
            inGroup={!!componentDetail?.groupName}
            isExported={componentDetail?.stateUniqueId === EFirearmStateId.EXPORTED}
            stateUniqueId={componentDetail?.stateUniqueId}
          />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_COMMENTS_ANCILLARY]) && (
        <Accordion
          title={t('componentDetails.comments.header')}
          name="comments"
          key="comments"
          expanded={isAllExpanded}
        >
          <ComponentsComments id={id} />
        </Accordion>
      )}
      {hasPermissions([EPermission.MANAGE_ATTACHMENT_ANCILLARY]) && (
        <Accordion
          title={t('componentDetails.attachments.header')}
          name="attachments"
          key="attachments"
          expanded={isAllExpanded}
        >
          <ComponentsAttachments idAncillary={id} />
        </Accordion>
      )}
    </div>
  );
};

export default DetailComponents;
