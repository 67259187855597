import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';

interface ILegalEntityAncillaryTableCard {
  data: ITableDataSource;
}

const LegalEntityAncillaryTableCard = ({ data }: ILegalEntityAncillaryTableCard) => {
  const isWarning = data.state?.toLowerCase().includes('stolen');
  const { t } = useTranslations();
  const navigate = useNavigate();

  const getType = (typeId: string) => {
    switch (typeId) {
      case EArtAncillaryType.ACCESSORIES:
        return 'accessory';
      case EArtAncillaryType.AMMUNITION:
        return 'ammunition';
      case EArtAncillaryType.COMPONENTS:
        return 'component';
      default:
        return '';
    }
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${isWarning ? colors.error100 : colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: isWarning ? colors.error15 : colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.firecycleId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.ancillaryDictionaryValue.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.ancillaryDictionaryValue)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.ancillaryType.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillaryType)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeperName)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.ancillary.accordion.state.column')}
        </Typography>
        <Chips
          text={emptyFieldHolder(data.state)}
          variant={isWarning ? EChipsVariants.error : EChipsVariants.primary}
        />
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('actions.general.label')}: `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('legalEntityDetails.ancillary.accordion.details.link')}
            variant={EButtonVariants.contained}
            onClick={() => {
              navigate(
                `${ERouteLinks.ViewAncillary.replace(':id', data.id as string).replace(
                  ':type',
                  getType(data.ancillaryDictionaryUniqueId),
                )}`,
              );
            }}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityAncillaryTableCard };
