import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import {
  IFormElementOptionProp,
  initialTwoFactorAuthFormValues,
  ITwoFactorAuthForm,
} from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/TwoFactorAuthPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { useAuth } from 'models/authContext';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { Box } from '@mui/system';
import {
  AuthTemplate,
  getBase64Data,
  StyledAuthHeader,
  StyledImg,
} from 'components/templates/AuthTemplate';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { v4 as uuidv4 } from 'uuid';
import { FC_SYSTEM_DEVICE_ID } from 'pages/LoginPage';
import { useTrustedDevice } from 'models/trustedDeviceContext';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate } from 'react-router-dom';
import { IData } from 'pages/AuthPage';
import { IAuthResponse } from 'models/auth';
import { useAuthenticationSetup } from 'pages/SystemSettingsPage/AuthenticationSetupPage/hooks';
import { ItrustedDeviceOptions } from 'models/settings';
import { FormSelect } from 'components/molecules/FormSelect';
import { RouterLink } from 'components/atoms/RouterLink';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { ELoginPartTypes } from 'models/loginScreenSetup';
import { IImageData, useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { Alert } from 'components/atoms/Alert';

export interface ITwoFactorAuthPageProps {
  email: string;
  password: string;
  phoneNumber: string;
  changeDataCallback: (p: IData) => void;
}

const TwoFactorAuthPage = ({
  email,
  password,
  phoneNumber,
  changeDataCallback,
}: ITwoFactorAuthPageProps): ReactElement => {
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: initialTwoFactorAuthFormValues,
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const preventRef = useRef(false);
  const { verifyAuthCode, logIn } = useAuth();
  const { state: routerState }: any = useLocation();
  const { addTrustedDevice } = useTrustedDevice();
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isResendAuthCodeLoading, setIsResendAuthCodeLoading] = useState(false);
  const { getEnabledTrustedDeviceOptionsData, trustedDeviceOptions } =
    useAuthenticationSetup();
  const { getLoginScreen, loginScreenDataParsed } = useLogoSetup();

  useEffect(() => {
    (async () => {
      await getLoginScreen();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEnabledTrustedDeviceOptionsData();
  }, []);

  const anyLogoPresent: boolean =
    loginScreenDataParsed !== null &&
    loginScreenDataParsed !== undefined &&
    (loginScreenDataParsed[ELoginPartTypes.LOGO_1] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_2] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_3]) !== undefined;

  const defaultValue: string | undefined = trustedDeviceOptions
    ? trustedDeviceOptions.sort((a, b) => a.daysToExpire! - b.daysToExpire!)[0].uniqueId
    : undefined;

  const mapTrustedDeviceOptionsToOptions = (
    options: ItrustedDeviceOptions[] | null,
  ): IFormElementOptionProp[] => {
    if (options === null) {
      return [];
    }
    return options.map((option) => ({
      label: option.daysToExpire ? option.daysToExpire.toString() : '0',
      value: option.uniqueId || '',
    }));
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoginLoading(true);
    await verifyAuthCode(
      { email, enteredCodeValue: data.authCode },
      () => {
        if (data.isRememberDevice) {
          const deviceToken = uuidv4();
          addTrustedDevice(
            {
              deviceToken,
              title: `${browserName} ${fullBrowserVersion} ${new Date().toLocaleString()}`,
              trustedDeviceTimeOptionUniqueId: data.trustedDeviceTimeOptionUniqueId,
            },
            () => {
              localStorage.setItem(FC_SYSTEM_DEVICE_ID, deviceToken);
            },
          );
        }
        navigate(ERouteLinks.Dashboard);
      },
      () => {
        setError('authCode', {
          type: 'custom',
          message: 'Two Factor Authentication code is incorrect.',
        });
      },
    );
    setIsLoginLoading(false);
  });

  const [formFields, setFormFields] = useState<ITwoFactorAuthForm>(
    initialTwoFactorAuthFormValues,
  );

  useEffect(() => {
    const subscription = watch(() => setFormFields(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (routerState?.isRedirectToTwoFactorAuthPage && !preventRef.current) {
      preventRef.current = true;
      logIn(
        {
          email,
          password: routerState?.password,
          deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
        },
        (res: IAuthResponse) => {
          if (res.phoneNumber) {
            changeDataCallback({
              email,
              password: routerState?.password,
              phoneNumber: res.phoneNumber,
            });
          }
        },
      );
    }
  }, []);

  return (
    <AuthTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems={anyLogoPresent ? 'flex-start' : 'center'}
        sm={11}
        md={7}
        xl={6}
        sx={{ p: { xs: 4 } }}
        px={2}
      >
        {anyLogoPresent && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              minHeight: '151px',
              width: '100%',
            }}
            mt={3}
          >
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_1] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_1] as IImageData,
                  )}
                  alt="Logo One"
                />
              )}
            </Box>
            <Box pr={2} pl={2}>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_2] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_2] as IImageData,
                  )}
                  alt="Logo Two"
                />
              )}
            </Box>
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_3] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_3] as IImageData,
                  )}
                  alt="Logo Three"
                />
              )}
            </Box>
          </Box>
        )}
        <Grid item xl={6} container justifyContent="center" alignItems="flex-start">
          {routerState?.isRedirectToTwoFactorAuthPage && (
            <Grid item mb={6}>
              <Alert text="The password has been changed successfully." />
            </Grid>
          )}
          <Grid item xs={12} mb={3}>
            <StyledAuthHeader mb={4}>Two-Factor Authentication</StyledAuthHeader>
            <Typography variant="body1" textAlign="center" mb={2}>
              Please enter the code you received by SMS
              <br />
              {`on the following number: ${phoneNumber}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <FormInput
                name="authCode"
                label="Authentication Code"
                placeholder="Enter received code"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
              {errors && errors.authCode && errors.authCode.type === 'custom' && (
                <Box sx={{ marginTop: '-25px' }}>
                  <FormErrorLabel label={errors.authCode.message as string} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box>
              <FormCheckbox
                name="isRememberDevice"
                label="Remember this device for"
                onChange={(value) => {
                  if (value) {
                    setValue('trustedDeviceTimeOptionUniqueId', defaultValue);
                  } else {
                    setValue('trustedDeviceTimeOptionUniqueId', undefined);
                  }
                }}
                control={control}
                errors={errors}
                withValidation={false}
              />
            </Box>
            <Box height="32px" marginTop="-8px">
              <FormSelect
                name="trustedDeviceTimeOptionUniqueId"
                options={mapTrustedDeviceOptionsToOptions(trustedDeviceOptions)}
                setValue={setValue}
                control={control}
                errors={errors}
                disabled={!formFields.isRememberDevice}
                withValidation={!formFields.isRememberDevice}
                canBeEmpty={false}
              />
            </Box>
            <Typography variant="caption" ml={2}>
              days
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={12} container justifyContent="center">
            <Grid item xs={12} mt={3} mb={1}>
              <Button
                label="Verify"
                variant={EButtonVariants.contained}
                onClick={() => onSubmit()}
                isLoading={isLoginLoading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                label="Resend Authentication Code"
                variant={EButtonVariants.outlined}
                isLoading={isResendAuthCodeLoading}
                onClick={async () => {
                  setIsResendAuthCodeLoading(true);
                  await logIn({
                    email,
                    password,
                    deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
                  });
                  setIsResendAuthCodeLoading(false);
                }}
                fullWidth
              />
            </Grid>
            <Grid mt={3} container justifyContent="center">
              <RouterLink
                to={ERouteLinks.AuthWithSlash}
                label="Back to log in"
                replace
                forceRefresh
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { TwoFactorAuthPage };
