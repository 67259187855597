import { createContext, useContext } from 'react';
import { IAlert } from 'components/atoms/Alert';

export interface IAlertContext {
  setAlert: (type: string, payload: IAlert) => void;
  clearAlert: (type: string) => void;
  roleAlert: null | IAlert;
  dictionaryAlert: null | IAlert;
  userAlert: null | IAlert;
  legalEntityAlert: null | IAlert;
  legalEntityOrgAlert: null | IAlert;
  samePageAlert: null | IAlert;
  nextPageAlert: null | IAlert;
  authAlert: null | IAlert;
  transactionAlert: null | IAlert;
  ancillaryAlert: null | IAlert;
  trustedDeviceAlert: null | IAlert;
  firearmAlert: null | IAlert;
}

export const AlertContext = createContext<IAlertContext>({} as IAlertContext);

export const useAlert = (): IAlertContext => useContext(AlertContext);
